import React from 'react';
import * as S from './menu-card-new-group-content.styles';
import IncButton from 'components/inc-button/inc-button.component';
import { useTranslation } from 'react-i18next';

const MenuCardNewGroupContent = ({ title, size }) => {
  const { t } = useTranslation();

  return (
    <S.Container px={1} data-testid="MenuCard.Content.Container">
      <S.TopContainer>
        <S.InnerContainer>
          <S.Title data-testid="MenuCard.Title" tabIndex="0">
            {title}
          </S.Title>
        </S.InnerContainer>
      </S.TopContainer>
      <IncButton data-testid="MenuCard.Button.NumItems">
        {size} {t('catalog.menuCard.itemsLabel')}
      </IncButton>
    </S.Container>
  );
};

export default MenuCardNewGroupContent;
