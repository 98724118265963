import React, { useMemo } from 'react';
import CustomInput from 'components/custom-input/custom-input.component';
import { InputLabel } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import FormError from 'components/form-error/form-error.component';
import { useFormContext } from 'react-hook-form';
import { getMissingMandatoryFieldsInAddress } from 'pages/locations/locations.utils';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';

const MissingAddressFields = ({ missingFieldsData, errors, address }) => {
  const { register } = useFormContext();

  const missingFields = useMemo(
    () => getMissingMandatoryFieldsInAddress(address),
    [address],
  );

  return missingFieldsData.map(
    ({ label, name, field, testId, errorMessage }) =>
      missingFields.includes(field) && (
        <CustomInput key={field}>
          <InputLabel htmlFor={name}>{label}*</InputLabel>

          <TrimTextField
            id={name}
            {...register(name, {
              required: true,
              validate: value => !!value.trim(),
            })}
            fullWidth
            inputProps={{ 'data-testid': testId }}
          />

          <ErrorMessage
            errors={errors}
            name={name}
            render={() => <FormError errorMessage={errorMessage} />}
          />
        </CustomInput>
      ),
  );
};

export default MissingAddressFields;
