import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PasswordVisibility = ({ testId, setShowPassword, showPassword }) => {
  const { t } = useTranslation();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputAdornment position="end">
      <IconButton
        data-testid={testId}
        aria-label={t('forms.password.ariaLabel.toggleVisibility')}
        onClick={handleShowPassword}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordVisibility;
