/* istanbul ignore next */
// We have removed logrocket from the project.
// However keeping this mock implementation for future references.
const LogRocket = {
  init: (id, options) => {
    console.log(`Logrocket ID: ${id}`);
    const request = options.network.requestSanitizer({});
    const result = options.network.responseSanitizer({});
    return { request, result };
  },
  identify: (userData, _options) => console.log(`User ID, ${userData?.userId}`),
  captureException: (error, _options) =>
    console.log('Session Tracking Message', error),
};

export default window.Cypress ? window.LogRocket : LogRocket;
