import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BackAction from '../../../../components/back-action/back-action.component';
import { selectItemInstructionsLabel } from '../../../../redux/config/config.selectors';
import {
  makeSelectIsMenuBrowsingStore,
  selectStoreById,
} from '../../../../redux/locations/locations.selectors';
import {
  Item,
  ItemName,
  ItemsContainer,
  OrderStatus,
  Qty,
} from '../account-order-history-item.styles';
import OrderItemOptions from '../order-item-options/order-item-options.component';
import * as S from './order-item-detail.styles';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import IncButton from 'components/inc-button/inc-button.component';
import TrackOrder from 'components/track-order/track-order.component';
import OrderItemOffers from '../order-item-offers/order-item-offers.component';
import { useAccountOrderHistoryItem } from '../account-order-history-item.hooks';

const OrderItemDetail = ({ order, handleBackAction, handleReorder }) => {
  const { t } = useTranslation();
  const itemInstructionsLabel = useSelector(selectItemInstructionsLabel);
  const {
    orderTotal: {
      displayTotal,
      displayTotalDiscountApplied,
      displaySubtotal,
      displayGratuity,
      taxSummary,
      chargeSummary,
    },
    checks,
    orderOptionInstructions,
    orderOptionName,
    orderNumber,
    orderItems,
    orderDiscounts,
    orderFulfillTime,
    status,
    locationName,
    orderName,
    locationId,
    orderInstructions,
    deliveryInstructions,
    orderOptionUserResponse,
    orderOptionInformation,
    trackingUrl,
  } = order;
  const payments = checks && checks.length ? checks[0].payments : null;
  const { taxes = [] } = taxSummary || {};
  const { charges = [] } = chargeSummary || {};

  // Combine taxes and charges in one list
  const chargesList = [
    ...taxes.map(
      ({
        taxDescription: chargeDescription,
        displayTaxAmountApplied: chargeDisplayAmount,
      }) => ({ chargeDescription, chargeDisplayAmount }),
    ),
    ...charges.map(
      ({ chargeDescription, displayChargeApplied: chargeDisplayAmount }) => ({
        chargeDescription,
        chargeDisplayAmount,
      }),
    ),
  ];

  const store = useSelector(selectStoreById(locationId));

  const isMenuBrowsingStore = useSelector(
    makeSelectIsMenuBrowsingStore(locationId),
  );

  const { isOrderDiscountApplied } = useAccountOrderHistoryItem(orderDiscounts);

  return (
    <>
      <BackAction handleBack={handleBackAction} />
      <S.CustomContainer
        data-testid="OrderItemDetail.CustomContainer"
        tabIndex="0"
      >
        <S.Head>
          <div>
            <S.For>{orderName}</S.For>
            <S.LineItem>
              {locationName} {orderOptionName ? ` - ${orderOptionName}` : null}
            </S.LineItem>
          </div>
          <DescriptionIcon />
        </S.Head>

        <S.Content>
          <S.LineItem>
            {DateTime.fromISO(orderFulfillTime).toLocaleString(
              DateTime.DATETIME_SHORT,
            )}
          </S.LineItem>
          {orderNumber && (
            <Box display="flex">
              <S.LineItem>{t('accountPastOrders.orderNumberLabel')}</S.LineItem>
              <S.LineItem>{orderNumber}</S.LineItem>
            </Box>
          )}
          <S.Total>
            <span>{t('accountPastOrders.totalLabel')}</span>
            <span>${displayTotal}</span>
          </S.Total>

          <ItemsContainer>
            {orderItems.map(
              ({
                orderItemId,
                displayInfoTitle,
                quantity,
                itemInstructions,
                itemTotal,
                options,
                itemDiscounts,
              }) => (
                <React.Fragment key={orderItemId}>
                  <Item data-testid="OrderItemDetail.Item">
                    <ItemName>
                      <Qty>{quantity}</Qty>
                      <Typography component="span">
                        {displayInfoTitle}
                      </Typography>
                    </ItemName>

                    <Typography component="span">
                      $
                      {isOrderDiscountApplied
                        ? itemTotal.displayPreDiscountSubtotal
                        : itemTotal.displayTotal}
                    </Typography>
                  </Item>
                  {itemDiscounts && itemDiscounts.length > 0 && (
                    <OrderItemOffers itemDiscounts={itemDiscounts} />
                  )}
                  {options && options.length > 0 && (
                    <OrderItemOptions options={options} />
                  )}
                  {itemInstructions && (
                    <>
                      <S.ItemInstructions>
                        <strong>{itemInstructionsLabel}</strong>
                      </S.ItemInstructions>
                      <S.ItemInstructions>
                        {itemInstructions}
                      </S.ItemInstructions>
                    </>
                  )}
                </React.Fragment>
              ),
            )}
          </ItemsContainer>

          <S.DetailContainer>
            {displaySubtotal && (
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <S.DetailLabel>
                  {t('accountPastOrders.subtotalLabel')}
                </S.DetailLabel>
                <S.DetailLabel>{displaySubtotal}</S.DetailLabel>
              </Box>
            )}
            <S.DetailItem>
              <span>{t('accountPastOrders.tipLabel')}</span>
              <span>${displayGratuity}</span>
            </S.DetailItem>
            {displayTotalDiscountApplied &&
              displayTotalDiscountApplied !== '0.00' && (
                <S.DetailItem>
                  <span>{t('accountPastOrders.offerSavingsLabel')}</span>
                  <span>-${displayTotalDiscountApplied}</span>
                </S.DetailItem>
              )}
          </S.DetailContainer>
          {chargesList.length ? (
            <S.DetailContainer>
              <S.DetailLabel>
                {t('accountPastOrders.chargesLabel')}
              </S.DetailLabel>
              {chargesList.map(
                ({ chargeDescription, chargeDisplayAmount }, index) => (
                  <S.DetailItem key={index}>
                    <span>{chargeDescription}</span>
                    <span>${chargeDisplayAmount}</span>
                  </S.DetailItem>
                ),
              )}
            </S.DetailContainer>
          ) : null}

          {payments && (
            <S.DetailContainer>
              <S.DetailLabel>
                {t('accountPastOrders.paymentsLabel')}
              </S.DetailLabel>
              {payments.map(({ paymentTxId, cardType, lastFour }) => (
                <div key={paymentTxId}>
                  {cardType && (
                    <S.DetailItem>
                      <span>{t('cardItem.cardType')}</span>
                      <span>{cardType}</span>
                    </S.DetailItem>
                  )}
                  {lastFour && (
                    <S.DetailItem>
                      <span>{t('cardItem.cardNumber')}</span>
                      <span>xxxx xxxx xxxx {lastFour}</span>
                    </S.DetailItem>
                  )}
                </div>
              ))}
            </S.DetailContainer>
          )}

          {deliveryInstructions && (
            <S.DetailContainer>
              <S.DetailLabel>
                {t('checkout.deliveryInstructions.title')}
              </S.DetailLabel>
              <S.DetailItem>{deliveryInstructions}</S.DetailItem>
            </S.DetailContainer>
          )}

          {orderInstructions && (
            <S.DetailContainer>
              <S.DetailLabel>
                {t('checkout.orderInstructions.title')}
              </S.DetailLabel>
              <S.DetailItem>{orderInstructions}</S.DetailItem>
            </S.DetailContainer>
          )}

          {orderOptionUserResponse && orderOptionInformation && (
            <S.DetailContainer>
              <S.DetailLabel>{orderOptionInformation}</S.DetailLabel>
              <S.DetailItem>{orderOptionUserResponse}</S.DetailItem>
            </S.DetailContainer>
          )}

          <S.OrderOptionInstructions>
            {orderOptionInstructions}
          </S.OrderOptionInstructions>

          <S.BottomActions>
            <div>
              <OrderStatus className={status === 'CLOSED' ? 'completed' : ''}>
                {status === 'CANCELED' ? (
                  <>
                    <CancelIcon />
                    {t('accountPastOrders.cancelledStatus')}
                  </>
                ) : (
                  <>
                    <CheckCircleOutlineIcon />
                    {t('accountPastOrders.completedStatus')}
                  </>
                )}
              </OrderStatus>
            </div>
            <Box marginTop={1} width="100%">
              <TrackOrder trackingUrl={trackingUrl} />
            </Box>
            {store && store.phoneNumber && (
              <Box marginTop="10px" width="100%">
                <IncButtonDark
                  href={`tel:${store.phoneNumber}`}
                  data-testid="OrderItemDetail.CustomButton.CallStore"
                  fullWidth
                >
                  {t('accountPastOrders.callStoreAction')}
                </IncButtonDark>
              </Box>
            )}
            {!isMenuBrowsingStore && (
              <Box marginTop="10px" width="100%">
                <IncButton
                  data-testid="OrderItemDetail.CustomButton.Reorder"
                  onClick={() => handleReorder(order.orderId)}
                  fullWidth
                >
                  {t('accountPastOrders.reorderAction')}
                </IncButton>
              </Box>
            )}
          </S.BottomActions>
        </S.Content>
      </S.CustomContainer>
    </>
  );
};

export default OrderItemDetail;
