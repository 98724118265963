import { Grid } from '@material-ui/core';
import { PaymentOptionType } from 'components/payment/payment.constants';
import { Rover, useRoverState } from 'reakit';

const noAriaControlOptions = new Set([
  PaymentOptionType.PAY_LATER,
  PaymentOptionType.GOOGLE_PAY,
  PaymentOptionType.APPLE_PAY,
]);

const PaymentOptionWrapper = ({ type, as, gridSm, handleChange, value }) => {
  const roverProps = useRoverState();
  const selected = type === value;

  return (
    <Grid item xs={12} sm={gridSm}>
      <Rover
        {...roverProps}
        as={as}
        id={type}
        selected={selected}
        onClick={handleChange}
        role="tab"
        aria-controls={
          !noAriaControlOptions.has(type) && selected
            ? `${type}-panel`
            : undefined
        }
      />
    </Grid>
  );
};

export default PaymentOptionWrapper;
