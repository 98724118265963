import { Box } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './tip-selector.styles';

const TipSelector = ({
  name = 'tip-selector',
  percentages = [0, 10, 15, 20, 30],
  onChange,
  calculateAmount,
  value,
  percentageValue = percentages[0],
}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(percentageValue?.toString());

  useEffect(() => {
    if (
      percentageValue > -1 &&
      calculateAmount(percentageValue).toString() !== value.toString()
    ) {
      setTabValue(-1);
    }
  }, [calculateAmount, percentageValue, value]);

  useEffect(() => {
    if (percentages.indexOf(parseInt(percentageValue)) === -1) {
      setTabValue(-1);
    }
  }, [percentages, percentageValue]);

  return (
    <Box data-testid="TipSelector.TipTabList">
      <S.TipTabs
        value={tabValue}
        onChange={(_e, newPercentage) => {
          setTabValue(newPercentage);
          newPercentage !== -1 &&
            onChange(calculateAmount(newPercentage), newPercentage);
        }}
        id={name}
        variant="scrollable"
        aria-label={t('tipSelector.listAria')}
      >
        {percentages
          .map(percentage => (
            <S.TipTab
              label={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box fontSize="12px">{percentage}%</Box>
                  <Box fontSize="14px">${calculateAmount(percentage)}</Box>
                </Box>
              }
              key={percentage}
              value={percentage.toString()}
              data-testid="TipSelector.TipTab"
              aria-label={t('tipSelector.tipValueAria', {
                percentage,
                tipValue: calculateAmount(percentage),
              })}
            />
          ))
          .concat([
            <S.TipTab
              label={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>{t('tipSelector.otherLabel')}</Box>
                </Box>
              }
              key="other"
              value={-1}
              data-testid="TipSelector.TipTab"
              aria-label={t('tipSelector.customTipAria')}
            />,
          ])}
      </S.TipTabs>
      {tabValue === -1 && (
        <Box display="flex" justifyContent="flex-end" py={1}>
          <CurrencyTextField
            name="custom_amount"
            inputProps={{
              'data-testid': 'Tip.CurrencyTextField',
              'aria-label': `${t('tipSelector.customTipAria')}`,
            }}
            variant="standard"
            value={value}
            minimumValue="0"
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onBlur={(_e, value) => onChange(value, -1)}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(TipSelector);
