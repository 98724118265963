import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsPickupButtonVisible,
  selectIsDeliveryButtonVisible,
  selectIsCateringButtonVisible,
} from 'redux/locations/locations.selectors';
import { useMemo, useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useOrderTypeLabels } from 'hooks/useOrderTypeLabels';
import { actions, selectors } from 'redux/app/app.slice';
import {
  selectForceUserToSelectOrderType,
  selectIsCateringAvailable,
  selectOrderTypeFromDefaultOrderType,
} from 'redux/config/config.selectors';

export const useLocationsToggleButtons = (orderType, setSelectedStore) => {
  const dispatch = useDispatch();

  const isPickupButtonVisible = useSelector(selectIsPickupButtonVisible);
  const isDeliveryButtonVisible = useSelector(selectIsDeliveryButtonVisible);
  const isCateringButtonVisible = useSelector(selectIsCateringButtonVisible);
  const reduxOrderType = useSelector(selectors.selectLocationsOrderType);
  const locationType = useSelector(selectors.selectLocationsLocationType);
  const defaultOrderType = useSelector(selectOrderTypeFromDefaultOrderType);
  const catering = useSelector(selectIsCateringAvailable);

  const { pickupLabel, deliveryLabel, cateringLabel } = useOrderTypeLabels();

  const orderTabs = useMemo(() => {
    const tabs = [];
    if (isPickupButtonVisible) {
      tabs.push({
        type: 'PICKUP',
        label: pickupLabel,
        'data-testid': 'OrderTypeSelectors.CustomButton.Pickup',
      });
    }
    if (isDeliveryButtonVisible) {
      tabs.push({
        type: 'DELIVERY',
        label: deliveryLabel,
        'data-testid': 'OrderTypeSelectors.CustomButton.Delivery',
      });
    }
    if (isCateringButtonVisible) {
      tabs.push({
        type: 'CATERING',
        label: cateringLabel,
        'data-testid': 'OrderTypeSelectors.CustomButton.Catering',
      });
    }
    return tabs;
  }, [
    cateringLabel,
    deliveryLabel,
    isCateringButtonVisible,
    isDeliveryButtonVisible,
    isPickupButtonVisible,
    pickupLabel,
  ]);

  useEffect(() => {
    if (orderType === 'CATERING') {
      dispatch(actions.setLocationsLocationType('CATERING'));
      dispatch(actions.setLocationsOrderType(null));
    } else {
      dispatch(actions.setLocationsOrderType(orderType));
      dispatch(actions.setLocationsLocationType(null));
    }
    setSelectedStore(null);
  }, [dispatch, orderType, setSelectedStore]);

  useEffect(() => {
    scroll.scrollToTop({ smooth: true, containerId: 'locationsListContainer' });
  }, [orderType]);

  const defaultTab = useMemo(() => {
    if (!catering && defaultOrderType === 'CATERING') {
      return (
        getInitialTabState(reduxOrderType, locationType, orderTabs) ??
        orderTabs[0]?.type
      );
    }

    if (!!defaultOrderType) {
      return defaultOrderType;
    }

    return (
      getInitialTabState(reduxOrderType, locationType, orderTabs) ??
      orderTabs[0]?.type
    );
  }, [catering, defaultOrderType, locationType, orderTabs, reduxOrderType]);

  return { orderTabs, defaultTab };
};

const getInitialTabState = (orderType, locationType, orderTabs) => {
  if (
    locationType === 'CATERING' &&
    orderTabs.some(tab => tab.type === 'CATERING')
  )
    return 'CATERING';

  if (orderType === 'PICKUP' && orderTabs.some(tab => tab.type === orderType))
    return orderType;

  if (orderType === 'DELIVERY' && orderTabs.some(tab => tab.type === orderType))
    return orderType;
};

export const useForceOrderType = () => {
  const [selectedForceOrderType, setSelectedForceOrderType] = useState(null);

  const forceUserToSelectOrderType = useSelector(
    selectForceUserToSelectOrderType,
  );

  return {
    selectedForceOrderType,
    setSelectedForceOrderType,
    showForceOrderTypeControls:
      forceUserToSelectOrderType && !selectedForceOrderType,
  };
};
