import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderOffers } from 'redux/cart/cart.actions';
import {
  selectApplicableOffers,
  selectOrderAndItemDiscounts,
  selectOrderTotal,
} from 'redux/cart/cart.selectors';
import { selectIsLoggedIn } from 'redux/user/user.selectors';

export const useCheckoutOffersSectionComponent = () => {
  const applicableOffers = useSelector(selectApplicableOffers);
  const discounts = useSelector(selectOrderAndItemDiscounts);

  return {
    applicableOffers,
    discounts,
  };
};

export const useCheckoutOffersSection = () => {
  const dispatch = useDispatch();
  const orderTotal = useSelector(selectOrderTotal);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const applicableOffers = useSelector(selectApplicableOffers);
  const discounts = useSelector(selectOrderAndItemDiscounts);
  const show = useMemo(
    () =>
      !isLoggedIn ||
      (isLoggedIn && applicableOffers?.length > 0 && discounts?.length < 1),
    [isLoggedIn, applicableOffers?.length, discounts?.length],
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getOrderOffers());
    }
  }, [dispatch, isLoggedIn, orderTotal]);

  return { show };
};
