import { PaymentGatewayType } from 'components/payment/payment.constants';
import {
  useCustomerInfo,
  usePaymentGatewayMatches,
  usePaymentMode,
} from 'components/payment/payment.new.hooks';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsFreeOrder } from 'redux/cart/cart.selectors';
import { getSectionId } from '../checkout-section/checkout-section.hooks';
import CheckoutContext from '../checkout.context';

export const useShowNewCheckoutPayments = () => {
  const isFreeOrder = useSelector(selectIsFreeOrder);
  const paymentMode = usePaymentMode();
  const paymentGatewayMatches = usePaymentGatewayMatches();

  const show = useMemo(
    () =>
      paymentGatewayMatches([
        PaymentGatewayType.INCENTIVIO_PAYMENTS,
        PaymentGatewayType.SPREEDLY,
        PaymentGatewayType.CLOVER,
        PaymentGatewayType.SQUARE,
      ]) &&
      !isFreeOrder &&
      paymentMode !== 'NO_PAYMENT',
    [isFreeOrder, paymentGatewayMatches, paymentMode],
  );

  return show;
};

export const useResetPaymentValidation = () => {
  const { t } = useTranslation();
  const title = useMemo(() => t('checkout.payment.title'), [t]);
  const { clearSectionValidationStateById } = useContext(CheckoutContext);

  const resetCheckoutValidation = useCallback(() => {
    clearSectionValidationStateById(getSectionId(title));
  }, [clearSectionValidationStateById, title]);

  return resetCheckoutValidation;
};

export const useGetCardNickname = () => {
  const customerInfo = useCustomerInfo();
  return customerInfo
    ? `${customerInfo.firstName} ${customerInfo.lastName}`
    : 'new card';
};
