import Slider from '@material-ui/core/Slider';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPreDiscountSubtotal } from 'redux/cart/cart.selectors';
import { universalMaxPoints } from 'redux/cart/cart.utils';
import { convertCentsToDollar } from 'utils';
import * as S from './offer-item-slider.styles';

const OfferItemSlider = ({
  discountAmount,
  pointsCost,
  availablePoints,
  setPointsToSpend,
  pointsToSpend,
}) => {
  const handleSliderChange = (_e, newValue) => {
    setPointsToSpend(newValue);
  };

  const preDiscountSubtotal = useSelector(selectPreDiscountSubtotal);

  const maxPoints = useMemo(
    () =>
      universalMaxPoints(
        availablePoints,
        discountAmount,
        pointsCost,
        preDiscountSubtotal,
      ),
    [availablePoints, discountAmount, pointsCost, preDiscountSubtotal],
  );

  const marks = [{ value: maxPoints, label: maxPoints }];
  if (pointsCost !== maxPoints) {
    marks.unshift({ value: pointsCost, label: pointsCost });
  }
  const min = pointsCost === maxPoints ? 0 : pointsCost;
  const step = pointsCost === maxPoints ? null : pointsCost;

  return (
    <S.SliderWrapper>
      <Slider
        data-testid="OfferItem.Slider"
        defaultValue={pointsCost}
        value={pointsToSpend}
        onChange={handleSliderChange}
        marks={marks}
        step={step}
        min={min}
        max={maxPoints}
        valueLabelDisplay="auto"
        aria-label="Points slider"
      />
      <S.SavedText
        tabIndex="0"
        aria-label={`Saved amount: $${
          (pointsToSpend / pointsCost) * discountAmount
        }`}
      >
        Saved:{' '}
        {convertCentsToDollar((pointsToSpend / pointsCost) * discountAmount)}
      </S.SavedText>
    </S.SliderWrapper>
  );
};

export default OfferItemSlider;
