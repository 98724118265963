import { Box, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs.component';
import { useInbox } from './account-inbox.hooks';
import * as S from './account-inbox.styles';
import Messages from './messages/messages.component';

const AccountInbox = () => {
  const { t } = useTranslation();

  const { fetchAppcontent, loading, containerAriaAttributes, ...rest } =
    useInbox();

  return (
    <>
      <Breadcrumbs maxWidth="md">
        <Link to="/" component={RouterLink}>
          {t('breadcrumbs.home')}
        </Link>
        <Typography aria-label="Currently located at Inbox" tabIndex="0">
          {t('breadcrumbs.inbox')}
        </Typography>
      </Breadcrumbs>
      <S.CustomContainer
        maxWidth="md"
        minheight={loading ? '100vh' : null}
        data-testid="AccountInbox.AccountContainer"
        {...containerAriaAttributes}
      >
        <Box>
          <h1>{t('accountInbox.title')}</h1>
        </Box>
        <Messages
          {...rest}
          loading={loading}
          fetchAppcontent={fetchAppcontent}
        />
      </S.CustomContainer>
    </>
  );
};

export default AccountInbox;
