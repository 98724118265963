import { Box } from '@material-ui/core';
import { PaymentOptionType } from 'components/payment/payment.constants';
import CreditCardPanelController from './credit-card-panel-controller/credit-card-panel-controller.component';

const CreditCardPanel = props => {
  return (
    <Box
      id={`${PaymentOptionType.CARD}-panel`}
      role="tabpanel"
      data-testid="CreditCardPanel.Box"
    >
      <CreditCardPanelController {...props} />
    </Box>
  );
};

export default CreditCardPanel;
