import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import IncButton from 'components/inc-button/inc-button.component';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppcontent } from 'redux/data/appcontent/appcontent.hooks';
import { useMessages } from 'redux/data/messages/messages.hooks';
import CustomCheckbox from '../../../../components/custom-checkbox/custom-checkbox.component';
import CustomDialog from '../../../../components/custom-dialog/custom-dialog.component';
import CustomInput from '../../../../components/custom-input/custom-input.component';
import FormError from '../../../../components/form-error/form-error.component';
import Form from '../../../../components/form/form.component';
import {
  deleteActivityBasedContent,
  fetchSurveyFields,
  submitSurveyAnswers,
} from '../../../../redux/user/user.actions';
import * as S from './survey-message.styles';

const transformRatings = (surveyFields, formData) => {
  const ratingsQuestions = surveyFields.questions.filter(
    question => question.type === 'RATING',
  );
  return ratingsQuestions.reduce((result, ratingsQuestion) => {
    const fieldName = `${ratingsQuestion.type}@${ratingsQuestion.questionId}`;
    const ratingsValue = !!formData[fieldName]
      ? parseInt(formData[fieldName])
      : null;
    result[fieldName] = !!ratingsValue
      ? ratingsQuestion.choices[ratingsValue - 1].choiceId
      : [];
    return result;
  }, {});
};

const SurveyMessage = ({ surveyId, distributedMessageId, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [successOpen, setSuccessOpen] = useState(false);
  const { refreshAppcontent } = useAppcontent();
  const { deleteMessage } = useMessages();

  useEffect(() => {
    dispatch(fetchSurveyFields(surveyId)).then(data => {
      if (data) {
        setData(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleThankyouClose = async () => {
    handleClose();
    setSuccessOpen(false);
    dispatch(deleteActivityBasedContent(distributedMessageId));
    await deleteMessage(distributedMessageId);
  };

  const onSubmit = formData => {
    const ratingObject = transformRatings(data, formData);

    dispatch(
      submitSurveyAnswers(surveyId, {
        ...formData,
        ...ratingObject,
      }),
    ).then(res => {
      if (res) {
        setSuccessOpen(true);
        refreshAppcontent();
      }
    });
  };

  const handleQuestion = question => {
    const { type, choices, isMandatory, questionId, questionTitle } = question;
    switch (type) {
      case 'RATING':
        return (
          <>
            <S.CustomRating
              name={`${type}@${questionId}`}
              rules={{
                validate: {
                  required: value => (isMandatory ? value > 0 : true),
                },
              }}
              error={!!errors[questionId]}
              max={choices.length}
            />
          </>
        );

      case 'OPEN_ENDED':
        return (
          <TrimTextField
            multiline
            fullWidth
            rows={4}
            error={!!errors[questionId]}
            variant="outlined"
            {...register(`${type}@${questionId}`, {
              required: isMandatory,
            })}
          />
        );

      case 'BOOLEAN':
      case 'SINGLE_CHOICE':
        return (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label={questionTitle}
              name={questionId}
              defaultValue={choices[0].choiceId}
            >
              {choices.map(({ choiceId, choiceDisplayInfos }) => {
                const { ref, ...registerProps } = register(
                  `${type}@${questionId}`,
                  { required: isMandatory },
                );

                return (
                  <S.CustomRadio
                    key={choiceId}
                    control={
                      <Radio
                        color="primary"
                        inputRef={ref}
                        {...registerProps}
                        value={choiceId}
                      />
                    }
                    label={choiceDisplayInfos[0].title}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );

      case 'MULTIPLE_CHOICE':
        return choices.map(({ choiceId, choiceDisplayInfos }) => (
          <div key={choiceId}>
            <CustomCheckbox
              {...register(`${type}@${questionId}`, {
                required: isMandatory,
              })}
              value={choiceId}
              label={choiceDisplayInfos[0].title}
            />
          </div>
        ));

      default:
        return null;
    }
  };

  return (
    <>
      {data && data.questions ? (
        <>
          <Box mb={2}>
            <Typography component="h3">
              {data.surveyDisplayInfos[0].title}
            </Typography>
            <Typography component="h4">
              {data.surveyDisplayInfos[0].shortDescription}
            </Typography>
          </Box>
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {data.questions.map(question => (
                <CustomInput
                  key={question.questionId}
                  testId="SurveyMessage.CustomInput.Question"
                >
                  <S.FieldLabel>
                    {question.questionDisplayInfos[0].title}
                    {question.isMandatory ? '*' : ''}
                  </S.FieldLabel>
                  <p>{question.questionDisplayInfos[0].questionDescription}</p>
                  {handleQuestion(question)}
                  {errors[`${question.type}@${question.questionId}`] && (
                    <FormError
                      errorMessage={t('register.errors.requiredField')}
                    />
                  )}
                </CustomInput>
              ))}
              <IncButton
                type="submit"
                data-testid="SurveyMessage.CustomButton.Submit"
                fullWidth
              >
                {t('accountInbox.surveyMessage.submitAction')}
              </IncButton>
            </Form>
          </FormProvider>
        </>
      ) : null}
      <CustomDialog
        title="Thank you!"
        open={successOpen}
        close={handleThankyouClose}
        testId="SurveyMessage.CustomDialog.ThankYou"
      >
        <Box mb={2}>
          <Typography>
            {t('accountInbox.surveyMessage.successMessage')}
          </Typography>
        </Box>
        <IncButton
          onClick={handleThankyouClose}
          data-testid="SurveyMessage.CustomButton.Close"
          fullWidth
        >
          {t('accountInbox.surveyMessage.closeAction')}
        </IncButton>
      </CustomDialog>
    </>
  );
};

export default SurveyMessage;
