import { Box, Typography } from '@material-ui/core';
import Spinner from 'components/spinner/spinner.component';
import { useIsStoreDisabled } from 'hooks/useIsStoreDisabled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useRouteMatch } from 'react-router-dom';
import {
  MULTIPLE_CATALOG,
  SINGLE_CATALOG,
} from 'redux/catalogs/catalog.constants';
import MenuExportCsv from './export/menu-export-csv.component';
import MenuBreadcrumbs from './menu-breadcrumbs/menu-breadcrumbs.component';
import { useMenu } from './menu.hooks';
import MultipleCatalogMenu from './multiple-catalog-menu/multiple-catalog-menu.component';
import SingleCatalogMenu from './single-catalog-menu/single-catalog-menu.component';

const MenuPage = () => {
  const { menuType, store, isLoading } = useMenu();
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  useIsStoreDisabled();

  if (!store) return null;

  if (isLoading) return <Spinner />;

  return (
    <Box data-testid="Catalog.Box">
      <MenuBreadcrumbs />
      {menuType === MULTIPLE_CATALOG && <MultipleCatalogMenu />}
      {menuType === SINGLE_CATALOG && <SingleCatalogMenu />}
      {!menuType && (
        <Box display="flex" flex={1} justifyContent="center" marginTop="2rem">
          <Typography variant="h5">{t('catalog.body.emptyMessage')}</Typography>
        </Box>
      )}
      <Route path={`${path}/csv`} component={MenuExportCsv} />
    </Box>
  );
};

export default MenuPage;
