import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormError from 'components/form-error/form-error.component';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  getMonths,
  getMonthsWithNoneOption,
} from '../birth-month-day-select.utils';

const MonthSelect = ({ required }) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name="birthMonth"
      rules={{ required: required }}
      render={({ field: { onChange, ref, ...rest } }) => (
        <FormControl fullWidth>
          <InputLabel id="month-select-label">
            {`${t('forms.dateOfBirth.month.label')}${required ? '*' : ''}`}
          </InputLabel>
          <Select
            labelId="month-select-label"
            inputProps={{
              'data-testid': 'MonthSelect.Input',
            }}
            data-testid="MonthSelect.Select"
            id="month-select"
            onChange={e => {
              onChange(e.target.value);
            }}
            MenuProps={{
              MenuListProps: {
                'data-testid': 'BirthMonthDaySelect.Select.Month.Menu',
              },
            }}
            inputRef={ref}
            {...rest}
          >
            {(required ? getMonths() : getMonthsWithNoneOption()).map(month => (
              <MenuItem key={month.id} value={month.id}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
          {errors.birthMonth && (
            <FormError
              data-testid="MonthSelect.FormError"
              errorMessage={t('forms.errors.requiredField')}
            />
          )}
        </FormControl>
      )}
    />
  );
};

export default MonthSelect;
