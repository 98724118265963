import React from 'react';

import * as S from './breadcrumbs.styles';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';

const Breadcrumbs = ({ children, isdark, maxWidth }) => (
  <Hidden only={['xs', 'sm']}>
    <S.Wrapper>
      <Container maxWidth={maxWidth} data-testid="Breadcrumbs.Container">
        <S.Container isdark={isdark}>{children}</S.Container>
      </Container>
    </S.Wrapper>
  </Hidden>
);

export default Breadcrumbs;
