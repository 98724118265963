import { getCenter } from 'geolib';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors as appSelectors } from 'redux/app/app.slice';
import { selectRequestedStoresWithOrderingDisabled } from 'redux/locations/locations.selectors';
import {
  getFormattedLatLng,
  getLatLngFromStores,
  updateMap,
} from '../locations.utils';
import LocationsMapMarker from './locations-map-marker/locations-map-marker.component';
import { useMapStyles } from './locations-map.hooks';
import * as S from './locations-map.styles';

const LocationsMap = props => {
  const stores = useSelector(selectRequestedStoresWithOrderingDisabled);
  const locationFetchingLatLon = useSelector(
    appSelectors.selectLocationFetchingLatLon,
  );
  const {
    latitude: requestedAddressLatitude,
    longitude: requestedAddressLongitude,
  } = locationFetchingLatLon;

  const styles = useMapStyles();
  const [maps, setMaps] = useState();
  const [map, setMap] = useState();

  const { latitude, longitude } = !!requestedAddressLatitude
    ? locationFetchingLatLon
    : getCenter(getLatLngFromStores(stores));

  useEffect(() => {
    if (maps && map && !requestedAddressLatitude) {
      updateMap(map, maps, stores);
    }
  }, [map, maps, requestedAddressLatitude, stores]);

  return (
    <S.Container data-testid="LocationsMap.Container">
      <GoogleMapReact
        center={getFormattedLatLng(latitude, longitude)}
        defaultZoom={14}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map: gMap, maps: gMaps }) => {
          if (gMaps) setMaps(gMaps);
          if (gMap) setMap(gMap);
        }}
        options={{ styles }}
      >
        {!!requestedAddressLatitude && (
          <S.RequestedAddressPin
            lat={requestedAddressLatitude}
            lng={requestedAddressLongitude}
          />
        )}

        {stores.map(store => (
          <LocationsMapMarker
            key={`${store.storeId}marker`}
            lat={store.latitude}
            lng={store.longitude}
            store={store}
            {...props}
          />
        ))}
      </GoogleMapReact>
    </S.Container>
  );
};

export default LocationsMap;
