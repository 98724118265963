import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import RHFDevtoolsWrapper from 'components/rhf-devtools-wrapper/rhf-devtools-wrapper.component';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddToCartButtton from './add-to-cart-button/add-to-cart-button.component';
import ItemDescription from './item-description/item-description.component';
import ItemInstructionAdditionalText from './item-instruction-additional-text/item-instruction-additional-text.component';
import ItemInstructions from './item-instructions/item-instructions.component';
import ItemMediumImage from './item-medium-image/item-medium-image.component';
import ItemQtyPicker from './item-qty-picker/item-qty-picker.component';
import { useMenuModal } from './menu-modal.hooks';
import * as S from './menu-modal.styles';
import NutritionalInfoLink from './nutritional-info-link/nutritional-info-link.component';
import NutritionalInfo from './nutritional-info/nutritional-info.component';
import OptionGroupTabs from './option-group-tabs/option-group-tabs.component';
import { useEffect } from 'react';

const MenuModal = ({ open }) => {
  const { t } = useTranslation();

  const { getValues, control, trigger, unregister } = useFormContext();

  const {
    handleClose,
    onClose,
    visibility,
    onError,
    onSubmit,
    handleTabChange,
    filteredEditOptionsByGroupId,
    tabVal,
    extendedAttributes,
    mediumImage,
    title,
    shortDescription,
    longDescription,
    item,
    selectionGroups,
    isCustomizations,
    tabValues,
    customizationGroups,
    editInfo,
  } = useMenuModal({ getValues, trigger });

  useEffect(() => {
    return () => unregister();
  }, [unregister]);

  return (
    <S.CustomDialog
      open={open}
      scroll="paper"
      fullWidth
      onClose={onClose}
      PaperProps={{ 'data-testid': 'CatalogModal.CustomDialog' }}
      aria-labelledby={`dialog-title-${item?.itemId}`}
    >
      <S.Close
        data-testid="CustomDialog.Close"
        onClick={handleClose}
        tabIndex="0"
        aria-label={t('dialogs.close.ariaLabel')}
      >
        <CloseIcon />
      </S.Close>

      <S.Content id="CatalogModal.Content">
        <ItemMediumImage mediumImage={mediumImage} />

        {!visibility.visible && (
          <Box m={1}>
            <Alert data-testid="MenuModal.Alert.Visibility" severity="warning">
              {t('catalog.modal.notVisible')}
            </Alert>
          </Box>
        )}

        <NutritionalInfo extendedAttributes={extendedAttributes} />

        <Box mb="2em" mt="2em">
          <ItemDescription
            title={title}
            shortDescription={shortDescription}
            longDescription={longDescription}
            itemId={item?.itemId}
          />

          <OptionGroupTabs
            selectionGroups={selectionGroups}
            isCustomizations={isCustomizations}
            tabVal={tabVal}
            handleTabChange={handleTabChange}
            tabValues={tabValues}
            filteredEditOptionsByGroupId={filteredEditOptionsByGroupId}
            editInfo={editInfo}
            customizationGroups={customizationGroups}
          />

          <ItemInstructions editInfo={editInfo} />

          <ItemInstructionAdditionalText />
        </Box>

        <NutritionalInfoLink extendedAttributes={extendedAttributes} />
      </S.Content>

      <S.CartActions>
        <Grid container>
          <ItemQtyPicker />

          <AddToCartButtton onSubmit={onSubmit} onError={onError} />
        </Grid>
      </S.CartActions>

      <RHFDevtoolsWrapper control={control} />
    </S.CustomDialog>
  );
};

export default MenuModal;
