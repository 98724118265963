import { Box } from '@material-ui/core';
import LinkifyParse from 'components/linkify-parse/linkify-parse.component';
import InformationField from 'components/options-fields/information-field/information-field.component';
import OrderOptionSelect from 'components/options-fields/order-option-select/order-option-select.component';
import React from 'react';
import * as S from './order-options.styles';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useStoreOrderOptions } from 'pages/store/new-order-form/useStoreOrderOptions.hooks';

const OrderOptions = ({ label, store, orderType, defaultValue, children }) => {
  const {
    fieldNames: { orderOption: name, informationField },
  } = useFieldNames();

  const {
    orderOptions: options,
    defaultOrderOption: fallbackOrderOption,
    orderOption,
    skip,
  } = useStoreOrderOptions(store, orderType);

  const defaultOrderOption = defaultValue
    ? options?.find(oo => oo.optionName === defaultValue?.optionName)
    : null;
  const defaultInformation = defaultValue?.informationFieldInput ?? '';

  if (skip) return null;

  return (
    <Box data-testid="OrderOptions.Box">
      <OrderOptionSelect
        defaultValue={defaultOrderOption ?? fallbackOrderOption}
        sortedOptions={options}
        label={label}
        name={name}
      />

      {orderOption?.instructions && (
        <S.OptionInstructions component="div" tabIndex="0">
          <LinkifyParse>{orderOption.instructions}</LinkifyParse>
        </S.OptionInstructions>
      )}

      {orderOption?.informationField && (
        <InformationField
          selectedOption={orderOption}
          name={informationField}
          defaultValue={defaultInformation}
        />
      )}
      <Box marginTop="2em">{children}</Box>
    </Box>
  );
};

export default OrderOptions;
