import { Box, Divider, Typography } from '@material-ui/core';
import AddressControls from 'components/address/address-controls/address-controls.component';
import Form from 'components/form/form.component';
import OrderTypeSelector from 'components/new-order/order-type-selector/order-type-selector.component';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOrderTypeSelectors } from 'pages/store/new-order-form/useOrderTypeSelectors.hooks';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import useUnregisterAddressForPickup from './useUnregisterAddressForPickup.hooks';
import RHFDevtoolsWrapper from 'components/rhf-devtools-wrapper/rhf-devtools-wrapper.component';
import { useStorePageOrderAvailability } from './useOrderAvailability.hooks';
import StorePageSelections from './store-page-selections/store-page-selections.component';
import { useLocation } from 'react-router';
import useResetOrderOption from './useResetOrderOption';
import { useSelector } from 'react-redux';
import {
  makeSelectIsMenuBrowsingStore,
  selectIsCatering,
} from 'redux/locations/locations.selectors';
import { useGetAddressLabel } from 'components/address/address-controls/address-controls.hooks';

const NewOrderForm = ({ store }) => {
  const { t } = useTranslation();
  const { fieldNames } = useFieldNames();
  const formMethods = useForm({ shouldUnregister: true });
  const { watch, control, unregister, setValue } = formMethods;
  const orderType = watch(fieldNames.orderType);
  const isMenuBrowsingStore = useSelector(
    makeSelectIsMenuBrowsingStore(store.storeId),
  );
  const isCatering = useSelector(selectIsCatering(store.storeId));
  const label = useGetAddressLabel(isCatering);

  const address = watch(fieldNames.address);

  useStorePageOrderAvailability(store, address, orderType);

  useUnregisterAddressForPickup(orderType, unregister);
  useResetOrderOption(store, orderType, setValue);

  const { orderTypes } = useOrderTypeSelectors(store);

  const location = useLocation();
  const locationState = location.state;

  const defaultOrderType = useMemo(() => {
    if (
      locationState?.orderType &&
      orderTypes?.find(orderType => orderType.type === locationState?.orderType)
    )
      return locationState?.orderType;
    else return orderTypes?.[0]?.type;
  }, [locationState?.orderType, orderTypes]);

  if (!isMenuBrowsingStore && (!orderTypes || orderTypes?.length < 1)) {
    return <Typography>{t('errors.orderingUnavailable')}</Typography>;
  }

  return (
    <FormProvider {...formMethods}>
      <Form>
        <OrderTypeSelector
          types={orderTypes}
          defaultValue={defaultOrderType}
          name={fieldNames.orderType}
        />

        <Box mb="2em">
          <Divider orientation="horizontal" />
        </Box>

        {orderType === 'DELIVERY' && (
          <AddressControls orderType={orderType} label={label} />
        )}

        <StorePageSelections store={store} orderType={orderType} />
      </Form>
      <RHFDevtoolsWrapper control={control} />
    </FormProvider>
  );
};

export default NewOrderForm;
