import { Tabs } from '@material-ui/core';
import styled from 'styled-components';

export const CustomTabs = styled(Tabs)`
  .MuiTabs-indicator {
    height: 4px;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiTab-wrapper {
    text-transform: none;
  }

  .Mui-selected {
    .MuiTab-wrapper {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
