import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import * as S from './item-qty-picker.styles';
import { useTranslation } from 'react-i18next';
import { useItemQtyPicker } from './item-qty-picker.hooks';

const ItemQtyPicker = () => {
  const { t } = useTranslation();

  const {
    hideQtyPicker,
    visibility,
    handleAdd,
    handleRemove,
    itemQuantity,
    isMenuBrowsingStore,
  } = useItemQtyPicker();

  if (hideQtyPicker) return null;

  return (
    <S.QtyContainer item xs={12} sm={4} data-testid="CatalogModal.QtyContainer">
      {t('catalog.modal.addToCart.quantity')}
      <S.QtyButton
        color="primary"
        size="small"
        aria-label={t('checkout.items.quantity.decrement', {
          quantity: itemQuantity,
        })}
        onClick={handleRemove}
        data-testid="CatalogModal.QtyButton.Decrease"
        disabled={!visibility.visible || isMenuBrowsingStore}
      >
        <RemoveIcon />
      </S.QtyButton>
      <span
        aria-label={t('catalog.modal.item.quantity.ariaLabel', {
          itemQuantity,
        })}
        tabIndex="0"
      >
        {itemQuantity}
      </span>
      <S.QtyButton
        color="primary"
        size="small"
        aria-label={t('checkout.items.quantity.increment', {
          quantity: itemQuantity,
        })}
        onClick={handleAdd}
        data-testid="CatalogModal.QtyButton.Increase"
        disabled={!visibility.visible || isMenuBrowsingStore}
      >
        <AddIcon />
      </S.QtyButton>
    </S.QtyContainer>
  );
};

export default ItemQtyPicker;
