import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import { selectDisplayTotalWithoutGratuityWithTip } from 'redux/cart/cart.selectors';
import CheckoutContext from '../checkout.context';
import CheckoutOffersSectionApplicable from './checkout-offers-section-applicable/checkout-offers-section-applicable.component';
import CheckoutOffersSectionSignup from './checkout-offers-section-signup/checkout-offers-section-signup.component';
import { useCheckoutOffersSectionComponent } from './checkout-offers-section.hooks';

const CheckoutOffersSection = () => {
  const { applicableOffers, discounts } = useCheckoutOffersSectionComponent();
  const { openOffersModal } = useContext(CheckoutContext);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const displayTotalWithTip = useSelector(
    selectDisplayTotalWithoutGratuityWithTip,
  );

  return (
    <>
      {isLoggedIn &&
        displayTotalWithTip > 0 &&
        discounts?.length < 1 &&
        applicableOffers?.length > 0 && (
          <CheckoutOffersSectionApplicable
            applicableOffers={applicableOffers}
            openModal={openOffersModal}
          />
        )}
      {!isLoggedIn && <CheckoutOffersSectionSignup />}
    </>
  );
};

export default CheckoutOffersSection;
