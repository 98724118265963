import React from 'react';

import * as S from './custom-dialog.styles';
import ARImage from '../ar-image/ar-image.component';
import CloseIcon from '@material-ui/icons/Close';
import {
  dialogDescription1,
  dialogDescription2,
  dialogTitle,
} from './custom-dialog.constants';
import { useTranslation } from 'react-i18next';

const CustomDialog = ({
  open,
  close,
  title,
  children,
  image,
  originalImage,
  testId = 'CustomDialog.Container',
  paperId,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <S.Container
      fullWidth
      open={open}
      onClose={close}
      maxWidth="xs"
      PaperProps={{ 'data-testid': testId, id: paperId }}
      aria-labelledby={dialogTitle}
      aria-describedby={`${dialogDescription1} ${dialogDescription2}`}
      {...rest}
    >
      <S.Close
        data-testid="CustomDialog.Close"
        onClick={close}
        tabIndex="0"
        aria-label={t('dialogs.close.ariaLabel')}
      >
        <CloseIcon />
      </S.Close>
      {image && <ARImage src={image} alt="" />}
      {originalImage && <S.OriginalImg src={originalImage} alt="" />}
      <S.Content className="dialogContent">
        {!!title && (
          <S.Title component="h1" variant="h4" id={dialogTitle}>
            {title}
          </S.Title>
        )}
        {children}
      </S.Content>
    </S.Container>
  );
};

export default CustomDialog;
