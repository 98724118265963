import RHFDevtoolsWrapper from 'components/rhf-devtools-wrapper/rhf-devtools-wrapper.component';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import CheckoutSectionBase from '../checkout-section/checkout-section-base.component';
import CheckoutOrderDetailsEdit from './checkout-order-details-edit/checkout-order-details-edit.component';
import CheckoutOrderDetailsPreview from './checkout-order-details-preview/checkout-order-details-preview.component';
import { useCheckoutOrderDetailsSectionComponent } from './checkout-order-details-section.hooks';

const CheckoutOrderDetailsSection = () => {
  const { orderDetailsContext, formMethods } =
    useCheckoutOrderDetailsSectionComponent();

  return (
    <CheckoutSectionBase
      context={orderDetailsContext}
      data-testid="CheckoutOrderDetailsSection.CheckoutSection"
    >
      <CheckoutOrderDetailsPreview />
      <FormProvider {...formMethods}>
        <CheckoutOrderDetailsEdit />
        <RHFDevtoolsWrapper control={formMethods.control} />
      </FormProvider>
    </CheckoutSectionBase>
  );
};

export default React.memo(CheckoutOrderDetailsSection);
