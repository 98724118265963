import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as S from '../account-settings-form.styles';
import { Grid, Box } from '@material-ui/core';
import { InfoBox } from 'components/card-item/card-item.styles';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import { useTranslation } from 'react-i18next';
import { selectDeliveryAddressList } from 'redux/user/user.selectors';
import AddressDialog from 'pages/account-settings/address-dialog/address-dialog.component';
import AddressList from './address-list/address-list.component';

const Addresses = () => {
  const { t } = useTranslation();
  const addressList = useSelector(selectDeliveryAddressList);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [showAddressDialog, setShowAddressDialog] = useState(false);

  const handleCardClicked = address => {
    setAddressToEdit(address);
    setShowAddressDialog(true);
  };

  const closeAddressDialog = () => {
    setShowAddressDialog(false);
  };

  return (
    <>
      <S.SectionContainer data-testid="AccountSettings.SectionContainer.MyAddresses">
        <S.SectionTitle tabIndex="0">
          {t('accountSettings.myAddresses')}
        </S.SectionTitle>
        <Grid container spacing={1}>
          {addressList.map(address => (
            <AddressList
              address={address}
              key={address.addressId}
              handleCardClicked={handleCardClicked}
            />
          ))}

          <Grid item xs={12} sm={6} md={4}>
            <InfoBox
              className="addBox"
              onClick={() => handleCardClicked(null)}
              data-testid="AccountSettings.Grid.AddAddressCard"
            >
              <Box m="auto">
                <IncButtonDark>
                  {t('accountSettings.addAddressAction')}
                </IncButtonDark>
              </Box>
            </InfoBox>
          </Grid>
        </Grid>
      </S.SectionContainer>

      {showAddressDialog && (
        <AddressDialog
          aria-label={t('accountSettings.addressModal.ariaLabel')}
          tabIndex="0"
          open
          close={closeAddressDialog}
          address={addressToEdit}
          testId="AccountSettings.AddressDialog"
        />
      )}
    </>
  );
};

export default Addresses;
