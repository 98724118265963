import MessageLongDescription from '../message-long-description/message-long-description.component';
import MessageShortDescription from '../message-short-description/message-short-description.component';
import MessageTitle from '../message-title/message-title.component';

const DefaultMessageView = ({ title, shortDescription, longDescription }) => {
  return (
    <>
      <MessageTitle title={title} />
      <MessageShortDescription description={shortDescription} />
      <MessageLongDescription description={longDescription} />
    </>
  );
};

export default DefaultMessageView;
