import i18next from 'i18next';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { actions as messageActions } from 'redux/data/messages/messages.slice';
import { actions as offerActions } from 'redux/data/offers/offers.slice';
import { selectAvailableTimesTZ } from 'redux/data/orderavailability/orderavailability.selectors';
import { getAnalytics } from 'util/analytics';
import { getClientId } from 'util/clientId';
import INC_BASE_API from '../../apis/incentivio-api';
import { getResponseErrorMessage } from '../../apis/incentivio-api.util';
import { getLanguageKey, toISOStringLocal } from '../../utils';
import { selectors as appSelectors } from '../app/app.slice';
import { removeAllDiscounts, resetCart } from '../cart/cart.actions';
import { selectLocationId, selectOrderId } from '../cart/cart.selectors';
import { selectors as clientSelectors } from '../client/client.slice';
import { selectGiftCardDefaultLocation } from '../config/config.selectors';
import {
  selectClientId,
  selectDeliveryAddressId,
  selectDeliveryAddressList,
  selectEmail,
  selectFirstName,
  selectIsLoggedIn,
  selectLastName,
  selectPaymentInstrumentById,
  selectPhoneNumber,
  selectRefreshToken,
  selectUserId,
} from './user.selectors';
import UserTypes from './user.types';
import { getActivityEvents } from './user.utils';

export const signUpUserStart = () => ({
  type: UserTypes.SIGN_UP_USER_START,
});

export const signUpUserSuccess = () => ({
  type: UserTypes.SIGN_UP_USER_SUCCESS,
});

export const signUpUserFailure = errorMessage => ({
  type: UserTypes.SIGN_UP_USER_FAILURE,
  payload: errorMessage,
});

export const signInUserStart = () => ({
  type: UserTypes.SIGN_IN_USER_START,
});

export const signInUserSuccess = data => ({
  type: UserTypes.SIGN_IN_USER_SUCCESS,
  payload: data,
});

export const signInUserFailure = () => ({
  type: UserTypes.SIGN_IN_USER_FAILURE,
});

export const refreshTokenStart = () => ({
  type: UserTypes.REFRESH_TOKEN_START,
});

export const refreshTokenSuccess = accessToken => ({
  type: UserTypes.REFRESH_TOKEN_SUCCESS,
  payload: accessToken,
});

export const refreshTokenFailure = errorMessage => ({
  type: UserTypes.REFRESH_TOKEN_FAILURE,
  payload: errorMessage,
});

export const signOut = () => ({
  type: UserTypes.SIGN_OUT,
});

export const fetchUserDataStart = () => ({
  type: UserTypes.FETCH_USER_DATA_START,
});

export const fetchUserDataSuccess = userData => ({
  type: UserTypes.FETCH_USER_DATA_SUCCESS,
  payload: userData,
});

export const fetchUserDataFailure = errorMessage => ({
  type: UserTypes.FETCH_USER_DATA_FAILURE,
  payload: errorMessage,
});

export const requestPwResetStart = () => ({
  type: UserTypes.REQUEST_PW_RESET_START,
});

export const requestPwResetSuccess = () => ({
  type: UserTypes.REQUEST_PW_RESET_SUCCESS,
});

export const requestPwResetFailure = () => ({
  type: UserTypes.REQUEST_PW_RESET_FAILURE,
});

export const fetchAddressesStart = () => ({
  type: UserTypes.FETCH_ADDRESSES_START,
});

export const fetchAddressesSuccess = addressList => ({
  type: UserTypes.FETCH_ADDRESSES_SUCCESS,
  payload: addressList,
});

export const fetchAddressesFailure = () => ({
  type: UserTypes.FETCH_ADDRESSES_FAILURE,
});

export const fetchPaymentInstrumentsStart = () => ({
  type: UserTypes.FETCH_PAYMENT_INSTRUMENTS_START,
});

export const fetchPaymentInstrumentsSuccess = paymentInstruments => ({
  type: UserTypes.FETCH_PAYMENT_INSTRUMENTS_SUCCESS,
  payload: paymentInstruments,
});

export const fetchPaymentInstrumentsFailure = () => ({
  type: UserTypes.FETCH_PAYMENT_INSTRUMENTS_FAILURE,
});

export const updateUserDataStart = () => ({
  type: UserTypes.UPDATE_USER_DATA_START,
});

export const updateUserDataSuccess = () => ({
  type: UserTypes.UPDATE_ADDRESS_SUCCESS,
});

export const updateUserDataFailure = errorMessage => ({
  type: UserTypes.UPDATE_USER_DATA_FAILURE,
  payload: errorMessage,
});

export const addAddressStart = () => ({
  type: UserTypes.ADD_ADDRESS_START,
});

export const addAddressSuccess = () => ({
  type: UserTypes.ADD_ADDRESS_SUCCESS,
});

export const addAddressFailure = errorMessage => ({
  type: UserTypes.ADD_ADDRESS_FAILURE,
  payload: errorMessage,
});

export const deleteAddressStart = () => ({
  type: UserTypes.DELETE_ADDRESS_START,
});

export const deleteAddressSuccess = () => ({
  type: UserTypes.DELETE_ADDRESS_SUCCESS,
});

export const deleteAddressFailure = errorMessage => ({
  type: UserTypes.DELETE_ADDRESS_FAILURE,
  payload: errorMessage,
});

export const updateAddressStart = () => ({
  type: UserTypes.UPDATE_ADDRESS_START,
});

export const updateAddressSuccess = () => ({
  type: UserTypes.UPDATE_ADDRESS_SUCCESS,
});

export const updateAddressFailure = errorMessage => ({
  type: UserTypes.UPDATE_ADDRESS_FAILURE,
  payload: errorMessage,
});

export const updatePasswordStart = () => ({
  type: UserTypes.UPDATE_PASSWORD_START,
});

export const updatePasswordSuccess = () => ({
  type: UserTypes.UPDATE_PASSWORD_SUCCESS,
});

export const updatePasswordFailure = errorMessage => ({
  type: UserTypes.UPDATE_PASSWORD_FAILURE,
  payload: errorMessage,
});

export const getDistributedMessageDataStart = () => ({
  type: UserTypes.GET_DISTRIBUTED_MESSAGE_DATA_START,
});

export const getDistributedMessageDataSuccess = () => ({
  type: UserTypes.GET_DISTRIBUTED_MESSAGE_DATA_SUCCESS,
});

export const getDistributedMessageDataFailure = () => ({
  type: UserTypes.GET_DISTRIBUTED_MESSAGE_DATA_FAILURE,
});

export const getDistributedOfferDataStart = () => ({
  type: UserTypes.GET_DISTRIBUTED_OFFER_DATA_START,
});

export const getDistributedOfferDataSuccess = () => ({
  type: UserTypes.GET_DISTRIBUTED_OFFER_DATA_SUCCESS,
});

export const getDistributedOfferDataFailure = () => ({
  type: UserTypes.GET_DISTRIBUTED_OFFER_DATA_FAILURE,
});

export const getActivityBasedContentStart = () => ({
  type: UserTypes.GET_ACTIVITY_BASED_CONTENT_START,
});

export const getActivityBasedContentSuccess = data => ({
  type: UserTypes.GET_ACTIVITY_BASED_CONTENT_SUCCESS,
  payload: data,
});

export const getActivityBasedContentFailure = errorMessage => ({
  type: UserTypes.GET_ACTIVITY_BASED_CONTENT_FAILURE,
  payload: errorMessage,
});

export const deleteActivityBasedContent = id => ({
  type: UserTypes.DELETE_ACTIVITY_BASED_CONTENT,
  payload: id,
});

export const redeemOfferByIdStart = () => ({
  type: UserTypes.REDEEM_OFFER_BY_ID_START,
});

export const redeemOfferByIdSuccess = () => ({
  type: UserTypes.REDEEM_OFFER_BY_ID_SUCCESS,
});

export const redeemOfferByIdFailure = errorMessage => ({
  type: UserTypes.REDEEM_OFFER_BY_ID_FAILURE,
  payload: errorMessage,
});

export const getOrderHistoryStart = () => ({
  type: UserTypes.GET_ORDER_HISTORY_START,
});

export const getOrderHistorySuccess = () => ({
  type: UserTypes.GET_ORDER_HISTORY_SUCCESS,
});

export const getOrderHistoryFailure = errorMessage => ({
  type: UserTypes.GET_ORDER_HISTORY_FAILURE,
  payload: errorMessage,
});

export const addPaymentInstrumentStart = () => ({
  type: UserTypes.ADD_PAYMENT_INSTRUMENT_START,
});

export const addPaymentInstrumentSuccess = () => ({
  type: UserTypes.ADD_PAYMENT_INSTRUMENT_SUCCESS,
});

export const addPaymentInstrumentFailure = errorMessage => ({
  type: UserTypes.ADD_PAYMENT_INSTRUMENT_FAILURE,
  payload: errorMessage,
});

export const editPaymentInstrumentStart = () => ({
  type: UserTypes.EDIT_PAYMENT_INSTRUMENT_START,
});

export const editPaymentInstrumentSuccess = () => ({
  type: UserTypes.EDIT_PAYMENT_INSTRUMENT_SUCCESS,
});

export const editPaymentInstrumentFailure = errorMessage => ({
  type: UserTypes.EDIT_PAYMENT_INSTRUMENT_FAILURE,
  payload: errorMessage,
});

export const getGiftCardDataStart = () => ({
  type: UserTypes.GET_GIFT_CARD_DATA_START,
});

export const getGiftCardDataSuccess = () => ({
  type: UserTypes.GET_GIFT_CARD_DATA_SUCCESS,
});

export const getGiftCardDataFailure = () => ({
  type: UserTypes.GET_GIFT_CARD_DATA_FAILURE,
});

export const addGiftCardValueStart = () => ({
  type: UserTypes.ADD_GIFT_CARD_VALUE_START,
});

export const addGiftCardValueSuccess = () => ({
  type: UserTypes.ADD_GIFT_CARD_VALUE_SUCCESS,
});

export const addGiftCardValueFailure = errorMessage => ({
  type: UserTypes.ADD_GIFT_CARD_VALUE_FAILURE,
  payload: errorMessage,
});

export const deletePaymentInstrumentStart = () => ({
  type: UserTypes.DELETE_PAYMENT_INSTRUMENT_START,
});

export const deletePaymentInstrumentSuccess = () => ({
  type: UserTypes.DELETE_PAYMENT_INSTRUMENT_SUCCESS,
});

export const deletePaymentInstrumentFailure = errorMessage => ({
  type: UserTypes.DELETE_PAYMENT_INSTRUMENT_FAILURE,
  payload: errorMessage,
});

export const buyGiftCardStart = () => ({
  type: UserTypes.BUY_GIFT_CARD_START,
});

export const buyGiftCardSuccess = () => ({
  type: UserTypes.BUY_GIFT_CARD_SUCCESS,
});

export const buyGiftCardFailure = errorMessage => ({
  type: UserTypes.BUY_GIFT_CARD_FAILURE,
  payload: errorMessage,
});

export const getLoyaltyAccountsStart = () => ({
  type: UserTypes.GET_LOYALTY_ACCOUNTS_START,
});

export const getLoyaltyAccountsSuccess = data => ({
  type: UserTypes.GET_LOYALTY_ACCOUNTS_SUCCESS,
  payload: data,
});

export const getLoyaltyAccountsFailure = errorMessage => ({
  type: UserTypes.GET_LOYALTY_ACCOUNTS_FAILURE,
  payload: errorMessage,
});

export const getLoyaltyAccountsHistoryStart = () => ({
  type: UserTypes.GET_LOYALTY_ACCOUNTS_HISTORY_START,
});

export const getLoyaltyAccountsHistorySuccess = loyaltyHistoryData => ({
  type: UserTypes.GET_LOYALTY_ACCOUNTS_HISTORY_SUCCESS,
  payload: loyaltyHistoryData,
});

export const getLoyaltyAccountsHistoryFailure = errorMessage => ({
  type: UserTypes.GET_LOYALTY_ACCOUNTS_HISTORY_FAILURE,
  payload: errorMessage,
});

export const fetchSurveyFieldsStart = () => ({
  type: UserTypes.FETCH_SURVEY_FIELDS_START,
});

export const fetchSurveyFieldsSuccess = () => ({
  type: UserTypes.FETCH_SURVEY_FIELDS_SUCCESS,
});

export const fetchSurveyFieldFailure = errorMessage => ({
  type: UserTypes.FETCH_SURVEY_FIELDS_FAILURE,
  payload: errorMessage,
});

export const submitSurveyAnswersStart = () => ({
  type: UserTypes.SUBMIT_SURVEY_ANSWERS_START,
});

export const submitSurveyAnswersSuccess = () => ({
  type: UserTypes.SUBMIT_SURVEY_ANSWERS_SUCCESS,
});

export const submitSurveyAnswersFailure = errorMessage => ({
  type: UserTypes.SUBMIT_SURVEY_ANSWERS_FAILURE,
  payload: errorMessage,
});

export const uploadProfilePicStart = () => ({
  type: UserTypes.UPLOAD_PROFILE_PIC_START,
});

export const uploadProfilePicSuccess = () => ({
  type: UserTypes.UPLOAD_PROFILE_PIC_SUCCESS,
});

export const uploadProfilePicFailure = errorMessage => ({
  type: UserTypes.UPLOAD_PROFILE_PIC_FAILURE,
  payload: errorMessage,
});

export const addExistingGiftCardStart = () => ({
  type: UserTypes.ADD_EXISTING_GIFT_CARD_START,
});

export const addExistingGiftCardSuccess = () => ({
  type: UserTypes.ADD_EXISTING_GIFT_CARD_SUCCESS,
});

export const addExistingGiftCardFailure = errorMessage => ({
  type: UserTypes.ADD_EXISTING_GIFT_CARD_FAILURE,
  payload: errorMessage,
});

export const setProfilePicStart = () => ({
  type: UserTypes.SET_PROFILE_PIC_START,
});

export const setProfilePicSuccess = () => ({
  type: UserTypes.SET_PROFILE_PIC_SUCCESS,
});

export const setProfilePicFailure = errorMessage => ({
  type: UserTypes.SET_PROFILE_PIC_FAILURE,
  payload: errorMessage,
});

export const getGiftCardByTokenStart = () => ({
  type: UserTypes.GET_GIFT_CARD_BY_TOKEN_START,
});

export const getGiftCardByTokenSuccess = () => ({
  type: UserTypes.GET_GIFT_CARD_BY_TOKEN_SUCCESS,
});

export const getGiftCardByTokenFailure = errorMessage => ({
  type: UserTypes.GET_GIFT_CARD_BY_TOKEN_FAILURE,
  payload: errorMessage,
});

export const signUpUserStartAsync = formData => {
  const {
    email,
    firstName,
    EMAIL_OPT_IN,
    DIETARY_PREFERENCES,
    ALLERGIES,
    lastName,
    password,
    phoneNumber,
    referralCode,
    dateOfBirth,
    authenticationProvider,
    jwt,
  } = formData;
  return async (dispatch, getState) => {
    dispatch(signUpUserStart());
    const emailOptIn = EMAIL_OPT_IN.toString().toUpperCase();

    const body = {
      baseLanguagePreferences: getLanguageKey().toUpperCase(),
      email,
      extendedAttributes: {
        USER_AFFILIATION: 'CUSTOMER',
        EMAIL_OPT_IN: emailOptIn,
        DIETARY_PREFERENCES:
          DIETARY_PREFERENCES?.length > 0
            ? DIETARY_PREFERENCES?.toString().toUpperCase()
            : undefined,
        ALLERGIES:
          ALLERGIES?.length > 0
            ? ALLERGIES?.toString().toUpperCase()
            : undefined,
        SMS_TXN_OPT_IN: 'TRUE',
        SMS_MARKETING_OPT_IN: emailOptIn,
      },
      firstName,
      howAcquired: 'THROUGH_WEB',
      lastName,
      password,
      phoneNumber,
      referralCode,
      dateOfBirth: dateOfBirth?.isLuxonDateTime
        ? dateOfBirth.toISODate()
        : dateOfBirth,
      authenticationProvider,
      jwt,
    };

    return INC_BASE_API.post('/useraccounts', body)
      .then(response => {
        getAnalytics().trackSignUp({
          first_name: firstName,
          last_name: lastName,
          email,
          phone: phoneNumber,
          username: email,
        });
        dispatch(signUpUserSuccess());
        if (response.data.verified) {
          !!jwt
            ? dispatch(
                socialSignInStartAsync({
                  email: email,
                  authProvider: authenticationProvider,
                  jwt,
                }),
              )
            : dispatch(signInStartAsync({ email: email, password: password }));
        }
        return response;
      })
      .catch(error => {
        dispatch(signUpUserFailure());
        return getResponseErrorMessage(error, dispatch);
      });
  };
};

export const signInStartAsync = ({ email, password, remember_me }) => {
  const queryString = require('query-string');
  return async (dispatch, getState) => {
    try {
      dispatch(signInUserStart());

      const headers = {
        Authorization: 'Basic Y2xpZW50YXBwOjEyMzQ1Ng==',
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      };

      const response = await INC_BASE_API.post(
        '/oauth/token',
        queryString.stringify({
          username: email,
          password: password,
          clientId: clientSelectors.selectClientId(getState()),
          grant_type: 'password',
          scope: 'read write',
        }),
        {
          headers,
        },
      );

      const token = response.data.access_token;
      const refreshToken = response.data.refresh_token;
      getAnalytics().trackSignIn({ username: email });
      dispatch(
        signInUserSuccess({
          token,
          refreshToken,
          rememberMe: remember_me,
        }),
      );
      return response;
    } catch (error) {
      dispatch(signInUserFailure());
      return error;
    }
  };
};

export const getGoogleJwt = authorizationCode => {
  return async dispatch => {
    try {
      dispatch(signInUserStart());

      return await INC_BASE_API.get('/oauth2/authorizationCode/google', {
        params: {
          authorizationCode,
          clientId: getClientId(),
        },
      });
    } catch (error) {
      dispatch(signInUserFailure());
      throw error;
    }
  };
};

export const socialSignInStartAsync = ({ email, authProvider, jwt }) => {
  const queryString = require('query-string');
  return async (dispatch, getState) => {
    try {
      dispatch(signInUserStart());

      const headers = {
        Authorization: 'Basic Y2xpZW50YXBwOjEyMzQ1Ng==',
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      };

      const response = await INC_BASE_API.post(
        '/oauth/token',
        queryString.stringify({
          username: email,
          clientId: clientSelectors.selectClientId(getState()),
          grant_type: 'password',
          scope: 'read write',
          authentication_provider: authProvider,
          jwt,
        }),
        {
          headers,
        },
      );

      const token = response.data.access_token;
      const refreshToken = response.data.refresh_token;
      getAnalytics().trackSignIn({ username: email });
      dispatch(
        signInUserSuccess({
          token,
          refreshToken,
          rememberMe: true,
          authProvider,
        }),
      );
      return response;
    } catch (error) {
      dispatch(signInUserFailure());
      throw error;
    }
  };
};

export const refreshToken = failedRequest => {
  return async (dispatch, getState) => {
    try {
      dispatch(refreshTokenStart());

      const headers = {
        Authorization: 'Basic Y2xpZW50YXBwOjEyMzQ1Ng==',
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      };

      const queryString = require('query-string');
      const refreshToken = selectRefreshToken(getState());
      const res = await INC_BASE_API.post(
        '/oauth/token',
        queryString.stringify({
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
          scope: 'read write',
        }),
        {
          headers,
          skipAuthRefresh: true,
        },
      );
      const newToken = res.data.access_token;
      const newRefreshToken = res.data.refresh_token;
      dispatch(
        refreshTokenSuccess({
          newToken,
          newRefreshToken,
        }),
      );
      failedRequest.response.config.headers[
        'Authorization'
      ] = `Bearer ${newToken}`;
      return res;
    } catch (e) {
      dispatch(refreshTokenFailure(getResponseErrorMessage(e, dispatch)));
      dispatch(resetCart());
      dispatch(signOut());
      throw e;
    }
  };
};

export const signOutStartAsync = () => {
  return async (dispatch, getState) => {
    const username = selectEmail(getState());
    try {
      await dispatch(removeAllDiscounts());
      await INC_BASE_API.get('/oauth/logout', {
        skipAuthRefresh: true,
        authenticated: true,
      });
    } catch (error) {
      toast.error(getResponseErrorMessage(error, dispatch));
      return error;
    } finally {
      getAnalytics().trackSignOut({ username });
      dispatch(signOut());
    }
  };
};

export const fetchUserDataStartAsync = () => {
  return async dispatch => {
    try {
      dispatch(fetchUserDataStart());
      const response = await INC_BASE_API.get('/useraccounts', {
        authenticated: true,
      });
      dispatch(fetchUserDataSuccess(response.data));
      getAnalytics().identify(response.data);
      return response.data;
    } catch (error) {
      dispatch(fetchUserDataFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const requestPwReset = email => {
  return async dispatch => {
    try {
      dispatch(requestPwResetStart());
      const response = await INC_BASE_API.post(
        `/useraccounts/${email}/requestpwreset`,
        {},
      );
      dispatch(requestPwResetSuccess());
      return response.status;
    } catch (error) {
      dispatch(requestPwResetFailure(getResponseErrorMessage(error, dispatch)));
      return getResponseErrorMessage(error, dispatch);
    }
  };
};

export const fetchAddressesStartAsync = () => {
  return async (dispatch, getState) => {
    const userId = selectUserId(getState());
    const clientId = selectClientId(getState());
    dispatch(fetchAddressesStart());
    return INC_BASE_API.get('/deliveryaddresses', {
      params: {
        userId,
        clientId,
      },
      authenticated: true,
    })
      .then(response => {
        dispatch(
          fetchAddressesSuccess({
            deliveryAddressList: response.data.deliveryAddressList,
            id: response.data.id,
          }),
        );
        return response;
      })
      .catch(error => {
        dispatch(
          fetchAddressesFailure(getResponseErrorMessage(error, dispatch)),
        );
        return error;
      });
  };
};

export const fetchPaymentInstrumentsStartAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchPaymentInstrumentsStart());
      const response = await INC_BASE_API.get('/paymentinstruments', {
        authenticated: true,
      });
      dispatch(
        fetchPaymentInstrumentsSuccess(response.data.paymentInstruments),
      );
      return response;
    } catch (error) {
      dispatch(
        fetchPaymentInstrumentsFailure(
          getResponseErrorMessage(error, dispatch),
        ),
      );
      return null;
    }
  };
};

export const updateUserData = data => {
  return async dispatch => {
    dispatch(updateUserDataStart());
    try {
      if (data.dateOfBirth?.isLuxonDateTime) {
        data.dateOfBirth = data.dateOfBirth.toISODate();
      }

      const response = await INC_BASE_API.put(
        '/useraccounts',
        { ...data },
        { authenticated: true },
      );

      dispatch(updateUserDataSuccess());
      dispatch(fetchUserDataStartAsync());
      return response;
    } catch (error) {
      dispatch(updateUserDataFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const updateAddress = editedAddress => {
  return async (dispatch, getState) => {
    dispatch(updateAddressStart());
    try {
      const addressList = selectDeliveryAddressList(getState());
      const clientId = selectClientId(getState());
      const userId = selectUserId(getState());
      const id = selectDeliveryAddressId(getState());
      const addresses = addressList.filter(
        address => address.addressId !== editedAddress.addressId,
      );

      const data = {
        addresses: [...addresses, editedAddress],
        clientId,
        userId,
        id,
      };

      const response = await INC_BASE_API.put(
        '/save/multipledeliveryaddresses',
        { ...data },
        { authenticated: true },
      );

      dispatch(updateAddressSuccess());
      dispatch(fetchAddressesStartAsync());
      return response;
    } catch (error) {
      dispatch(updateAddressFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const addNewAddress = address => {
  return async (dispatch, getState) => {
    const clientId = selectClientId(getState());
    const userId = selectUserId(getState());
    dispatch(addAddressStart());
    try {
      await INC_BASE_API.put(
        '/save/singledeliveryaddress',
        {
          addresses: [address],
          clientId,
          userId,
        },
        { authenticated: true },
      );

      dispatch(addAddressSuccess());
      const currentAddressList = [
        ...(selectDeliveryAddressList(getState()) ?? []),
      ];
      const addressesResponse = await dispatch(fetchAddressesStartAsync());
      return addressesResponse?.data?.deliveryAddressList.filter(
        responseAddress =>
          currentAddressList.length < 1 ||
          currentAddressList.every(
            currentAddress =>
              responseAddress.addressId !== currentAddress.addressId,
          ),
      )?.[0];
    } catch (error) {
      dispatch(addAddressFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const deleteAddress = addressId => {
  return async (dispatch, getState) => {
    dispatch(deleteAddressStart());
    try {
      const response = await INC_BASE_API.delete(
        `/deliveryaddresses/${addressId}`,
        { authenticated: true },
      );

      dispatch(deleteAddressSuccess());
      dispatch(fetchAddressesStartAsync());
      return response;
    } catch (error) {
      dispatch(deleteAddressFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const updatePassword = ({ oldPassword, newPassword }) => {
  return async (dispatch, getState) => {
    dispatch(updatePasswordStart());
    try {
      const response = await INC_BASE_API.post(
        '/useraccounts/updatepassword',
        {
          oldPassword,
          newPassword,
        },
        {
          authenticated: true,
          authErrorHandler: failedRequest => {
            toast.error(
              failedRequest.response?.headers['incentivio-message'] ??
                i18next.t('errors.genericError'),
            );
          },
        },
      );
      dispatch(updatePasswordSuccess());
      return response;
    } catch (error) {
      dispatch(updatePasswordFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const getDistributedMessageData = id => {
  return async dispatch => {
    dispatch(getDistributedMessageDataStart());
    try {
      const response = await INC_BASE_API.get(`/distributedmessages/${id}`, {
        authenticated: true,
      });
      dispatch(getDistributedMessageDataSuccess());
      return response.data;
    } catch (error) {
      dispatch(getDistributedMessageDataFailure());
      toast.error(getResponseErrorMessage(error, dispatch));
      return null;
    }
  };
};

export const getDistributedOfferData = id => {
  return async dispatch => {
    dispatch(getDistributedOfferDataStart());
    try {
      const response = await INC_BASE_API.get(`/distributedoffers/${id}`, {
        authenticated: true,
      });
      dispatch(getDistributedOfferDataSuccess());
      return response.data;
    } catch (error) {
      dispatch(getDistributedOfferDataFailure());
      toast.error(getResponseErrorMessage(error, dispatch));
      return null;
    }
  };
};

export const getActivityBasedContent = (messageId, socialMedia, checkIn) => {
  return async (dispatch, getState) => {
    dispatch(getActivityBasedContentStart());
    const events = getActivityEvents(messageId, socialMedia, checkIn);
    try {
      const storeTimeZone = selectAvailableTimesTZ(getState());
      const orderId = selectOrderId(getState());
      const { latitude, longitude } = appSelectors.selectUserLatLon(getState());
      const clientId = clientSelectors.selectClientId(getState());
      const isLoggedIn = selectIsLoggedIn(getState());

      const response = await INC_BASE_API.post(
        `/getactivitybasedcontent`,
        {
          events,
          languagecode: getLanguageKey().toUpperCase(),
          latitude: latitude ?? 0,
          localDateTime: DateTime.local({ zone: storeTimeZone }).toFormat(
            "yyyy-MM-dd'T'HH:mm:ss",
          ),
          longitude: longitude ?? 0,
          clientId,
        },
        { authenticated: isLoggedIn },
      );

      if (checkIn) {
        getAnalytics().trackCouponEntered({
          orderId,
          promoCode: checkIn,
        });
      }

      dispatch(getActivityBasedContentSuccess(response.data));
      response.data?.offerList?.offers?.length &&
        isLoggedIn &&
        dispatch(offerActions.refreshOffers());
      response.data?.messageList?.messages?.length &&
        dispatch(messageActions.refreshMessages());
      return response.data;
    } catch (error) {
      dispatch(
        getActivityBasedContentFailure(
          getResponseErrorMessage(error, dispatch),
        ),
      );
      return null;
    }
  };
};

export const redeemOfferById = offerId => {
  return async (dispatch, getState) => {
    dispatch(redeemOfferByIdStart());
    const { latitude, longitude } = appSelectors.selectUserLatLon(getState());

    try {
      const response = await INC_BASE_API.post(
        `/redeemofferbyid`,
        {
          distributedOfferId: offerId,
          latitude: latitude ?? 0,
          transactionDate: toISOStringLocal(new Date()),
          longitude: longitude ?? 0,
        },
        { authenticated: true },
      );
      dispatch(redeemOfferByIdSuccess());
      return response;
    } catch (error) {
      dispatch(
        redeemOfferByIdFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const getOrderHistory = (page = 0) => {
  return async dispatch => {
    dispatch(getOrderHistoryStart());
    try {
      const response = await INC_BASE_API.get(`/orders-history`, {
        params: {
          count: '6',
          page,
        },
        authenticated: true,
      });
      dispatch(getOrderHistorySuccess());
      return response.data;
    } catch (error) {
      dispatch(
        getOrderHistoryFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const addPaymentInstrument = (cardInfo, isUsePoolLocationId = false) => {
  return async (dispatch, getState) => {
    dispatch(addPaymentInstrumentStart());
    try {
      const locationId = selectLocationId(getState());
      const orderId = selectOrderId(getState());
      const defaultGiftCardLocation = selectGiftCardDefaultLocation(getState());
      const { token, full_name, month, year, lastFour } = cardInfo;
      const response = await INC_BASE_API.post(
        `/paymentinstruments`,
        {
          expirationMonth: month,
          expirationYear: year,
          isDefault: true,
          locationId: isUsePoolLocationId
            ? defaultGiftCardLocation
            : locationId,
          nickname: full_name,
          paymentToken: token,
          paymentType: 'Card',
          lastFour,
        },
        { authenticated: true },
      );
      dispatch(addPaymentInstrumentSuccess());
      await dispatch(fetchPaymentInstrumentsStartAsync());
      getAnalytics().trackPaymentInfoEntered({ orderId });
      return response.data;
    } catch (error) {
      dispatch(
        addPaymentInstrumentFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const addExistingGiftCard = ({ paymentToken, cardSecurityCode }) => {
  return async (dispatch, getState) => {
    dispatch(addExistingGiftCardStart());
    try {
      const clientId = selectClientId(getState());
      const defaultGiftCardLocation = selectGiftCardDefaultLocation(getState());
      const userId = selectUserId(getState());
      const lastFour = paymentToken.substr(-4);
      const response = await INC_BASE_API.post(
        `/giftcard/paymentinstruments`,
        {
          clientId,
          isDefault: false,
          lastFour,
          locationId: defaultGiftCardLocation,
          nickname: '',
          paymentToken,
          paymentType: 'CARD_NOT_PRESENT',
          sourceType: 'WEB',
          tokenType: '',
          userId,
          zipCode: '',
          cardSecurityCode,
        },
        { authenticated: true },
      );
      dispatch(addExistingGiftCardSuccess());
      dispatch(fetchPaymentInstrumentsStartAsync());
      toast.success(
        i18next.t('addPurchaseGiftCard.addExistingGiftCard.successMessage', {
          lastFour,
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addExistingGiftCardFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const editPaymentInstrument = (paymentInstrumentId, data) => {
  return async (dispatch, getState) => {
    const { isDefault, nickname } = data;
    dispatch(editPaymentInstrumentStart());
    try {
      const response = await INC_BASE_API.put(
        `/paymentinstruments/${paymentInstrumentId}/`,
        {
          isDefault,
          nickname,
        },
        { authenticated: true },
      );

      dispatch(editPaymentInstrumentSuccess());
      dispatch(fetchPaymentInstrumentsStartAsync());
      return response;
    } catch (error) {
      dispatch(
        editPaymentInstrumentFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const getGiftCardData = paymentInstrumentId => {
  return async dispatch => {
    dispatch(getGiftCardDataStart());
    try {
      const response = await INC_BASE_API.get(`/mygiftcard`, {
        params: {
          sourceType: 'WEB',
          cardIdentifier: paymentInstrumentId,
        },
        authenticated: true,
      });
      dispatch(getGiftCardDataSuccess());
      return response.data;
    } catch (error) {
      dispatch(getGiftCardDataFailure());
      toast.error(getResponseErrorMessage(error, dispatch));
      return null;
    }
  };
};

export const addGiftCardValue = (
  amount,
  paymentInstrumentId,
  cardIdentifier,
) => {
  return async (dispatch, getState) => {
    dispatch(addGiftCardValueStart());

    const clientId = selectClientId(getState());
    const email = selectEmail(getState());
    const firstName = selectFirstName(getState());
    const lastName = selectLastName(getState());
    const phone = selectPhoneNumber(getState());
    const userId = selectUserId(getState());
    const defaultGiftCardLocation = selectGiftCardDefaultLocation(getState());
    const { paymentToken } = selectPaymentInstrumentById(paymentInstrumentId)(
      getState(),
    );

    try {
      const response = await INC_BASE_API.post(
        `/giftcard/add-value`,
        {
          amount,
          clientId,
          firstName,
          email,
          deliveryInstructions: null,
          cardIdentifier,
          gratuity: 0,
          isOneTimeTransaction: false,
          lastName,
          locationId: defaultGiftCardLocation,
          orderNote: '',
          paymentMode: null,
          paymentToken,
          paymentType: 'CARD_NOT_PRESENT',
          phone,
          sourceType: 'WEB',
          userId,
        },
        { authenticated: true },
      );
      dispatch(addGiftCardValueSuccess());
      dispatch(fetchPaymentInstrumentsStartAsync());
      return response;
    } catch (error) {
      dispatch(
        addGiftCardValueFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const deletePaymentInstrument = paymentInstrumentId => {
  return async dispatch => {
    try {
      dispatch(deletePaymentInstrumentStart());
      const response = await INC_BASE_API.delete(
        `/paymentinstruments/${paymentInstrumentId}/`,
        { authenticated: true },
      );

      dispatch(deletePaymentInstrumentSuccess());
      dispatch(fetchPaymentInstrumentsStartAsync());
      return response;
    } catch (error) {
      dispatch(
        deletePaymentInstrumentFailure(
          getResponseErrorMessage(error, dispatch),
        ),
      );
      return null;
    }
  };
};

export const buyGiftCard = (amount, paymentInstrumentId) => {
  return async (dispatch, getState) => {
    dispatch(buyGiftCardStart());

    const clientId = selectClientId(getState());
    const email = selectEmail(getState());
    const firstName = selectFirstName(getState());
    const lastName = selectLastName(getState());
    const phone = selectPhoneNumber(getState());
    const userId = selectUserId(getState());
    const paymentInstrument = selectPaymentInstrumentById(paymentInstrumentId)(
      getState(),
    );
    const paymentToken = paymentInstrument
      ? paymentInstrument.paymentToken
      : '';
    const defaultGiftCardLocation = selectGiftCardDefaultLocation(getState());

    try {
      const response = await INC_BASE_API.post(
        `/purchase/ecard`,
        {
          amount,
          clientId,
          firstName,
          email,
          deliveryInstructions: null,
          gratuity: 0,
          isOneTimeTransaction: false,
          lastName,
          locationId: defaultGiftCardLocation,
          orderNote: '',
          paymentMode: null,
          paymentToken,
          paymentType: 'CARD_NOT_PRESENT',
          phone,
          sourceType: 'WEB',
          userId,
        },
        { authenticated: true },
      );
      dispatch(buyGiftCardSuccess());
      dispatch(fetchPaymentInstrumentsStartAsync());
      return response;
    } catch (error) {
      dispatch(buyGiftCardFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const getLoyaltyAccounts = () => {
  return async dispatch => {
    dispatch(getLoyaltyAccountsStart());
    try {
      const response = await INC_BASE_API.get(`/loyaltyaccounts`, {
        authenticated: true,
      });
      dispatch(getLoyaltyAccountsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(
        getLoyaltyAccountsFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const getLoyaltyAccountsHistory = (page = 0) => {
  return async dispatch => {
    dispatch(getLoyaltyAccountsHistoryStart());
    try {
      const response = await INC_BASE_API.get(
        `/loyaltyaccounts/accounthistory`,
        {
          params: {
            count: 4,
            page,
            sortBy: 'transactionDate',
          },
          authenticated: true,
        },
      );
      dispatch(getLoyaltyAccountsHistorySuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(
        getLoyaltyAccountsHistoryFailure(
          getResponseErrorMessage(error, dispatch),
        ),
      );
      return null;
    }
  };
};

export const fetchSurveyFields = surveyId => {
  return async dispatch => {
    dispatch(fetchSurveyFieldsStart());
    try {
      const response = await INC_BASE_API.get(`/surveys/${surveyId}`, {
        params: {
          language: getLanguageKey().toUpperCase(),
        },
        authenticated: true,
      });
      dispatch(fetchSurveyFieldsSuccess());
      return response.data;
    } catch (error) {
      dispatch(
        fetchSurveyFieldFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const submitSurveyAnswers = (surveyId, answers) => {
  return async dispatch => {
    dispatch(submitSurveyAnswersStart());
    try {
      let reformattedAnswers = [];
      Object.entries(answers).forEach(answer => {
        const questionId = answer[0].split('@');
        const choicesIds = answer[1];

        if (questionId[0] !== 'OPEN_ENDED') {
          reformattedAnswers = [
            ...reformattedAnswers,
            {
              questionId: questionId[1],
              choiceIds: Array.isArray(choicesIds) ? choicesIds : [choicesIds],
            },
          ];
        } else {
          reformattedAnswers = [
            ...reformattedAnswers,
            {
              questionId: questionId[1],
              textAnswer: choicesIds,
            },
          ];
        }
      });

      const response = await INC_BASE_API.put(
        `/surveys/${surveyId}/submitanswers`,
        { answers: [...reformattedAnswers] },
        { authenticated: true },
      );

      dispatch(submitSurveyAnswersSuccess());
      return response;
    } catch (error) {
      dispatch(
        submitSurveyAnswersFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const uploadProfilePic = filePath => {
  return async (dispatch, getState) => {
    dispatch(uploadProfilePicStart());
    try {
      const clientId = selectClientId(getState());

      const file = new FormData();
      file.append('file', filePath);

      const res = await INC_BASE_API.post(
        `/clients/${clientId}/media?source=s3`,
        file,
        {
          headers: {
            'content-type': 'multipart/form-data',
            'object-type': 'PROFILE',
          },
          authenticated: true,
        },
      );

      dispatch(uploadProfilePicSuccess());
      await dispatch(setProfilePic(res.data.downloadUrl));
      return res;
    } catch (error) {
      dispatch(
        uploadProfilePicFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const setProfilePic = value => {
  return async dispatch => {
    dispatch(setProfilePicStart());
    try {
      const res = await INC_BASE_API.patch(
        `/useraccounts`,
        [
          {
            attribute: 'userProfileImageUrl',
            value,
          },
        ],
        { authenticated: true },
      );

      dispatch(setProfilePicSuccess());
      await dispatch(fetchUserDataStartAsync());
      return res;
    } catch (error) {
      dispatch(setProfilePicFailure(getResponseErrorMessage(error, dispatch)));
      return null;
    }
  };
};

export const getGiftCardByToken = token => {
  return async dispatch => {
    dispatch(getGiftCardByTokenStart());

    try {
      const response = await INC_BASE_API.get(`/giftcard/tokenlookup/${token}`);
      dispatch(getGiftCardByTokenSuccess());
      return response;
    } catch (error) {
      dispatch(
        getGiftCardByTokenFailure(getResponseErrorMessage(error, dispatch)),
      );
      return null;
    }
  };
};

export const lookupGiftCard = async ({
  cardNumber,
  cardSecurityCode,
  errorOnAssigned = undefined,
}) => {
  try {
    const response = await INC_BASE_API.post(`/giftcard/lookup`, {
      cardNumber,
      securityCode: cardSecurityCode,
      errorOnAssigned,
    });
    return response;
  } catch (error) {
    const errorMessage = getResponseErrorMessage(error);
    toast.error(errorMessage);
    return errorMessage;
  }
};

export const verifyUserAccountWithCode = (userEmail, code, password) => {
  return async dispatch => {
    try {
      await INC_BASE_API.get('verifyUserAccountWithCode', {
        params: {
          userEmail,
          code,
        },
      });

      await dispatch(signInStartAsync({ email: userEmail, password }));
      return { verified: true };
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
      return null;
    }
  };
};

export const resendVerificationMail = userEmail => {
  return async () => {
    try {
      await INC_BASE_API.get(
        `useraccounts/${userEmail}/resendverificationmail`,
      );
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
    }
  };
};
