import * as S from './signup.styles.jsx';
import { Container } from '@material-ui/core';
import { useIsURLSearchParamMatch } from 'hooks/useURLSearchParams';
import { useVerificationMessage } from 'hooks/auth.js';
import SignupForm from './signup-form/signup-form.component.jsx';
import Thankyou from './thank-you/thank-you.component.jsx';

const SignupPage = () => {
  const isHideHeader = useIsURLSearchParamMatch('embed', 'true');
  const { state, setState, verificationMessage, verificationMethod, label } =
    useVerificationMessage();

  const onSuccess = data => {
    const { verified, email, phoneNumber, password } = data;

    if (!verified) {
      setState(state => ({
        ...state,
        email,
        phoneNumber,
        password,
        verified: false,
        signupSuccess: true,
      }));
    } else {
      setState({ signupSuccess: true });
    }
  };

  return (
    <S.CustomBox ishideheader={isHideHeader ? 'true' : undefined}>
      <Container maxWidth="md">
        {!state.signupSuccess ? (
          <SignupForm onSuccess={onSuccess} />
        ) : (
          <Thankyou
            state={state}
            verificationMessage={verificationMessage}
            verificationMethod={verificationMethod}
            label={label}
          />
        )}
      </Container>
    </S.CustomBox>
  );
};

export default SignupPage;
