import React, { useCallback } from 'react';
import * as S from './menu-card-new-item.styles';
import MenuCardNew from '../menu-card-new.component';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import { useOpenMenuItemDialogPath } from 'hooks/dialog/menu-dialog.hooks';
import { makeSelectIsItemOutOfStock } from 'redux/catalogs/catalogs.selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MenuCardNewItemContent from './menu-card-new-item-content/menu-card-new-item-content.component';
import { useMediaQuery, useTheme } from '@material-ui/core';
import MenuCardNewImage from '../menu-card-new-image/menu-card-new-image.component';

const MenuCardNewItem = ({ item, path, ...rest }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { smallImage, title, shortDescription } = useDisplayInfo(
    item.displayInfo,
  );

  const openModal = useOpenMenuItemDialogPath(path);

  // eslint-disable-next-line
  const selectIsItemOutOfStock = useCallback(
    makeSelectIsItemOutOfStock(item.itemId),
  );

  const isItemOutOfStock = useSelector(selectIsItemOutOfStock);
  const { t } = useTranslation();

  const handlePressedOpenModal = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      openModal();
    }
  };

  return (
    <MenuCardNew
      onClick={openModal}
      onKeyPress={handlePressedOpenModal}
      disabled={isItemOutOfStock}
      {...rest}
    >
      {isItemOutOfStock && (
        <S.NotAvailableBadge>
          {t('catalog.itemNotAvailable')}
        </S.NotAvailableBadge>
      )}
      {matches ? (
        <S.Container>
          <MenuCardNewImage smallImage={smallImage} alt={title} />
          <MenuCardNewItemContent
            title={title}
            description={shortDescription}
            price={item.price}
          />
        </S.Container>
      ) : (
        <>
          <MenuCardNewImage smallImage={smallImage} />
          <MenuCardNewItemContent
            title={title}
            description={shortDescription}
            price={item.price}
          />
        </>
      )}
    </MenuCardNew>
  );
};

export default MenuCardNewItem;
