import { DateTime } from 'luxon';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import * as S from './message-date.styles';

const MessageDate = ({ date }) => {
  return (
    <S.Date tabIndex="0">
      <ScheduleIcon fontSize="inherit" />
      &nbsp;
      {DateTime.fromISO(date, { zone: 'utc' }).toRelative({
        padding: -90000,
        round: true,
      })}
    </S.Date>
  );
};

export default MessageDate;
