import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import * as S from '../drawer/drawer.styles';
import TogglerButton from '../toggler-button/toggler-button.component';
import { useTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';
import { selectItemsCount } from '../../redux/cart/cart.selectors';
import { useSelector } from 'react-redux';
import HeaderDrawer from 'components/header-drawer/header-drawer.component';

const Drawer = ({
  toggleText,
  children,
  isLight,
  handleToggleDrawer,
  isDrawerOpen = false,
  toggleTestId,
}) => {
  const { t } = useTranslation();
  const totalItemsCount = useSelector(selectItemsCount);

  return (
    <>
      <TogglerButton
        handleToggle={handleToggleDrawer(true)}
        toggleTestId={toggleTestId}
        aria-label={toggleText}
      >
        {toggleText === t('drawer.login.toggle') ? (
          <AccountCircleIcon />
        ) : (
          <S.CartTogglerWrapper>
            <S.CustomBadge
              overlap="rectangular"
              badgeContent={totalItemsCount}
              data-testid="Drawer.CustomBadge"
            >
              <ShoppingCartIcon />
            </S.CustomBadge>
          </S.CartTogglerWrapper>
        )}
        <Hidden only="xs">{toggleText}</Hidden>
      </TogglerButton>

      <HeaderDrawer
        open={isDrawerOpen}
        onOpen={handleToggleDrawer(true)}
        onClose={handleToggleDrawer(false)}
        isLight={isLight}
      >
        {children}
      </HeaderDrawer>
    </>
  );
};

export default Drawer;
