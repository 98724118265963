import React from 'react';
import * as S from './store-short-description.styles';

const StoreShortDescription = ({ shortDescription }) => {
  return (
    <S.Description tabIndex="0" aria-label={shortDescription}>
      {shortDescription}
    </S.Description>
  );
};

export default StoreShortDescription;
