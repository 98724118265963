import { InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import BackAction from 'components/back-action/back-action.component';
import CustomInput from 'components/custom-input/custom-input.component';
import ResendCode from './resend-code/resend-code.component';
import { useTranslation } from 'react-i18next';
import IncButton from 'components/inc-button/inc-button.component';
import Form from 'components/form/form.component';
import { useForm } from 'react-hook-form';
import { verifyUserAccountWithCode } from 'redux/user/user.actions';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import FormError from 'components/form-error/form-error.component';

const VerifyCode = ({ send, label, state: { email, previous, password } }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    watch,
    formState: { isSubmitting, errors },
  } = useForm();
  const dispatch = useDispatch();

  const embedSignUp = useRouteMatch(['/signup']);
  const code = watch('code', '');
  const history = useHistory();

  const { ref, ...registerProps } = register('code', {
    validate: value => (/^[0-9]+$/.test(value) ? true : 'Error'),
  });

  const onSubmit = async () => {
    const response = await dispatch(
      verifyUserAccountWithCode(email, code, password),
    );

    if (!!embedSignUp && !!response?.verified) history.push('/');
  };

  return (
    <>
      {!!send && <BackAction handleBack={() => send(previous)} />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput>
          <InputLabel htmlFor="code">{label}</InputLabel>
          <TextField id="code" inputRef={ref} {...registerProps} fullWidth />
          {errors.code && (
            <FormError errorMessage={t('verifyCode.genericError')} />
          )}
        </CustomInput>

        <IncButton type="submit" disabled={isSubmitting} fullWidth>
          {t('verifyCode.proceed')}
        </IncButton>
      </Form>
      <ResendCode email={email} />
    </>
  );
};

export default VerifyCode;
