import { Typography } from '@material-ui/core';
import * as S from './reset-password-success-message.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsWOV2Enabled } from 'redux/config/config.selectors';
import { useLoginLink } from 'hooks/useLoginLink';

const ResetPasswordSuccessMessage = () => {
  const { t } = useTranslation();
  const isWOV2Enabled = useSelector(selectIsWOV2Enabled);

  const handleLoginLinkClick = useLoginLink();

  if (!isWOV2Enabled) {
    return (
      <Typography
        component="h2"
        tabIndex="0"
        aria-label={t('resetPassword.successMessage.complete')}
      >
        {t('resetPassword.successMessage.complete')}
      </Typography>
    );
  }

  return (
    <Typography component="h2">
      {t('resetPassword.successMessage.anons.1')}{' '}
      <S.LinkButton
        component="button"
        variant="body1"
        onClick={handleLoginLinkClick}
        data-testid="ResetPasswordSuccessMessage.LinkButton"
        aria-label={t('resetPassword.successMessage.anons.ariaLabel')}
      >
        {t('resetPassword.successMessage.anons.login')}
      </S.LinkButton>{' '}
      {t('resetPassword.successMessage.anons.2')}
    </Typography>
  );
};

export default ResetPasswordSuccessMessage;
