import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomDialog from '../custom-dialog/custom-dialog.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import IncButton from 'components/inc-button/inc-button.component';
import { Box } from '@material-ui/core';
import { selectOrderId } from 'redux/cart/cart.selectors';
import { clearCart, removeOrderItemStartAsync } from 'redux/cart/cart.actions';
import { useForm } from 'react-hook-form';
import HiddenMenuErrorContext from './hidden-menu-error-dialog.context';

const HiddenMenuErrorDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const { setHiddenMenuErrorState, hiddenMenuErrorState } = useContext(
    HiddenMenuErrorContext,
  );

  const {
    open,
    invalidItemDetails = [],
    callback,
    closeReorderDialog = () => {},
    dayTimeSkip = false,
  } = hiddenMenuErrorState;

  const orderId = useSelector(selectOrderId);

  const handleClose = useCallback(
    (params = {}) => {
      setHiddenMenuErrorState(hiddenMenuErrorState => ({
        ...hiddenMenuErrorState,
        open: false,
      }));

      if (dayTimeSkip && !params.handleActionClicked) {
        closeReorderDialog();
        dispatch(clearCart());
      }
    },
    [setHiddenMenuErrorState, dayTimeSkip, closeReorderDialog, dispatch],
  );

  const handleAction = useCallback(async () => {
    setHiddenMenuErrorState(hiddenMenuErrorState => ({
      ...hiddenMenuErrorState,
      handleActionClicked: true,
    }));

    for (const invalidItem of invalidItemDetails) {
      await dispatch(
        removeOrderItemStartAsync(
          orderId,
          invalidItem.orderItemId,
          invalidItem.itemId,
        ),
      );
    }

    await callback();
    handleClose({ handleActionClicked: true });
  }, [
    callback,
    dispatch,
    handleClose,
    invalidItemDetails,
    orderId,
    setHiddenMenuErrorState,
  ]);

  return (
    <CustomDialog
      open={!!open}
      close={handleClose}
      title={t('hiddenMenuErrorDialog.title')}
      testId="HiddenMenuErrorDialog.CustomDialog"
    >
      <form onSubmit={handleSubmit(handleAction)}>
        <Typography>{t('hiddenMenuErrorDialog.message')}</Typography>
        <Box mt={2} mb={2}>
          {invalidItemDetails.map(itemDetails => (
            <Typography key={itemDetails.itemId}>
              {itemDetails.displayInfoTitle}
            </Typography>
          ))}
        </Box>

        <Grid container spacing={1} justify="center">
          <Grid item xs={6}>
            <IncButtonDark
              onClick={handleClose}
              fullWidth
              data-testid="HiddenMenuErrorDialog.CustomButton.Cancel"
            >
              {t('hiddenMenuErrorDialog.cancelAction')}
            </IncButtonDark>
          </Grid>
          <Grid item xs={6}>
            <IncButton
              type="submit"
              disabled={isSubmitting}
              fullWidth
              data-testid="HiddenMenuErrorDialog.CustomButton.RemoveItems"
            >
              {t('hiddenMenuErrorDialog.submitAction')}
            </IncButton>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default HiddenMenuErrorDialog;
