import React from 'react';

import * as S from './form-error.styles';

const FormError = ({
  errorMessage,
  'data-testid': dataTestId = 'FormError',
}) => (
  <S.ErrorMessage
    tabIndex="0"
    aria-label={`${errorMessage}`}
    className="error-display"
    data-testid={dataTestId}
    role="alert"
  >
    {errorMessage}
  </S.ErrorMessage>
);

export default FormError;
