import React, { useMemo } from 'react';
import * as S from './menu-card-new.styles';

const MenuCardNew = ({
  onClick,
  onKeyPress,
  children,
  testId = 'MenuCard',
  disabled,
  ...rest
}) => {
  const handleClick = useMemo(
    () => (!disabled ? onClick : () => {}),
    [disabled, onClick],
  );
  const handleKeyPress = useMemo(
    () => (!disabled ? onKeyPress : () => {}),
    [disabled, onKeyPress],
  );

  return (
    <S.GridItem
      item
      xs={12}
      sm={6}
      md={6}
      lg={4}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      {...rest}
    >
      <S.Container container data-testid={testId} disabled={disabled}>
        {children}
      </S.Container>
    </S.GridItem>
  );
};

export default MenuCardNew;
