import { CardActionArea } from '@material-ui/core';
import React from 'react';
import * as S from './payment-option.styles';

const PaymentOption = React.forwardRef(
  (
    { icon, avatar, title, type, onClick, selected = false, tabIndex, ...rest },
    ref,
  ) => {
    const handleClick = e => onClick(type, e);

    return (
      <S.PaymentOptionCard
        variant="outlined"
        {...rest}
        selected={selected}
        data-testid="PaymentOption"
      >
        <CardActionArea
          onClick={handleClick}
          focusRipple
          component="div"
          role="none"
          ref={ref}
        >
          <S.PaymentOptionCardHeader
            avatar={avatar ? avatar : <S.IconAvatar>{icon}</S.IconAvatar>}
            title={title}
          />
        </CardActionArea>
      </S.PaymentOptionCard>
    );
  },
);
export default PaymentOption;
