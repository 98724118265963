import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const CustomIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.accentColor};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 6px;
    svg,
    .MuiAvatar-circular {
      width: 30px;
      height: 30px;
    }
  }
`;
