import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import AccountContainer from '../../components/account-container/account-container.component';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs.component';
import CustomPagination from '../../components/custom-pagination/custom-pagination.component';
import Spinner from '../../components/spinner/spinner.component';
import { copyOrder } from '../../redux/cart/cart.actions';
import { selectIsFetching } from '../../redux/cart/cart.selectors';
import { getOrderHistory } from '../../redux/user/user.actions';
import AccountOrderHistoryItem from './account-order-history-item/account-order-history-item.component';
import OrderItemDetail from './account-order-history-item/order-item-detail/order-item-detail.component';
import * as S from './account-order-history.styles';
import AccountOrderHistoryDialog from './account-order-history-dialog/account-order-history-dialog.component';

const AccountOrderHistoryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [detailItem, setDetailItem] = useState(null);
  const [reorderOpen, setReorderOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [orderHistoryData, setOrderHistroyData] = useState({});

  useEffect(() => {
    dispatch(getOrderHistory()).then(res => {
      handleResponse(res);
    });
  }, [dispatch]);

  const handlePaginationChange = (_, value) => {
    dispatch(getOrderHistory(value - 1)).then(res => {
      handleResponse(res);
      scroll.scrollToTop({
        smooth: true,
      });
    });
  };

  const handleReorder = orderId => {
    dispatch(copyOrder(orderId, setSelectedStore)).then(data => {
      if (data) {
        setOrderHistroyData(data);
        setReorderOpen(true);
      }
    });
  };

  const handleResponse = res => {
    if (res) {
      const { paging, orders } = res;
      setTotalPages(paging.totalPages);
      setCurrentPage(paging.currentPage);
      setOrders(orders);
    }
  };

  return (
    <>
      <Breadcrumbs maxWidth="md">
        <Link to="/" component={RouterLink}>
          {t('breadcrumbs.home')}
        </Link>
        <Typography
          aria-label={`Currently located at ${t('breadcrumbs.pastOrders')}`}
          tabIndex="0"
        >
          {t('breadcrumbs.pastOrders')}
        </Typography>
      </Breadcrumbs>
      <AccountContainer testId="OrderHistory.AccountContainer">
        {isFetching && <Spinner />}
        <h1 tabIndex="0">{t('accountPastOrders.title')}</h1>
        {orders.length > 0 ? (
          !detailItem ? (
            orders.map(order => (
              <AccountOrderHistoryItem
                key={order.orderId}
                order={order}
                handleReorder={handleReorder}
                handleDetailItem={() => setDetailItem(order)}
              />
            ))
          ) : (
            <OrderItemDetail
              handleBackAction={() => setDetailItem(null)}
              handleReorder={handleReorder}
              order={detailItem}
            />
          )
        ) : (
          <S.EmptyText tabIndex="0">
            {t('accountPastOrders.emptyText')}
          </S.EmptyText>
        )}

        {totalPages > 1 && !detailItem && (
          <CustomPagination
            page={currentPage + 1}
            count={totalPages}
            onChange={handlePaginationChange}
            tabIndex="0"
            data-testid="AccountOrderHistoryPage.Pagination"
          />
        )}

        {reorderOpen && (
          <AccountOrderHistoryDialog
            setReorderOpen={setReorderOpen}
            selectedStore={selectedStore}
            orderHistoryData={orderHistoryData}
          />
        )}
      </AccountContainer>
    </>
  );
};

export default AccountOrderHistoryPage;
