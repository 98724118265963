import { Box, Grid } from '@material-ui/core';
import { useMachine } from '@xstate/react';
import IncButton from 'components/inc-button/inc-button.component';
import {
  IncButtonDark,
  IncButtonLight,
} from 'components/inc-button/inc-button.styles';
import VerifyCode from 'components/verify-code/verify-code.component';
import { useVerificationMessage } from 'hooks/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_CODE, SMS_CODE } from 'redux/config/config.constants';
import { selectSocialSignInEnabled } from 'redux/config/config.selectors';
import { requestPwReset } from 'redux/user/user.actions';
import BackAction from '../back-action/back-action.component';
import ForgotPassword from '../forgot-password/forgot-password.component';
import GuestFields from '../guest-fields/guest-fields.component';
import RegisterFields from '../register-fields/register-fields.component';
import SignInFields from '../sign-in-fields/sign-in-fields.component';
import { accountMachine } from './create-account.machine';
import * as S from './create-account.styles';

const CreateAccount = ({
  setIsLight,
  handleDrawerTitle,
  onComplete = () => {},
  guest = false,
  context = { drawer: true },
  initial,
}) => {
  const dispatch = useDispatch();
  const socialLogin = useSelector(selectSocialSignInEnabled);
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [refresh, setRefresh] = useState(false);

  const [machineState, send] = useMachine(accountMachine(initial), {
    actions: {
      setLight: () => setIsLight(true),
      setDark: () => setIsLight(false),
      refresh: () => setRefresh(state => !state),
      setTitle: (_context, event, actionMeta) => {
        if (event.type === 'REGISTER' && !!event.registerData) {
          handleDrawerTitle(t('drawer.login.registerSocial'));
        } else {
          handleDrawerTitle(
            actionMeta.state.meta[`account.${actionMeta.state.value}`].title,
          );
        }
      },
    },
    context,
  });

  const { state, setState, verificationMessage, verificationMethod, label } =
    useVerificationMessage();

  const onSuccess = data => {
    const { verified, email, phoneNumber, password, authenticationProvider } =
      data;
    if (!verified && [SMS_CODE, EMAIL_CODE].includes(verificationMethod)) {
      setState(state => ({
        ...state,
        email,
        phoneNumber,
        password,
        previous: 'REGISTER',
      }));
      send('CODE_VERIFICATION');
      return;
    }

    if (!data.verified)
      setState(state => ({ ...state, verified: false, email }));

    send(!!authenticationProvider ? 'INITIAL' : 'LOGIN');
  };

  const onSubmitPassword = ({ email }) => dispatch(requestPwReset(email));

  // eslint-disable-next-line default-case
  switch (machineState.value) {
    case 'login':
      return (
        <>
          {(!socialLogin || !context.drawer) && (
            <BackAction handleBack={() => send('BACK')} tabIndex="0" />
          )}
          {!state.verified && (
            <S.VerificationMessage
              tabIndex="0"
              aria-label={verificationMessage}
              data-testid="CreateAccountStep.VericationMessage"
            >
              {verificationMessage}
            </S.VerificationMessage>
          )}
          <SignInFields
            send={send}
            setState={setState}
            state={state}
            verificationMessage={verificationMessage}
            verificationMethod={verificationMethod}
          />
          <S.HorizontalLine />
          <Box display="flex" alignItems="center" justifyContent="center">
            {t('register.label.dontHaveAccount')}{' '}
            <S.StyledActionLink
              onClick={() => send('REGISTER')}
              data-testid="SignInFields.Link.CreateAccount"
            >
              {t('register.label.createAccount')}
            </S.StyledActionLink>
          </Box>
        </>
      );

    case 'register':
      return (
        <>
          <BackAction handleBack={() => send('BACK')} tabIndex="0" />
          <RegisterFields
            onSuccess={onSuccess}
            registerData={machineState.event.registerData}
            send={send}
          />
        </>
      );

    case 'guest':
      return (
        <>
          <BackAction handleBack={() => send('BACK')} tabIndex="0" />
          <GuestFields onSuccess={onComplete} />
        </>
      );

    case 'forgot_password':
      return (
        <>
          <BackAction handleBack={() => send('BACK')} tabIndex="0" />
          <ForgotPassword send={send} onSubmit={onSubmitPassword} />
        </>
      );

    case 'initial':
      if (context.drawer && socialLogin) send('LOGIN');
      return (
        <Grid container spacing={!!context?.drawer ? 0 : 1}>
          <>
            {!!context?.drawer ? (
              <Grid item xs={12}>
                <S.DrawerSignupButton
                  onClick={() => send('REGISTER')}
                  data-testid="CreateAccountStep.CustomButton.Register"
                  fullWidth
                >
                  {t('authOptions.label.createAccount')}
                </S.DrawerSignupButton>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <IncButton
                  onClick={() => send('REGISTER')}
                  data-testid="CreateAccountStep.CustomButton.Register"
                  fullWidth
                >
                  {t('authOptions.label.createAccount')}
                </IncButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <IncButtonLight
                onClick={() => send('LOGIN')}
                fullWidth
                data-testid="CreateAccountStep.CustomButton.Login"
              >
                {t('authOptions.label.login')}
              </IncButtonLight>
            </Grid>
          </>

          {guest && (
            <Grid item xs={12}>
              <IncButtonDark
                onClick={() => send('GUEST')}
                fullWidth
                data-testid="CreateAccountStep.CustomButton.GuestCheckout"
              >
                {t('authOptions.label.continueAsGuest')}
              </IncButtonDark>
            </Grid>
          )}
        </Grid>
      );

    case 'code_verification':
      return <VerifyCode send={send} label={label} state={state} />;
  }
};

export default CreateAccount;
