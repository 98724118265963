import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import CustomInput from 'components/custom-input/custom-input.component';
import MessagesDialog from 'components/messages-dialog/messages-dialog.component';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityBasedContent } from 'redux/user/user.actions';
import {
  selectActivityBasedContentOffers,
  selectAllActivityBasedContent,
} from 'redux/user/user.selectors';
import IncButton from 'components/inc-button/inc-button.component';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import { FormProvider, useForm } from 'react-hook-form';

const PromoCode = ({ open, handleClose, checkoutPromoCode, onRetrieve }) => {
  const { t } = useTranslation();
  const [offerOpen, setOfferOpen] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm();
  const { register, handleSubmit } = methods;
  const allActivityBasedContent = useSelector(selectAllActivityBasedContent);
  const activityBasedContentOffers = useSelector(
    selectActivityBasedContentOffers,
  );

  const messages = checkoutPromoCode
    ? activityBasedContentOffers
    : allActivityBasedContent;

  const handleApplyPromo = async data => {
    const result = await dispatch(
      getActivityBasedContent(null, null, data.promoCode),
    );
    onRetrieve && onRetrieve(result);
    setOfferOpen(true);

    handleClose();
  };

  return (
    <>
      <CustomDialog
        title="Enter Promo Code"
        testId="PromoCode.CustomDialog"
        open={open}
        close={handleClose}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleApplyPromo)}>
            <CustomInput>
              <InputLabel htmlFor="promoCode">
                {t('payment.promoCode.label')}
              </InputLabel>
              <TrimTextField
                id="promoCode"
                {...register('promoCode', { required: true })}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocalOfferIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </CustomInput>
            <Box mt={3}>
              <IncButton
                data-testid="PromoCode.CustomButton"
                type="submit"
                fullWidth
              >
                {t('payment.promoCode.submitAction')}
              </IncButton>
            </Box>
          </form>
        </FormProvider>
      </CustomDialog>
      <MessagesDialog
        open={offerOpen}
        setOpen={setOfferOpen}
        messages={messages}
        testId="PromoCode.MessagesDialog"
        checkoutPromoCode={checkoutPromoCode}
      />
    </>
  );
};

export default PromoCode;
