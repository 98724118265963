import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addExistingGiftCard,
  fetchPaymentInstrumentsStartAsync,
} from 'redux/user/user.actions';

export const useViewGiftCard = ({ cardIdentifier, securityCode }) => {
  const dispatch = useDispatch();
  const [isAddToWalletLoading, setIsAddToWalletLoading] = useState(false);
  const [isGetCardLoading, setIsGetCardLoading] = useState(true);
  const [inWallet, setInWallet] = useState(false);

  const addToWallet = async () => {
    setIsAddToWalletLoading(true);
    const data = await dispatch(
      addExistingGiftCard({
        paymentToken: cardIdentifier,
        cardSecurityCode: securityCode,
      }),
    );
    setIsAddToWalletLoading(false);
    setInWallet(!!data);
  };

  useEffect(() => {
    const getCard = async () => {
      setIsGetCardLoading(true);
      const response = await dispatch(fetchPaymentInstrumentsStartAsync());
      setIsGetCardLoading(false);
      setInWallet(
        response?.data?.paymentInstruments?.some(
          paymentInstrument =>
            paymentInstrument.paymentToken === cardIdentifier,
        ),
      );
    };
    cardIdentifier && getCard();
  }, [cardIdentifier, dispatch]);

  const isLoading = useMemo(
    () => isAddToWalletLoading || isGetCardLoading,
    [isAddToWalletLoading, isGetCardLoading],
  );

  return {
    inWallet,
    addToWallet,
    isLoading,
  };
};
