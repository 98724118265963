import { Box, Link } from '@material-ui/core';
import React from 'react';
import { Span, Text } from '../toc-and-privacy.styles';
import { useTranslation } from 'react-i18next';

const TocAndPrivacySettingsPage = ({ handleTocOpen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text variant="body2">
        {t('register.privacyPolicy.settings.label')}{' '}
        <Link
          href="https://incentivio.com/privacy"
          target="_blank"
          data-testid="TocAndPrivacy.PrivacyLink"
        >
          <Span>{t('register.privacyPolicy.settings.linkText')}</Span>
        </Link>
      </Text>
      <Box mt={1} />
      <Text variant="body2">
        {t('register.termsAndConditions.settings.label')}{' '}
        <Link
          onMouseDown={handleTocOpen}
          data-testid="TocAndPrivacy.TermsAndConditionsLink"
        >
          <Span>{t('register.termsAndConditions.settings.linkText')}</Span>
        </Link>
      </Text>
    </>
  );
};

export default TocAndPrivacySettingsPage;
