import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormError from 'components/form-error/form-error.component';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getMonths } from '../birth-month-day-select.utils';

const DaySelect = ({ required }) => {
  const { t } = useTranslation();
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const monthId = useWatch({ name: 'birthMonth' });

  const isMonthNotValid = monthId === '';

  useEffect(() => {
    if (isMonthNotValid) setValue('birthDay', '');
  }, [isMonthNotValid, monthId, setValue]);

  return (
    <Controller
      name="birthDay"
      rules={{ required: required }}
      render={({ field: { onChange, ...rest } }) => (
        <FormControl fullWidth disabled={isMonthNotValid}>
          <InputLabel id="day-select-label">
            {`${t('forms.dateOfBirth.day.label')}${required ? '*' : ''}`}
          </InputLabel>
          <Select
            labelId="day-select-label"
            id="day-select"
            data-testid="DaySelect.Select"
            onChange={e => onChange(e.target.value)}
            MenuProps={{
              MenuListProps: {
                'data-testid': 'BirthMonthDaySelect.Select.Day.Menu',
              },
            }}
            inputProps={{
              id: 'BirthMonthDaySelect',
              'data-testid': 'DaySelect.Input',
            }}
            {...rest}
          >
            {Array.from(
              {
                length:
                  getMonths().find(month => month.id === monthId)?.days ?? 0,
              },
              (_v, i) => {
                const value = (i + 1).toString().padStart(2, '0');
                return (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                );
              },
            )}
          </Select>
          {errors.birthDay && (
            <FormError
              data-testid="DaySelect.FormError"
              errorMessage={t('forms.errors.requiredField')}
            />
          )}
        </FormControl>
      )}
    />
  );
};

export default DaySelect;
