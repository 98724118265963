import { Box, Typography } from '@material-ui/core';
import { CustomSpinner } from 'components/spinner/spinner.styles';
import { useTranslation } from 'react-i18next';
import { useOrderStatus } from './checkout-status.hooks';

const CheckoutStatus = () => {
  const { t } = useTranslation();
  useOrderStatus();

  return (
    <Box
      display="flex"
      width="100%"
      flex="1"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      data-testid="CheckoutStatus.Box"
    >
      <>
        <Box mb={1}>
          <CustomSpinner />
        </Box>
        <Typography>{t('checkout.statusPage.pleaseWait1')}</Typography>
        <Typography variant="body2">
          {t('checkout.statusPage.pleaseWait2')}
        </Typography>
      </>
    </Box>
  );
};
export default CheckoutStatus;
