import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const VerifySmsErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Typography component="h2" tabIndex="0" aria-label={t('verifySms.error')}>
      {t('verifySms.error')}
    </Typography>
  );
};

export default VerifySmsErrorMessage;
