import React from 'react';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import Spinner from 'components/spinner/spinner.component';
import { useDispatch, useSelector } from 'react-redux';
import { addExistingGiftCard } from 'redux/user/user.actions';
import { selectIsCardLoading } from 'redux/user/user.selectors';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Form from 'components/form/form.component';
import IncButton from 'components/inc-button/inc-button.component';
import GiftCardSecurityCodeInput from 'components/gift-card-security-code-input/gift-card-security-code-input.component';
import GiftCardNumberInput from 'components/gift-card-number-input/gift-card-number-input.component';

const AddExistingGiftCard = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsCardLoading);

  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { handleSubmit } = formMethods;

  const onSubmit = data => {
    dispatch(addExistingGiftCard(data)).then(res => !!res && handleClose());
  };

  return (
    <CustomDialog
      title={t('addPurchaseGiftCard.addExistingGiftCard.title')}
      open={open}
      close={handleClose}
      testId="AddExistingGiftCard.CustomDialog"
    >
      {isLoading && <Spinner />}
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <GiftCardNumberInput
            name="paymentToken"
            id="existingCard"
            label={t(
              'addPurchaseGiftCard.addExistingGiftCard.enterCurrentCardNumber',
            )}
          />

          <GiftCardSecurityCodeInput />

          <IncButton
            type="submit"
            data-testid="AddExistingGiftCard.CustomButton"
            fullWidth
          >
            {t('addPurchaseGiftCard.addExistingGiftCard.submitAction')}
          </IncButton>
        </Form>
      </FormProvider>
    </CustomDialog>
  );
};

export default AddExistingGiftCard;
