import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { CheckoutH1 } from '../checkout.styles';

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StoreName = styled(CheckoutH1)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 1px;
`;

export const Description = styled(Box)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

/* export const Header = styled(Typography)`
  font-size: 1.125rem;
  font-weight: bold;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 0.875rem;
  }
`; */
