import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const Container = styled(Box)`
  display: flex;
  flex: 1;
  justify-self: flex-end;
  justify-content: flex-end;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    order: 3;
  }
`;
