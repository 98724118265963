import { Grid } from '@material-ui/core';
import { PaymentOptionType } from 'components/payment/payment.constants';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ApplePayPaymentOption from './payment-option/apple-pay-payment-option.component';
import CardPaymentOption from './payment-option/card-payment-option.component';
import GiftCardPaymentOption from './payment-option/gift-card-payment-option.component';
import GooglePayPaymentOption from './payment-option/google-pay-payment-option.component';
import GuestCardPaymentOption from './payment-option/guest-card-payment-option.component';
import PayLaterPaymentOption from './payment-option/pay-later-payment-option.component';
import PaymentOptionWrapper from './payment-option-wrapper/payment-option-wrapper.component';
import GuestGiftCardPaymentOption from './payment-option/guest-gift-card-payment-option.component';

const paymentOptionTypeMap = {
  [PaymentOptionType.CARD]: CardPaymentOption,
  [PaymentOptionType.GUEST_CARD]: GuestCardPaymentOption,
  [PaymentOptionType.GOOGLE_PAY]: GooglePayPaymentOption,
  [PaymentOptionType.APPLE_PAY]: ApplePayPaymentOption,
  [PaymentOptionType.GIFT_CARD]: GiftCardPaymentOption,
  [PaymentOptionType.GUEST_GIFT_CARD]: GuestGiftCardPaymentOption,
  [PaymentOptionType.PAY_LATER]: PayLaterPaymentOption,
};

const PaymentOptions = ({ options = {}, onChange, value = '' }) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    type => {
      onChange(type);
    },
    [onChange],
  );

  const enabledOptions = useMemo(
    () =>
      Object.entries(paymentOptionTypeMap).filter(
        ([mapOption]) => !!options[mapOption],
      ),
    [options],
  );

  useEffect(() => {
    if (!enabledOptions.some(enabledOption => enabledOption[0] === value)) {
      handleChange(enabledOptions[0][0]);
    }
  }, [enabledOptions, handleChange, value]);

  return (
    <Grid
      container
      spacing={1}
      data-testid="PaymentOptions"
      aria-label={t('paymentSelector.paymentOptions')}
      role="tablist"
    >
      {enabledOptions.map(([type, Component]) => (
        <PaymentOptionWrapper
          type={type}
          as={Component}
          value={value}
          handleChange={handleChange}
          gridSm={enabledOptions.length === 1 ? 4 : true}
          key={type}
        />
      ))}
    </Grid>
  );
};
export default PaymentOptions;
