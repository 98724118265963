import { Grid } from '@material-ui/core';
import { DetailImage } from '../menu-modal.styles';

const NutritionalInfo = ({ extendedAttributes }) => {
  if (extendedAttributes && Object.entries(extendedAttributes).length > 0)
    return (
      <Grid container justify="center">
        {Object.entries(extendedAttributes).map((image, index) => {
          if (image[0] === 'nutritional_info') return null;
          return (
            <Grid tabIndex="-1" key={`${image[0]}-${index}`} item xs>
              <DetailImage src={image[1]} alt="" />
            </Grid>
          );
        })}
      </Grid>
    );

  return null;
};

export default NutritionalInfo;
