import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import React, { useContext } from 'react';
import * as S from './menu-modal.styles';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  selectCatalogsIsFetching,
  selectChecksum,
} from 'redux/catalogs/catalogs.selectors';
import Spinner from 'components/spinner/spinner.component';
import MenuDialogContext from 'hooks/dialog/menu-dialog.context';

const NotFoundModal = ({ open }) => {
  const { t } = useTranslation();
  const menuLoading = useSelector(selectCatalogsIsFetching);
  const checksum = useSelector(selectChecksum);
  const { handleClose } = useContext(MenuDialogContext);

  return (
    <CustomDialog
      open={open}
      close={handleClose}
      testId="NotFoundModal.CustomDialog"
      title={
        menuLoading || checksum === '1' ? '' : t('catalog.notFoundModal.title')
      }
    >
      {menuLoading || checksum === '1' ? (
        <Box p={2}>
          <Spinner />
        </Box>
      ) : (
        <>
          <S.Close
            data-testid="CustomDialog.Close"
            onClick={handleClose}
            tabIndex="0"
            aria-label={t('dialogs.close.ariaLabel')}
          >
            <CloseIcon />
          </S.Close>
          <Typography variant="body1">
            {t('catalog.notFoundModal.body')}
          </Typography>
        </>
      )}
    </CustomDialog>
  );
};

export default NotFoundModal;
