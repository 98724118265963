import React, { useState } from 'react';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import { Input, InputLabel } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PasswordVisibility from '../password-visibility/password-visibility.component';

const Password = ({ label }) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const { t } = useTranslation();
  const pattern = new RegExp(
    /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[<>{}"\\/|;:.,~!?@#$%^=&*\]()[_+-]).{8,}$/,
  );

  return (
    <CustomInput>
      <InputLabel htmlFor="password">
        {label ?? t('forms.password.label')}*
      </InputLabel>
      <Input
        fullWidth
        id="password"
        type={showPassword ? 'text' : 'password'}
        error={!!errors.password}
        {...register('password', {
          required: t('forms.password.errors.required'),
          validate: value =>
            pattern.test(value) ? true : t('forms.password.errors.characters'),
        })}
        placeholder={t('forms.password.placeholder')}
        endAdornment={
          <PasswordVisibility
            showPassword={showPassword}
            setShowPassword={setShowPassword}
            testId="PasswordVisibility.IconButton"
          />
        }
      />
      {errors.password && (
        <FormError
          errorMessage={errors.password.message}
          data-testid="Password.FormError"
        />
      )}
    </CustomInput>
  );
};

export default Password;
