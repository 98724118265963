import { Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';

const minHeight = 1;
export const TipTabs = styled(Tabs)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.bgColor};
  min-height: ${minHeight}px;

  .MuiTabs-indicator {
    display: none;
  }
`;

export const TipTab = styled(Tab)`
  &.MuiTab-root {
    text-transform: capitalize;
    min-width: 1px;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 1px 0;
    min-height: ${minHeight}px;

    flex: 1 0 ${({ flexbasis }) => flexbasis ?? 0};

    color: ${({ theme }) => theme.palette.text.primary};
    opacity: 1;

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.main};
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }

    &.Mui-disabled {
      opacity: 0.4;
    }
  }
`;
