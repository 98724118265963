import React, { useState } from 'react';
import GiftCardView from '../giftcard-view/gift-card-view.component';
import { useTranslation } from 'react-i18next';
import * as S from './card-item-giftcard-content.styles';
import GiftCardAddValue from '../giftcard-add-value/giftcard-add-value.component';
import {
  selectGiftCardHeader,
  selectIsHideQrCode,
} from 'redux/config/config.selectors';
import { useSelector } from 'react-redux';

const CardItemGiftCardContent = ({
  paymentInstrumentId,
  handleClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const [addValue, setAddValue] = useState(false);
  const hideQrCode = useSelector(selectIsHideQrCode);
  const giftCardHeader = useSelector(selectGiftCardHeader);

  if (addValue) {
    return (
      <GiftCardAddValue
        paymentInstrumentId={paymentInstrumentId}
        handleClose={handleClose}
      />
    );
  }

  return (
    <>
      {hideQrCode ? (
        <S.YourNumber>
          <span tabIndex="0">{giftCardHeader}</span>
        </S.YourNumber>
      ) : (
        <S.ModalDescription tabIndex="0">
          {t('cardItem.modalDescription')}
        </S.ModalDescription>
      )}

      <GiftCardView setAddValue={setAddValue} {...rest} />
    </>
  );
};

export default CardItemGiftCardContent;
