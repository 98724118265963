import { Grid, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MessagesDialog from 'components/messages-dialog/messages-dialog.component';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { applyDiscount } from 'redux/cart/cart.actions';
import { useMessages } from 'redux/data/messages/messages.hooks';
import { useOffers } from 'redux/data/offers/offers.hooks';
import CustomDialog from '../../../components/custom-dialog/custom-dialog.component';
import Spinner from '../../../components/spinner/spinner.component';
import {
  deleteActivityBasedContent,
  getDistributedMessageData,
  getDistributedOfferData,
} from '../../../redux/user/user.actions';
import {
  selectAllActivityBasedContent,
  selectIsInboxItemLoading,
  selectIsLoggedIn,
} from '../../../redux/user/user.selectors';
import MessageCard from '../message-card/message-card.component';
import * as S from './account-inbox-message.styles';
import PurchaseMessage from './purchase-message/purchase-message.component';
import ShareableMessage from './shareable-message/shareable-message.component';
import SurveyMessage from './survey-message/survey-message.component';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDarkIcon } from 'components/inc-button/inc-button.styles';
import DefaultMessageView from './default-message-view/default-message-view.component';
import MessageExpiration from '../message-card/message-expiration/message-expiration.component';
import CheckoutPromocodeButtons from './checkout-promocode-buttons/checkout-promocode-buttons.component';

const AccountInboxMessage = ({ message, checkoutPromoCode, handleClose }) => {
  const {
    distributedMessageId,
    distributedOfferId,
    distributedDate,
    redeemType,
    messageType,
    title,
    status,
    smallImage,
    mediumImage,
    shortDescription,
    longDescription,
    surveyId,
    universalOffer,
    eligible,
    isLoyaltyOffer,
    shareTitle,
    redemptionEndDate,
    distributedEndDate,
    redemptionEndDateType,
  } = message;
  const type = redeemType ? redeemType : messageType;
  const disabled = isLoyaltyOffer && !eligible;
  const expireDate = redemptionEndDate ?? distributedEndDate;
  const isMessage = !!distributedMessageId;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsInboxItemLoading);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const allActivityBasedContent = useSelector(selectAllActivityBasedContent);
  const [isContentDialogOpen, setIsContentDialogOpen] = useState(false);
  const { deleteOffer, updateOfferStatus } = useOffers();
  const { deleteMessage, updateMessageStatus } = useMessages();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleDelete = async () => {
    if (distributedOfferId) {
      await deleteOffer(distributedOfferId);
      dispatch(deleteActivityBasedContent(distributedOfferId));
    } else if (distributedMessageId) {
      await deleteMessage(distributedMessageId);
      dispatch(deleteActivityBasedContent(distributedMessageId));
    }

    setModalOpen(false);
  };

  const handleOpenMessageOrOffer = async () => {
    if (disabled) return;

    if (checkoutPromoCode) {
      setDialogContent(
        <DefaultMessageView
          title={title}
          shortDescription={shortDescription}
          longDescription={longDescription}
        />,
      );
    }

    let data = message;

    if (!type || (['SHAREABLE', 'REFERRAL'].includes(type) && !shareTitle)) {
      if (distributedOfferId) {
        data = await dispatch(getDistributedOfferData(distributedOfferId));
      } else if (distributedMessageId) {
        data = await dispatch(getDistributedMessageData(distributedMessageId));
      }
    }

    const finalType = data?.redeemType ?? data?.messageType ?? type;

    switch (finalType) {
      case 'PURCHASE':
        if (data) {
          setDialogContent(
            <PurchaseMessage
              messageData={data}
              checkoutPromoCode={checkoutPromoCode}
            />,
          );
        }
        break;

      case 'SHAREABLE':
      case 'REFERRAL':
        if (data) {
          setDialogContent(
            <ShareableMessage
              messageData={data}
              handleClose={() => setModalOpen(false)}
              setIsContentDialogOpen={setIsContentDialogOpen}
            />,
          );
        }
        break;

      case 'SURVEY':
        setDialogContent(
          <SurveyMessage
            surveyId={surveyId}
            distributedMessageId={distributedMessageId}
            handleClose={() => setModalOpen(false)}
          />,
        );
        break;

      default:
        setDialogContent(
          <DefaultMessageView
            title={title}
            shortDescription={shortDescription}
            longDescription={longDescription}
          />,
        );
    }

    if (status !== 'READ' && status !== 'EXPIRED' && isLoggedIn) {
      distributedOfferId
        ? updateOfferStatus(distributedOfferId, 'READ')
        : updateMessageStatus(distributedMessageId, 'READ');
    }

    setModalOpen(true);
  };

  return (
    <>
      <MessageCard
        title={title}
        image={smallImage}
        description={shortDescription}
        date={distributedDate}
        status={status}
        onClick={handleOpenMessageOrOffer}
        disabled={disabled}
        expireDate={expireDate}
        redemptionEndDateType={redemptionEndDateType}
        distributedMessageId={distributedMessageId}
      />
      <CustomDialog
        open={modalOpen}
        close={() => setModalOpen(false)}
        image={mediumImage}
        title={type === 'SURVEY' ? title : null}
        testId="AccountInboxMessage.CustomDialog"
      >
        {dialogContent}
        {isLoading && <Spinner />}
        <S.ActionsGrid>
          {!!expireDate && !isMessage && (
            <Box
              marginBottom="10px"
              data-testid="AccountInboxMessage.CustomDialog.ExpirationDateTime"
            >
              <MessageExpiration
                expireDate={expireDate}
                redemptionEndDateType={redemptionEndDateType}
              />
            </Box>
          )}
          <Grid container spacing={3}>
            {!checkoutPromoCode ? (
              <>
                <Grid item xs={universalOffer ? 12 : 6}>
                  <IncButton
                    onClick={() => setModalOpen(false)}
                    data-testid="AccountInboxMessage.CustomButton.Close"
                    fullWidth
                  >
                    {t('accountInbox.closeAction')}
                  </IncButton>
                </Grid>
                {!universalOffer && (
                  <Grid item xs={6}>
                    <IncButtonDarkIcon
                      onClick={handleDelete}
                      data-testid="AccountInboxMessage.CustomButton.Delete"
                      fullWidth
                    >
                      {t('accountInbox.deleteAction')}
                      <DeleteIcon />
                    </IncButtonDarkIcon>
                  </Grid>
                )}
              </>
            ) : (
              <CheckoutPromocodeButtons
                message={message}
                handleClose={handleClose}
                setModalOpen={setModalOpen}
                applyDiscount={applyDiscount}
              />
            )}
          </Grid>
        </S.ActionsGrid>
      </CustomDialog>

      {isContentDialogOpen && (
        <MessagesDialog
          open
          setOpen={setIsContentDialogOpen}
          messages={allActivityBasedContent}
          testId="AccountInboxMessage.MessagesDialog"
        />
      )}
    </>
  );
};

export default AccountInboxMessage;
