import { ErrorMessage } from '@hookform/error-message';
import { Input, InputLabel } from '@material-ui/core';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import React from 'react';
import { useGiftCardNumberInput } from './gift-card-number-input.hooks';
import { useFormContext } from 'react-hook-form';

const GiftCardNumberInput = ({ name, id, label }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { errorMessage } = useGiftCardNumberInput(errors[name]);

  const { ref, ...registerProps } = register(name, {
    required: true,
    maxLength: 40,
  });

  return (
    <CustomInput>
      <InputLabel htmlFor={id}>{label}*</InputLabel>
      <Input
        id={id}
        {...registerProps}
        inputRef={ref}
        fullWidth
        onKeyPress={event => {
          if (!/[a-zA-Z0-9]/.test(event.key)) {
            event.preventDefault();
          } else {
            event.target.value += event.key.toUpperCase();
            event.preventDefault();
          }
        }}
        onInput={event => {
          event.target.value = event.target.value
            .replace(/[^a-zA-Z0-9]/g, '')
            .toUpperCase();
        }}
      />
      {errors[name] && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={() => <FormError errorMessage={errorMessage} />}
        />
      )}
    </CustomInput>
  );
};

export default GiftCardNumberInput;
