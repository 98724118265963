import INC_BASE_API from 'apis/incentivio-api';
import { useHandleOrderStatus } from 'pages/checkout/checkout-buttons-new/checkout-buttons-new.hooks';
import { useCheckoutPaymentLoading } from 'pages/checkout/checkout.component';
import { CHECKOUT_SECTIONS } from 'pages/checkout/checkout.constants';
import CheckoutContext from 'pages/checkout/checkout.context';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectPaymentOption } from 'redux/cart/cart.selectors';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import { IncentivioApiError } from 'util/errors';
import {
  PaymentOptionType,
  SpreedlyPaymentProcessorType,
} from '../payment.constants';
import { NewPaymentContext } from '../payment.context';
import {
  useMakePayment,
  useMakePaymentBody,
  usePaymentGiftCardAsAGuest,
  usePaymentMode,
  usePurchaseEmailGiftCardBody,
  useWallets,
} from '../payment.new.hooks';
import SpreedlyContext from './spreedly.context';
import { useApplePay } from './toast/apple-pay.hooks';
import SpreedlyCheckoutButtonsContext from 'pages/checkout/checkout-buttons-new/checkout-buttons-wrapper/spreedly-checkout-buttons/spreedly-checkout-buttons-container/spreedly-checkout-buttons-provider/spreedly-checkout-buttons.context';
import { useIsNewSpreedlyCardFlow } from '../payment-selector/credit-card-panel/credit-card-panel-controller/credit-card-panel-controller.hooks';

export const getSpreedlyPaymentProcessorType = ({ attributes } = {}) =>
  Object.values(SpreedlyPaymentProcessorType).find(
    value => value === attributes?.PAYMENT_PROCESSOR,
  ) ?? SpreedlyPaymentProcessorType.OTHER;

export const useSpreedlyPayments = () => {
  const [cardInfo, setCardInfo] = useState();
  const [spreedlyPaymentProcessorType, setSpreedlyPaymentProcessorType] =
    useState();
  const { initOrderResponse } = useContext(NewPaymentContext);
  useSetWalletSupport(spreedlyPaymentProcessorType);

  useEffect(() => {
    setSpreedlyPaymentProcessorType(
      getSpreedlyPaymentProcessorType(initOrderResponse),
    );
  }, [initOrderResponse]);

  return {
    cardInfo,
    setCardInfo,
    spreedlyPaymentProcessorType,
  };
};

export const usePlaceOrderWithCard = () => useMakePayment(useMakePaymentBody());

const useHandleCardPaymentSuccess = () => {
  const history = useHistory();
  const { setThreeDSTransactionToken } = useContext(
    SpreedlyCheckoutButtonsContext,
  );

  const trigger = response => {
    if (response.orderStatus === 'PAYMENT_3DS2_AUTHENTICATION_PENDING') {
      setThreeDSTransactionToken(response.externalStatuses.TRANSACTION_TOKEN);
      return;
    }
    history.push('/checkout/thankyou', response);
  };

  return { trigger };
};

export const useCheckoutSpreedlyCardPaymentWithoutModal = () => {
  const { t } = useTranslation();
  const { trigger: cardTrigger } = usePlaceOrderWithCard();
  const handleOrderStatus = useHandleOrderStatus();
  const { cardInfo, setCardInfo } = useContext(SpreedlyContext);
  const { scrollToSection, setSectionValidationState } =
    useContext(CheckoutContext);
  const addPromise = useCheckoutPaymentLoading(state => state.addPromise);
  const setPaymentLoading = useCheckoutPaymentLoading(
    state => state.setLoading,
  );
  const validate = useValidateSpreedly();
  const { trigger: cardPaymentSuccessTrigger } = useHandleCardPaymentSuccess();

  const handleSpreeldyPaymentWithoutModalGuestPayment = async data => {
    try {
      const response = await addPromise(cardTrigger(data));
      setCardInfo(null);
      cardPaymentSuccessTrigger(response.data);
    } catch (e) {
      handleOrderStatus(e);
    }
  };

  useEffect(() => {
    if (!cardInfo) return;
    handleSpreeldyPaymentWithoutModalGuestPayment({
      paymentToken: cardInfo.token,
      savePaymentInstrument: cardInfo.savePaymentInstrument ?? false,
      isOneTimeTransaction: true,
      cardNickname: cardInfo.cardNickname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInfo]);

  const trigger = () => {
    if (validate()) {
      setPaymentLoading(true);
      document.getElementById('payment-form').requestSubmit();
    } else {
      scrollToSection(CHECKOUT_SECTIONS.PAYMENT);
      setSectionValidationState(CHECKOUT_SECTIONS.PAYMENT, {
        message: t('checkout.payment.errors.requiredFields'),
      });
      document.getElementById('errors').innerHTML = '';
    }
  };

  return { trigger };
};

export const useSpreedlyPaymentsPlaceOrder = () => {
  const addPromise = useCheckoutPaymentLoading(state => state.addPromise);
  const paymentMode = usePaymentMode();
  const paymentOption = useSelector(selectPaymentOption);
  const { trigger: cardTrigger } = usePlaceOrderWithCard();
  const { trigger: spreedlyCardPaymentWithoutModalTrigger } =
    useCheckoutSpreedlyCardPaymentWithoutModal();
  const { trigger: cardPaymentSuccessTrigger } = useHandleCardPaymentSuccess();
  const { trigger: lookupGiftCardTrigger } = usePaymentGiftCardAsAGuest();
  const isNewSpreedlyCardFlow = useIsNewSpreedlyCardFlow();
  const { isSpreedlyFormStep } = useContext(CheckoutContext);
  const history = useHistory();

  let response;

  const trigger = async () => {
    try {
      if (paymentMode === 'NO_PAYMENT') {
        response = await addPromise(cardTrigger());
        history.push('/checkout/thankyou', response.data);
      } else {
        // eslint-disable-next-line default-case
        switch (paymentOption) {
          case PaymentOptionType.CARD:
            if (isNewSpreedlyCardFlow && isSpreedlyFormStep)
              spreedlyCardPaymentWithoutModalTrigger();
            else {
              response = await addPromise(cardTrigger());
              cardPaymentSuccessTrigger(response.data);
            }
            break;

          case PaymentOptionType.PAY_LATER:
          case PaymentOptionType.GIFT_CARD:
            response = await addPromise(cardTrigger());
            history.push('/checkout/thankyou', response.data);
            break;

          case PaymentOptionType.GUEST_CARD:
            spreedlyCardPaymentWithoutModalTrigger();
            break;

          case PaymentOptionType.GUEST_GIFT_CARD:
            const { lookupToken, giftCardNumber } = await addPromise(
              lookupGiftCardTrigger(),
            );
            if (lookupToken) {
              const response = await cardTrigger({
                paymentToken: giftCardNumber,
                isOneTimeTransaction: true,
              });

              history.push('/checkout/thankyou', response.data);
            }
            break;
        }
      }
    } catch (e) {
      // handle errors in caller
      throw e;
    }
  };

  return { trigger };
};

export const useSpreedlyEmailGiftCardPayment = () => {
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const body = usePurchaseEmailGiftCardBody();

  const trigger = async paymentToken => {
    try {
      setLoading(true);

      body.paymentToken = paymentToken;

      const response = await INC_BASE_API.post(`/giftcard/purchase`, body, {
        authenticated: isLoggedIn,
      });

      return response;
    } catch (e) {
      throw new IncentivioApiError('useSpreedlyEmailGiftCardPayment error', e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, trigger };
};

export const useSpreedlyPurchaseEmailGiftCard = () => {
  const {
    loading: emailGiftCardLoading,
    trigger: triggerEmailGiftCardPayment,
  } = useSpreedlyEmailGiftCardPayment();

  const loading = useMemo(() => emailGiftCardLoading, [emailGiftCardLoading]);

  const trigger = async paymentToken => {
    const resp = await triggerEmailGiftCardPayment(paymentToken);

    return resp;
  };

  return { loading, trigger };
};

export const useSpreedlyPaymentProcessorMatches = () => {
  const spreedlyPaymentContext = useContext(SpreedlyContext);

  return paymentProcessorTypes =>
    paymentProcessorTypes.includes(
      spreedlyPaymentContext?.spreedlyPaymentProcessorType,
    );
};

export const useSetWalletSupport = spreedlyPaymentProcessorType => {
  const { enabled: applePayEnabled, canMakePayments: canMakeApplePayPayments } =
    useApplePay();
  const { setWalletSupport, setLoading } = useWallets();

  useEffect(() => {
    const execute = async () => {
      // eslint-disable-next-line default-case
      switch (spreedlyPaymentProcessorType) {
        case SpreedlyPaymentProcessorType.TOAST:
          setLoading(true);
          const applePay = applePayEnabled && (await canMakeApplePayPayments());
          setWalletSupport(prev => ({
            ...prev,
            applePay,
          }));
          setLoading(false);
          break;
      }
    };

    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applePayEnabled,
    // canMakeApplePayPayments,
    setLoading,
    setWalletSupport,
    spreedlyPaymentProcessorType,
  ]);
};

export const useValidateSpreedly = () => {
  const validate = () => {
    return !['first_name', 'last_name', 'month', 'year'].find(
      field => !document.getElementById(field).value,
    );
  };

  return validate;
};
