import React, { useState } from 'react';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import { Input, InputLabel } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PasswordVisibility from '../password-visibility/password-visibility.component';

const ConfirmPassword = () => {
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext();
  const { t } = useTranslation();

  const password = watch('password');

  return (
    <CustomInput>
      <InputLabel htmlFor="confirmPassword">
        {t('forms.confirmPassword.label')}*
      </InputLabel>
      <Input
        fullWidth
        data-testid="ConfirmPassword.Input"
        id="confirmPassword"
        type={showConfirmPassword ? 'text' : 'password'}
        error={!!errors.password}
        {...register('confirmPassword', {
          validate: value =>
            value === password ||
            t('forms.confirmPassword.errors.passwordMismatch'),
        })}
        placeholder={t('forms.confirmPassword.placeholder')}
        endAdornment={
          <PasswordVisibility
            showPassword={showConfirmPassword}
            setShowPassword={setConfirmShowPassword}
            testId="ConfirmPasswordVisibility.IconButton"
          />
        }
      />
      {errors.confirmPassword && (
        <FormError
          errorMessage={errors.confirmPassword.message}
          data-testid="ConfirmPassword.FormError"
        />
      )}
    </CustomInput>
  );
};

export default ConfirmPassword;
