import { createTheme, darken, lighten } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createPalette from '@material-ui/core/styles/createPalette';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMemo } from 'react';
import 'react-awesome-slider/dist/styles.css';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { selectors } from 'redux/app/app.slice';
import { DARK_THEME, DEFAULT_THEME } from 'theme.constants';
import {
  selectColors,
  selectFonts,
  selectMessageCountColor,
} from './redux/config/config.selectors';

export const useBreakPoints = () => {
  const breakpoints = createBreakpoints({});
  const matchesMd = useMediaQuery(breakpoints.up('md'));
  const matchesLg = useMediaQuery(breakpoints.up('lg'));

  return {
    breakpoints,
    matchesMd,
    matchesLg,
  };
};

const useThemeVars = () => {
  const colors = useSelector(selectColors);
  const fonts = useSelector(selectFonts);
  const messageCountColor = useSelector(selectMessageCountColor);
  const isMenu = useRouteMatch(['/locations/:storeTitle/menu']);

  const { breakpoints, matchesMd, matchesLg } = useBreakPoints();

  const spacing = useMemo(() => (matchesLg ? 16 : 8), [matchesLg]);
  const navHeight = useMemo(() => {
    let navHeight = isMenu ? '99px' : '60px';
    if (matchesMd) {
      navHeight = '60px';
    }
    if (matchesLg) {
      navHeight = '80px';
    }
    return navHeight;
  }, [isMenu, matchesLg, matchesMd]);
  const overrides = useMemo(
    () => ({
      MuiButton: {
        root: {
          borderRadius: '100px',
          fontSize: '1rem',
          textTransform: 'capitalize',
          fontWeight: 'bold',
        },
      },
      MuiIconButton: {
        root: {
          color: 'inherit',
        },
      },
      MuiAlert: {
        root: {
          alignItems: 'center',
        },
      },
      MuiLink: {
        button: {
          textDecoration: 'underline',
        },
      },
    }),
    [],
  );

  return {
    colors,
    fonts,
    messageCountColor,
    breakpoints,
    spacing,
    navHeight,
    overrides,
  };
};

export const useTheme = () => {
  const {
    colors,
    fonts,
    messageCountColor,
    breakpoints,
    spacing,
    navHeight,
    overrides,
  } = useThemeVars();

  const standardPalette = createPalette({
    ...colors,
    primary: {
      main: colors?.primaryColor ?? '#3ce',
      contrastText: fonts?.tertiaryFont?.fontColor ?? 'white',
    },
    secondary: {
      main: colors?.secondaryColor ?? '#5ee',
      contrastText: fonts?.tertiaryFont?.fontColor ?? 'white',
    },
    accentColor: colors?.accentColor ?? 'white',
    bgColor: colors?.bgColor ?? '#fbfbfb',
    messageCountColor: messageCountColor ?? '#e55241',
    tonalOffset: 0.15,
    text: {
      primary: '#000',
      primaryLight: lighten('#000', 0.3),
    },
  });

  const standardTheme = createTheme({
    fonts,
    spacing,
    navHeight,
    palette: standardPalette,
    breakpoints,
    overrides: {
      ...overrides,
      MuiCssBaseline: {
        '@global': {
          body: {
            color: standardPalette.text.primary,
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: standardPalette.text.primary,
          fontSize: '0.875rem',
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          margin: 'auto',
        },
      },
    },
  });

  const darkPalette = createPalette({
    ...colors,
    primary: {
      main: '#fff',
      contrastText: '#000',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
    accentColor: 'white',
    bgColor: '#121212',
    messageCountColor: messageCountColor ?? '#e55241',
    tonalOffset: 0.15,
    text: {
      primary: '#fff',
      primaryLight: darken('#fff', 0.2),
    },
    type: 'dark',
    headerBgColor: '#343434',
  });

  const darkTheme = createTheme({
    fonts,
    spacing,
    navHeight,
    palette: darkPalette,
    breakpoints,
    overrides: {
      ...overrides,
      MuiCssBaseline: {
        '@global': {
          body: {
            color: darkPalette.text.primary,
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: darkPalette.text.primary,
          fontSize: '0.875rem',
        },
      },
    },
  });

  const savedTheme = useSelector(selectors.selectTheme);
  const theme = useMemo(() => {
    let theTheme;
    switch (savedTheme) {
      case DEFAULT_THEME:
        theTheme = standardTheme;
        break;
      case DARK_THEME:
        theTheme = darkTheme;
        break;
      default:
        theTheme = standardTheme;
    }
    return theTheme;
  }, [darkTheme, savedTheme, standardTheme]);

  return { theme, standardTheme, darkTheme };
};
