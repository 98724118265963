import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const useSetDefaultOrderType = (orderType, defaultTab) => {
  const { setValue } = useFormContext();
  const { fieldNames } = useFieldNames();

  useEffect(() => {
    if (!orderType) setValue(fieldNames.orderType, defaultTab);
  }, [defaultTab, fieldNames.orderType, orderType, setValue]);
};
