import React from 'react';
import ActionLink from 'components/action-link/action-link.component';
import { formatPhoneNumber } from 'pages/locations/locations.utils';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const StorePhone = ({ phoneNumber }) => {
  const { t } = useTranslation();

  if (!phoneNumber) return null;

  return (
    <ActionLink
      data-testid="StorePhone.ActionLink"
      href={`tel:${phoneNumber}`}
      component="a"
      type=""
      aria-label={`${t('locations.card.phone.ariaLabel')} ${phoneNumber}`}
    >
      <Typography variant="body2">{formatPhoneNumber(phoneNumber)}</Typography>
    </ActionLink>
  );
};

export default StorePhone;
