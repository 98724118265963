import React from 'react';
import { useSelector } from 'react-redux';
import CustomDialog from '../custom-dialog/custom-dialog.component';
import {
  selectLoyaltyConfirmedPoints,
  selectLoyaltyData,
} from '../../redux/user/user.selectors';
import Spinner from '../spinner/spinner.component';
import {
  selectLoyaltyHeader,
  selectLoyaltyPointName,
} from '../../redux/config/config.selectors';
import * as S from './account-loyalty.styles';
import AccountLoyaltyCard from '../account-loyalty-card/account-loyalty-card.component';
import AccountLoyaltyBody from './account-loyalty-body/account-loyalty-body.component';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoyaltyContext } from './account-loyalty.context';
import { useLoyaltyContent } from './account-loyalty.hooks';
import LoyaltyFooter from './loyalty-footer/loyalty-footer.component';
import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';

const AccountLoyalty = ({ open, handleClose }) => {
  const {
    open: showCard,
    handleClose: handleCloseCard,
    handleOpen: handleShowLoyaltyCard,
  } = useCustomDialog();
  const loyaltyHeader = useSelector(selectLoyaltyHeader);
  const loyaltyData = useSelector(selectLoyaltyData);
  const points = useSelector(selectLoyaltyConfirmedPoints);
  const pointName = useSelector(selectLoyaltyPointName(points));
  const { t } = useTranslation();

  const loyaltyContent = useLoyaltyContent();
  const { initialLoading, initialLoaderId, containerAriaAttributes, ...rest } =
    loyaltyContent;

  return (
    <LoyaltyContext.Provider value={rest}>
      <CustomDialog
        close={handleClose}
        open={open}
        testId="AccountLoyalty.CustomDialog"
        paperId={loyaltyContent.containerId}
        {...containerAriaAttributes}
      >
        {initialLoading && <Spinner id={initialLoaderId} />}

        <S.Title>{loyaltyHeader}</S.Title>

        {loyaltyData && (
          <Box
            tabIndex="0"
            aria-label={t('accountLoyalty.points.ariaLabel', {
              points,
              pointName,
            })}
          >
            <AccountLoyaltyBody points={points} pointName={pointName} />
          </Box>
        )}

        <LoyaltyFooter handleShowLoyaltyCard={handleShowLoyaltyCard} />
      </CustomDialog>

      {showCard && (
        <AccountLoyaltyCard
          open={showCard}
          handleClose={handleCloseCard}
          fetchLoyaltyData={false}
        />
      )}
    </LoyaltyContext.Provider>
  );
};

export default AccountLoyalty;
