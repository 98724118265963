import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  selectClientName,
  selectIsDateOfBirthEnabled,
  selectSocialSignInEnabled,
} from 'redux/config/config.selectors';
import { selectIsLoading } from '../../redux/user/user.selectors';
import CustomCheckbox from '../custom-checkbox/custom-checkbox.component';
import FormError from '../form-error/form-error.component';
import Form from '../form/form.component';
import InputPhone from '../input-phone/input-phone.component';
import Spinner from '../spinner/spinner.component';
import * as S from './register-fields.styles';
import FirstName from 'components/form/form-fields/first-name.component';
import LastName from 'components/form/form-fields/last-name.component';
import ReferralCode from './referral-code/referral-code.component';
import { signUpUserStartAsync } from 'redux/user/user.actions.js';
import Password from 'components/form/form-fields/password/password.component';
import ConfirmPassword from 'components/form/form-fields/confirm-password/confirm-password.component';
import Allergy from 'components/dietary-preferences/allergy/allergy.component';
import Dietary from 'components/dietary-preferences/dietary/dietary.component';
import { Box, Grid, Typography } from '@material-ui/core';
import GoogleSignUp from 'components/create-account/google-signup/google-signup.component';
import Email from 'components/form/form-fields/email.component';
import AppleSignup from 'components/create-account/apple-signup/apple-signup.component';
import BirthMonthDaySelect from 'components/form/form-fields/birth-month-day-select/birth-month-day-select.component';
import { getDateOfBirth } from 'utils';
import TocAndPrivacy from 'components/toc-and-privacy/toc-and-privacy.component';

const RegisterFields = ({ onSuccess, registerData, send }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const clientName = useSelector(selectClientName);
  const isDateOfBirthEnabled = useSelector(selectIsDateOfBirthEnabled);
  const socialLogin = useSelector(selectSocialSignInEnabled);

  const [error, setError] = useState();
  const isCheckout = useRouteMatch(['/checkout']);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...(registerData?.userData ?? {}),
      birthMonth: '',
      birthDay: '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
  } = methods;

  useEffect(() => {
    setValue('firstName', registerData?.userData?.firstName);
    setValue('lastName', registerData?.userData?.lastName);
    setValue('email', registerData?.userData?.email);
  }, [setValue, registerData]);

  const onSubmit = async formData => {
    const { birthMonth, birthDay, ...data } = formData;
    const newData = {
      ...data,
      dateOfBirth: getDateOfBirth(birthMonth, birthDay),
      password: !!registerData?.userData?.firstName ? 'null' : data.password,
      jwt: registerData?.jwt,
      authenticationProvider: registerData?.authProvider,
    };

    const response = await dispatch(signUpUserStartAsync(newData));

    const obj = !!registerData?.userData?.firstName
      ? {
          ...response.data,
          email: newData.email,
          phoneNumber: newData.phoneNumber,
          password: newData.password,
          authenticationProvider: newData.authenticationProvider,
          jwt: newData.jwt,
        }
      : {
          ...response.data,
          email: data.email,
          phoneNumber: data.phoneNumber,
          password: data.password,
        };

    if (!!response?.data) {
      onSuccess(obj);
    } else {
      setError(response);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isLoading && !isCheckout && <Spinner fade={false} />}

        {!!socialLogin && !registerData && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <GoogleSignUp
                  btnText={t('authOptions.label.googleSignUp')}
                  send={send}
                  setError={setError}
                />
              </Grid>
              <Grid item xs={12}>
                <AppleSignup
                  btnText={t('authOptions.label.appleSignUp')}
                  send={send}
                  setError={setError}
                />
              </Grid>
            </Grid>

            <Box mt={1}>
              <Typography variant="h6">
                {t('register.label.personalInfo')}
              </Typography>
            </Box>
            <S.HorizontalLine />
          </>
        )}

        <FirstName register={register} errors={errors} />

        <LastName register={register} errors={errors} />

        <Email
          register={register}
          errors={errors}
          disabled={!!registerData?.userData?.email}
        />

        {!registerData && (
          <>
            <Password />
            <ConfirmPassword />
          </>
        )}

        <InputPhone />

        {isDateOfBirthEnabled && <BirthMonthDaySelect />}

        <Allergy />

        <Dietary />

        <ReferralCode register={register} />

        <CustomCheckbox
          aria-label={t('register.label.newsletterSubscribe', {
            clientName,
          })}
          defaultChecked={true}
          label={t('register.label.newsletterSubscribe', {
            clientName,
          })}
          {...register('EMAIL_OPT_IN', {
            required: false,
          })}
          dataTestId="RegisterFields.CustomCheckbox.Subscribe"
        />

        {error && <FormError errorMessage={error} />}

        <S.SubmitButton
          type="submit"
          data-testid="RegisterFields.CustomButton.Submit"
          fullWidth
          disabled={isSubmitting}
        >
          {t('register.label.createAccount')}
        </S.SubmitButton>

        <TocAndPrivacy
          description={t('register.termsAndConditions.description')}
        />
      </Form>
    </FormProvider>
  );
};

export default RegisterFields;
