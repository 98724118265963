import { Typography } from '@material-ui/core';
import { useLoginLink } from 'hooks/useLoginLink';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsWOV2Enabled } from 'redux/config/config.selectors';
import * as S from './verify-email-success-message.styles';

const VerifyEmailSuccessMessage = () => {
  const { t } = useTranslation();
  const isWOV2Enabled = useSelector(selectIsWOV2Enabled);

  const handleLoginLinkClick = useLoginLink();

  if (!isWOV2Enabled) {
    return (
      <Typography
        component="h2"
        tabIndex="0"
        aria-label={t('verifyEmail.successMessage.complete')}
      >
        {t('verifyEmail.successMessage.complete')}
      </Typography>
    );
  }

  return (
    <Typography component="h2">
      {t('verifyEmail.successMessage.anons.1')}{' '}
      <S.LinkButton
        component="button"
        variant="body1"
        onClick={handleLoginLinkClick}
        data-testid="VerifyEmailSuccessMessage.LinkButton"
        aria-label={t('verifyEmail.successMessage.anons.ariaLabel')}
      >
        {t('verifyEmail.successMessage.anons.login')}
      </S.LinkButton>{' '}
      {t('verifyEmail.successMessage.anons.2')}
    </Typography>
  );
};

export default VerifyEmailSuccessMessage;
