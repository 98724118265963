import { Box } from '@material-ui/core';
import { PaymentOptionType } from '../payment.constants';
import CreditCardPanel from './credit-card-panel/credit-card-panel.component';
import GiftCardPanel from './gift-card-panel/gift-card-panel.component';
import GuestCardPanel from './guest-card-panel.component/guest-card-panel.component';
import PaymentOptions from './payment-options/payment-options.component';
import GuestGiftCardPanel from './guest-gift-card-panel/guest-gift-card-panel.component';

const PaymentSelector = ({
  paymentOptions = [],
  selectedPaymentOption,
  onChangePaymentOption,
  cards = [],
  selectedCard,
  onChangeCard,
  giftCards = [],
  selectedGiftCard,
  onChangeGiftCard,
}) => {
  return (
    <Box data-testid="PaymentSelector.Box">
      <PaymentOptions
        options={paymentOptions}
        value={selectedPaymentOption}
        onChange={onChangePaymentOption}
      />
      <Box mt={1}>
        {selectedPaymentOption === PaymentOptionType.CARD && (
          <CreditCardPanel
            cards={cards}
            selectedCard={selectedCard}
            onChangeCard={onChangeCard}
          />
        )}

        {selectedPaymentOption === PaymentOptionType.GUEST_CARD && (
          <GuestCardPanel />
        )}

        {selectedPaymentOption === PaymentOptionType.GIFT_CARD && (
          <GiftCardPanel
            giftCards={giftCards}
            selectedGiftCard={selectedGiftCard}
            onChangeGiftCard={onChangeGiftCard}
          />
        )}

        {selectedPaymentOption === PaymentOptionType.GUEST_GIFT_CARD && (
          <GuestGiftCardPanel />
        )}
      </Box>
    </Box>
  );
};

export default PaymentSelector;
