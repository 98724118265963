import { Brightness4, Brightness7 } from '@material-ui/icons';
import HeaderTooltipIcon from 'components/header/header-togglers/header-tooltip-icon/header-tooltip-icon.component';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'redux/app/app.slice';
import { DARK_THEME, DEFAULT_THEME } from 'theme.constants';

const ThemeToggle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const savedTheme = useSelector(selectors.selectTheme);
  const toggleTheme = useCallback(() => {
    const newTheme = savedTheme === DARK_THEME ? DEFAULT_THEME : DARK_THEME;
    dispatch(actions.setTheme(newTheme));
  }, [dispatch, savedTheme]);

  return (
    <HeaderTooltipIcon
      tooltipText={t('header.themeToggle.tooltipText')}
      onClick={toggleTheme}
      icon={savedTheme === DARK_THEME ? <Brightness7 /> : <Brightness4 />}
      data-testid="ThemeToggle.HeaderTooltipIcon"
    />
  );
};

export default ThemeToggle;
