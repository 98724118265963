import { Link, Typography } from '@material-ui/core';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { selectSlug } from 'redux/cart/cart.selectors';
import { selectRequestedStoreDITitle } from 'redux/locations/locations.selectors';

const CheckoutBreadcrumbs = () => {
  const { t } = useTranslation();
  const storeSlug = useSelector(selectSlug);
  const storeTitle = useSelector(selectRequestedStoreDITitle);

  return (
    <Breadcrumbs className="breadcrumbs" maxWidth="xl">
      <Link to="/" component={RouterLink}>
        {t('breadcrumbs.locations')}
      </Link>
      <Link to={`/locations/${storeSlug}`} component={RouterLink}>
        {storeTitle}
      </Link>
      <Link to={`/locations/${storeSlug}/menu`} component={RouterLink}>
        {t('breadcrumbs.menu')}
      </Link>
      <Typography tabIndex="0" aria-label="Currently located at checkout page">
        {t('checkout.title')}
      </Typography>
    </Breadcrumbs>
  );
};

export default CheckoutBreadcrumbs;
