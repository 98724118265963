import React from 'react';
import * as S from '../reset-password/reset-password.styles';
import Spinner from 'components/spinner/spinner.component';
import { Grid } from '@material-ui/core';
import { useImHere } from './imhere.hooks';
import { Description, ContentContainer } from './imhere.styles';
import { Cancel, CheckCircle, Error } from '@material-ui/icons';

const ImHere = () => {
  const { loading, description, status } = useImHere();

  const renderIcon = () => {
    if (status === 'success') {
      return (
        <CheckCircle
          style={{ color: '#2ECC71' }}
          data-testid="ImHere.Success.Icon"
        />
      );
    } else if (status === 'warning') {
      return (
        <Error style={{ color: '#F4CF35' }} data-testid="ImHere.Warning.Icon" />
      );
    } else if (status === 'error') {
      return (
        <Cancel style={{ color: '#FC0000' }} data-testid="ImHere.Error.Icon" />
      );
    }
  };

  return (
    <S.Container data-testid={'ImHere.Container'}>
      {loading && <Spinner />}
      <ContentContainer elevation={2}>
        <Grid container>
          <Grid item xs={1}>
            {renderIcon()}
          </Grid>
          <Grid item xs={11}>
            <Description
              component="h2"
              tabIndex="0"
              aria-label={''}
              data-testid={'ImHere.Description'}
            >
              {description}
            </Description>
          </Grid>
        </Grid>
      </ContentContainer>
    </S.Container>
  );
};

export default ImHere;
