import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import { useSelector } from 'react-redux';
import { selectFbAppId } from '../../redux/config/config.selectors';
import useEventListener from '../../hooks/useEventListener';
import { openWindow } from '../../utils';
import { IncButtonDarkIcon } from 'components/inc-button/inc-button.styles';

const FacebookShare = ({ shareableData, shareableUrl, onShare }) => {
  const fbAppId = useSelector(selectFbAppId);

  useEventListener('message', onShare);

  const openFacebookWindow = () => {
    const redirectUri = `${window.location.origin}/socialshare/fb`;
    const facebookUrl = `https://www.facebook.com/dialog/share?href=${shareableUrl}&quote=${shareableData}&display=popup&app_id=${fbAppId}&redirect_uri=${redirectUri}`;
    openWindow(
      facebookUrl,
      '_blank',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=640,left=200,top=100',
    );
  };

  return (
    <IncButtonDarkIcon
      onClick={openFacebookWindow}
      data-testid="FacebookShare"
      fullWidth
    >
      Facebook
      <FacebookIcon />
    </IncButtonDarkIcon>
  );
};

export default FacebookShare;
