import React from 'react';
import * as S from './menu-card-new-image.styles';
import { useTranslation } from 'react-i18next';

const MenuCardNewImage = ({ smallImage, alt = '' }) => {
  const { t } = useTranslation();

  return (
    smallImage?.length > 0 && (
      <S.MobileImage
        src={smallImage[0]}
        data-testid="MenuCard.Image"
        alt={t('catalog.menuCard.imageAlt', { alt })}
      />
    )
  );
};

export default MenuCardNewImage;
