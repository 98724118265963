import React, { useEffect, useState } from 'react';
import CustomInput from '../custom-input/custom-input.component';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormError from '../form-error/form-error.component';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

const InputPhone = ({ defaultValue, isGuest = false, ...rest }) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const defaultVal = !!defaultValue
    ? parsePhoneNumberFromString(defaultValue, 'US')?.formatNational()
    : '';

  const [phoneNumber, setPhoneNumber] = useState(defaultVal);

  const validatePhone = phone => {
    const parsedPhone = parsePhoneNumberFromString(phone, 'US');
    return parsedPhone && parsedPhone.isPossible();
  };

  useEffect(() => {
    if (!defaultVal) return;
    const newPhoneNumber = parsePhoneNumberFromString(defaultVal, 'US');
    if (!!newPhoneNumber) {
      setPhoneNumber(newPhoneNumber.formatNational());
      setValue('phoneNumber', newPhoneNumber.nationalNumber);
    }
  }, [defaultVal, setValue]);

  return (
    <Controller
      control={control}
      name="phoneNumber"
      rules={{
        required: t('register.errors.requiredField'),
        validate: value =>
          validatePhone(value) || t('forms.errors.phone.invalid'),
      }}
      defaultValue={defaultVal}
      render={({
        field: { onChange, ref, value, ...fieldRest },
        formState: { errors },
      }) => (
        <CustomInput>
          <InputLabel htmlFor="phoneInput">
            {t('register.label.phone')}*
          </InputLabel>
          <Input
            aria-required={true}
            id="phoneInput"
            fullWidth
            type="tel"
            onChange={event => {
              const newPhoneNumber = parsePhoneNumberFromString(
                event.target.value,
                'US',
              );
              if (newPhoneNumber) {
                if (newPhoneNumber.nationalNumber.length > 10) return;
                if (newPhoneNumber.nationalNumber.length === 10) {
                  setPhoneNumber(newPhoneNumber.formatNational());
                  onChange(newPhoneNumber.nationalNumber);
                  return;
                }
              }
              onChange(event.target.value);
              setPhoneNumber(event.target.value);
            }}
            inputRef={ref}
            error={!!errors.phoneNumber}
            placeholder={t('register.placeholder.phone')}
            {...rest}
            {...fieldRest}
            value={phoneNumber}
          />
          <Typography
            variant="caption"
            data-testid="CustomInput.Phone.HelperText"
          >
            {isGuest
              ? t('register.helperText.phone.guest')
              : t('register.helperText.phone.loggedIn')}
          </Typography>
          <ErrorMessage
            name="phoneNumber"
            errors={errors}
            render={({ message }) => (
              <FormError
                errorMessage={message}
                data-testid="InputPhone.FormError"
              />
            )}
          />
        </CustomInput>
      )}
    />
  );
};
export default InputPhone;
