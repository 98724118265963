import { Link } from '@material-ui/core';
import React from 'react';
import { Span, Text } from '../toc-and-privacy.styles';
import { useTranslation } from 'react-i18next';

const TocAndPrivacyFormInfo = ({ description, handleTocOpen }) => {
  const { t } = useTranslation();

  return (
    <Text align="center">
      {description}{' '}
      <Link
        onMouseDown={handleTocOpen}
        data-testid="TocAndPrivacy.TermsAndConditionsLink"
      >
        <Span>{t('guest.termsAndConditions.label')}</Span>
      </Link>
      {' & '}
      <Link
        href="https://incentivio.com/privacy"
        target="_blank"
        data-testid="TocAndPrivacy.PrivacyLink"
      >
        <Span>{t('guest.privacyPolicy.label')}</Span>
      </Link>
    </Text>
  );
};

export default TocAndPrivacyFormInfo;
