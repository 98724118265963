import { toast } from 'react-toastify';
import CommonTypes from '../common.types';
import CartActionTypes from './cart.types';
import { buildOrderItemPaths } from './cart.utils';
import { menuItemKeySplitChar } from 'hooks/dialog/menu-dialog.constants';
import UserTypes from 'redux/user/user.types';

const INITIAL_STATE = {
  order: {},
  orderItemIds: [],
  orderItemPaths: {},
  orderType: null,
  orderOption: null,
  orderInstructions: '',
  isDrawerOpen: false,
  location: null,
  catalogId: null,
  requestedFulfillTime: null,
  customerInfo: null,
  deliveryInstructions: '',
  tip: {
    amount: 0,
    percentage: null,
  },
  requestedAddress: {
    postalCode: null,
    city: null,
    street1: null,
    street2: null,
    state: null,
    country: null,
    lat: null,
    lon: null,
    aptSuite: null,
  },
  isFetching: false,
  offers: null,
  paymentInstrumentId: null,
  paymentOption: null,
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.payload,
        tip: {
          amount: 0,
          percentage: null,
        },
      };

    case CartActionTypes.SET_ORDER_INSTRUCTIONS:
      return {
        ...state,
        orderInstructions: action.payload,
      };

    case CartActionTypes.GET_ORDER_ID_START:
      return {
        ...state,
        isFetching: true,
      };

    case CartActionTypes.NEW_ORDER_START:
      return {
        ...state,
        isFetching: true,
      };

    case CartActionTypes.SET_LOCATION_DATA:
      return {
        ...state,
        location: action.payload,
      };

    case CartActionTypes.NEW_ORDER_SUCCESS:
      const {
        orderId,
        requestedFulfillTime,
        location,
        orderType,
        orderOption,
        address,
      } = action.payload;
      return {
        ...state,
        order: { orderId },
        orderItemIds: [],
        orderItemPaths: {},
        location,
        requestedFulfillTime,
        orderType,
        orderOption,
        requestedAddress: address,
        catalogId: null,
        customerInfo: null,
        tip: {
          amount: 0,
          percentage: null,
        },
        offers: null,
        isFetching: false,
        orderInstructions: '',
        deliveryInstructions: '',
        paymentInstrumentId: null,
        paymentOption: null,
      };

    case CartActionTypes.NEW_ORDER_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.UPDATE_ORDER_START:
      return {
        ...state,
        isFetching: true,
      };

    case CartActionTypes.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        requestedFulfillTime: action.payload.requestedFulfillTime,
        orderType: action.payload.orderType,
        orderOption: action.payload.orderOption,
        requestedAddress: action.payload.address,
        isFetching: false,
      };

    case CartActionTypes.UPDATE_ORDER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.GET_ORDER_BY_ID_START:
      return {
        ...state,
        isFetching: true,
      };

    case CartActionTypes.GET_ORDER_BY_ID_SUCCESS:
    case CartActionTypes.APPLY_DISCOUNT_SUCCESS:
      return {
        ...state,
        order: {
          ...action.payload,
        },
        ...buildOrderItemPaths(action.payload.orderItems, state.orderItemPaths),
        isFetching: false,
      };

    case CartActionTypes.GET_ORDER_BY_ID_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.CLEAR_CART:
      return {
        ...state,
        order: {},
        orderItemPaths: {},
        orderItemIds: [],
        customerInfo: null,
        catalogId: null,
        orderOption: null,
        tip: {
          amount: 0,
          percentage: null,
        },
        requestedFulfillTime: null,
        location: null,
        deliveryInstructions: '',
        orderInstructions: '',
        errorMessage: undefined,
        offers: null,
        paymentInstrumentId: null,
        paymentOption: null,
      };

    case CartActionTypes.SET_ORDER_FULFILL_TIME_SUCCESS:
      return {
        ...state,
        requestedFulfillTime: action.payload,
        isFetching: false,
      };

    case CartActionTypes.SET_REQUESTED_ADDRESS:
      return {
        ...state,
        requestedAddress: {
          ...action.payload,
        },
      };

    case CartActionTypes.SET_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payload,
      };

    case CartActionTypes.MODIFY_ORDER_ITEMS_START:
      return {
        ...state,
        order: {
          ...state.order,
          isModifyingItems: true,
          modifyQtyItemIds: action.payload
            ? [...(state.order.modifyQtyItemIds || []), action.payload]
            : [],
        },
      };

    case CartActionTypes.MODIFY_ORDER_ITEMS_SUCCESS:
      const newOrderItemIds = action.payload.orderItems.orderItems.map(
        orderItem => orderItem.orderItemId,
      );
      return {
        ...state,
        order: {
          ...state.order,
          ...action.payload.orderItems,
          orderDiscounts: undefined,
          isModifyingItems: false,
          modifyQtyItemIds: state.order.modifyQtyItemIds
            ? state.order.modifyQtyItemIds.filter(
                id => action.payload.orderItemId !== id,
              )
            : [],
        },
        orderItemIds: newOrderItemIds,
        orderItemPaths: Object.entries(state.orderItemPaths).reduce(
          (result, [orderItemId, orderItemPath]) => {
            if (newOrderItemIds.includes(orderItemId)) {
              result[orderItemId] = orderItemPath;
            }
            return result;
          },
          {},
        ),
      };

    case CartActionTypes.MODIFY_ORDER_ITEMS_FAILURE:
      return {
        ...state,
        order: {
          ...state.order,
          isModifyingItems: false,
          modifyQtyItemIds: state.order.modifyQtyItemIds
            ? state.order.modifyQtyItemIds.filter(id => action.payload !== id)
            : [],
        },
      };

    case CartActionTypes.ADD_ORDER_ITEM_START:
      return {
        ...state,
        isFetching: true,
      };

    case CartActionTypes.ADD_ORDER_ITEM_SUCCESS:
      const { partialOrder, path } = action.payload;
      const newOrderItemId = partialOrder.orderItems.find(
        orderItem => !state.orderItemIds.includes(orderItem.orderItemId),
      )?.orderItemId;

      return {
        ...state,
        order: {
          ...state.order,
          itemsCountDetails: partialOrder.itemsCountDetails,
          orderItems: partialOrder.orderItems,
          orderTotal: partialOrder.orderTotal,
        },
        orderItemIds:
          partialOrder.orderItems.map(orderItem => orderItem.orderItemId) ?? [],
        orderItemPaths: {
          ...state.orderItemPaths,
          [newOrderItemId]: path,
        },
        isFetching: false,
      };

    case CartActionTypes.ADD_ORDER_ITEM_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.SET_ORDER_OPTION:
      return {
        ...state,
        orderOption: action.payload,
      };

    case CartActionTypes.OPEN_CART_DRAWER:
      return {
        ...state,
        isDrawerOpen: true,
      };

    case CartActionTypes.CLOSE_CART_DRAWER:
      return {
        ...state,
        isDrawerOpen: false,
      };

    case CartActionTypes.SET_DELIVERY_INSTRUCTIONS:
      return {
        ...state,
        deliveryInstructions: action.payload,
      };

    case CartActionTypes.GET_ORDER_OFFERS_START:
      return {
        ...state,
        isFetching: true,
      };

    case CartActionTypes.GET_ORDER_OFFERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        offers: action.payload,
      };

    case CartActionTypes.GET_ORDER_OFFERS_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.COPY_ORDER_START:
      return {
        ...state,
        order: null,
        orderItemIds: [],
        orderItemPaths: {},
        isFetching: true,
      };

    case CartActionTypes.COPY_ORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.COPY_ODER_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.ADD_ORDER_ITEMS_START:
      return {
        ...state,
        isFetching: true,
      };

    case CartActionTypes.ADD_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          itemsCountDetails: action.payload.itemsCountDetails,
          orderItems: action.payload.orderItems,
          orderTotal: action.payload.orderTotal,
        },
        orderItemIds:
          action.payload.orderItems?.map(orderItem => orderItem.orderItemId) ??
          [],
        orderItemPaths: action.payload.orderItems?.reduce(
          (result, orderItem) => {
            result[
              orderItem.orderItemId
            ] = `${orderItem.catalogId}${menuItemKeySplitChar}${orderItem.group.groupId}${menuItemKeySplitChar}${orderItem.itemId}`;
            return result;
          },
          {},
        ),
        isFetching: false,
      };

    case CartActionTypes.ADD_ORDER_ITEMS_FAILURE:
      toast.error(action.payload);
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.SET_TIP:
      return {
        ...state,
        tip: action.payload,
      };

    case CartActionTypes.APPLY_DISCOUNT_START:
      return {
        ...state,
        isFetching: true,
      };

    case CartActionTypes.APPLY_DISCOUNT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.REMOVE_DISCOUNT_START:
      return {
        ...state,
        isFetching: true,
      };

    case CartActionTypes.REMOVE_DISCOUNT_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          orderDiscounts: [],
        },
        isFetching: false,
      };

    case CartActionTypes.REMOVE_DISCOUNT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case CartActionTypes.SET_DEFAULT_CART_ORDER_ITEM_STATE:
      return {
        ...state,
        ...buildOrderItemPaths(state.order?.orderItems, state.orderItemPaths),
      };

    case CartActionTypes.SET_PAYMENT_INSTRUMENT_ID:
      return {
        ...state,
        paymentInstrumentId: action.payload,
      };

    case CartActionTypes.SET_PAYMENT_OPTION:
      return {
        ...state,
        paymentOption: action.payload,
      };

    case CommonTypes.ERROR_HARD_RESET:
      return INITIAL_STATE;

    case UserTypes.SIGN_OUT:
      return {
        ...state,
        customerInfo: null,
      };

    default:
      return state;
  }
};

export default cartReducer;
