import { ErrorMessage } from '@hookform/error-message';
import { InputLabel } from '@material-ui/core';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import Form from 'components/form/form.component';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CardValueSelectorController from 'components/card-value-selector/card-value-selector.controller';
import IncButton from 'components/inc-button/inc-button.component';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import TrimEmailField from 'components/form/form-fields/trim-email-field/trim-email-field.component';
import { selectGiftCardSelectableAmounts } from 'redux/config/config.selectors';
import { useSelector } from 'react-redux';

const EGiftcardForm = ({ onSubmit, data }) => {
  const { t } = useTranslation();
  const presetValues = useSelector(selectGiftCardSelectableAmounts);
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      amount: data.amount ?? presetValues[0],
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
  } = methods;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardValueSelectorController control={control} errors={errors} />

        <CustomInput>
          <InputLabel htmlFor="from">
            {t('eGiftCard.form.from.label')}*
          </InputLabel>
          <TrimTextField
            id="from"
            fullWidth
            placeholder={t('eGiftCard.form.from.placeholder')}
            error={!!errors.from}
            defaultValue={data.from}
            {...register('from', {
              required: t('eGiftCard.form.from.errors.required'),
              validate: value =>
                !value.trim() ? t('eGiftCard.form.from.errors.required') : true,
            })}
          />
          <ErrorMessage
            errors={errors}
            name="from"
            render={({ message }) => <FormError errorMessage={message} />}
          />
        </CustomInput>

        <CustomInput>
          <InputLabel htmlFor="to">{t('eGiftCard.form.to.label')}*</InputLabel>
          <TrimTextField
            id="to"
            fullWidth
            placeholder={t('eGiftCard.form.to.placeholder')}
            error={!!errors.to}
            defaultValue={data.to}
            {...register('to', {
              required: t('eGiftCard.form.to.errors.required'),
              validate: value =>
                !value.trim() ? t('eGiftCard.form.to.errors.required') : true,
            })}
          />
          <ErrorMessage
            errors={errors}
            name="to"
            render={({ message }) => <FormError errorMessage={message} />}
          />
        </CustomInput>

        <CustomInput>
          <InputLabel htmlFor="recipientEmail">
            {t('eGiftCard.form.recipientEmail.label')}*
          </InputLabel>
          <TrimEmailField
            id="recipientEmail"
            fullWidth
            placeholder={t('eGiftCard.form.recipientEmail.placeholder')}
            error={!!errors.recipientEmail}
            defaultValue={data.recipientEmail}
            {...register('recipientEmail', {
              required: t('eGiftCard.form.recipientEmail.errors.required'),
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: t('eGiftCard.form.recipientEmail.errors.email'),
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="recipientEmail"
            render={({ message }) => <FormError errorMessage={message} />}
          />
        </CustomInput>

        <CustomInput>
          <InputLabel htmlFor="verifyRecipientEmail">
            {t('eGiftCard.form.verifyRecipientEmail.label')}*
          </InputLabel>
          <TrimEmailField
            id="verifyRecipientEmail"
            fullWidth
            placeholder={t('eGiftCard.form.verifyRecipientEmail.placeholder')}
            error={!!errors.verifyRecipientEmail}
            defaultValue={data.verifyRecipientEmail}
            {...register('verifyRecipientEmail', {
              required: t(
                'eGiftCard.form.verifyRecipientEmail.errors.required',
              ),
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: t('eGiftCard.form.verifyRecipientEmail.errors.email'),
              },
              validate: {
                match: value =>
                  value === getValues('recipientEmail') ||
                  t('eGiftCard.form.verifyRecipientEmail.errors.match'),
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="verifyRecipientEmail"
            render={({ message }) => <FormError errorMessage={message} />}
          />
        </CustomInput>

        <CustomInput>
          <InputLabel htmlFor="message">
            {t('eGiftCard.form.message.label')}
          </InputLabel>
          <TrimTextField
            id="message"
            fullWidth
            multiline
            minRows={4}
            maxRows={4}
            variant="outlined"
            placeholder={t('eGiftCard.form.message.placeholder')}
            error={!!errors.message}
            defaultValue={data.message}
            {...register('message', {
              maxLength: {
                value: 1000,
                message: t('eGiftCard.form.message.errors.maxLength', {
                  max: 1000,
                }),
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="message"
            render={({ message }) => <FormError errorMessage={message} />}
          />
        </CustomInput>
        <IncButton
          type="submit"
          disabled={!!Object.keys(errors).length}
          data-testid="EGiftcardForm.CustomButton.Submit"
          fullWidth
        >
          {t('eGiftCard.form.submitButton')}
        </IncButton>
      </Form>
    </FormProvider>
  );
};

export default EGiftcardForm;
