import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './drawer-cart-item-qty-buttons.styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { selectHideQtyPicker } from '../../../../redux/config/config.selectors';
import { useTranslation } from 'react-i18next';

const DrawerCartItemQtyButtons = ({
  handleIncrement,
  handleDecrement,
  quantity,
  modifyQtyType,
  modifyingQtyItemIds,
}) => {
  const hideQtyPicker = useSelector(selectHideQtyPicker);
  const { t } = useTranslation();

  return (
    <S.QtyButtons>
      {!hideQtyPicker && (
        <S.QtyButton
          data-testid="DrawerCartItem.QtyButton.Increase"
          onClick={handleIncrement}
          aria-label={t('checkout.items.quantity.increment', { quantity })}
          disabled={modifyQtyType === '+' && modifyingQtyItemIds.length > 0}
        >
          <AddIcon fontSize="small" />
        </S.QtyButton>
      )}

      <S.Quantity aria-label={`Current quantity ${quantity}`} tabIndex="0">
        {quantity}
      </S.Quantity>

      {!hideQtyPicker && (
        <S.QtyButton
          data-testid="DrawerCartItem.QtyButton.Decrease"
          onClick={handleDecrement}
          aria-label={t('checkout.items.quantity.decrement', { quantity })}
          disabled={modifyQtyType === '-' && modifyingQtyItemIds.length > 0}
        >
          <RemoveIcon fontSize="small" />
        </S.QtyButton>
      )}
    </S.QtyButtons>
  );
};

export default DrawerCartItemQtyButtons;
