import { menuItemKeySplitChar } from 'hooks/dialog/menu-dialog.constants';
import { DateTime } from 'luxon';
import { isValidAsapDuration } from 'pages/locations/locations.utils';
import i18n from '../../i18n';

export const addGroupIdToOptions = options =>
  options
    ? options.map(option => {
        let newOpt = {
          ...option,
          groupId: option.group.groupId,
        };

        if (option.options) {
          newOpt = { ...newOpt, options: addGroupIdToOptions(option.options) };
        }

        return newOpt;
      })
    : [];

export const convertDistanceToMiles = distance =>
  Math.round(distance * 0.000621371);

export const getMinutesText = (minutes, hours) => {
  const prefixSpace = hours > 0 ? ' ' : '';
  if (minutes === 0) return '';
  if (minutes === 1)
    return `${prefixSpace}${minutes} ${i18n.t('asapDuration.min')}`;
  return `${prefixSpace}${minutes} ${i18n.t('asapDuration.mins')}`;
};

export const getHoursText = hours => {
  if (hours === 0) return '';
  if (hours === 1) return `${hours} ${i18n.t('asapDuration.hour')}`;
  return `${hours} ${i18n.t('asapDuration.hours')}`;
};

export const getAsapLabel = asapDuration => {
  let asapString = i18n.t('locations.card.asap');
  if (isValidAsapDuration(asapDuration)) {
    const minutes = asapDuration % 60;
    const hours = parseInt(asapDuration / 60);
    asapString += ` (${getHoursText(hours)}${getMinutesText(minutes, hours)})`;
  }

  return asapString;
};

export const makeAvailabilityObject = (
  dateString,
  storeZone,
  displayZone,
  asapDuration,
  firstAvailableTimeString,
  timeValid = true,
) => {
  if (dateString === 'asap') {
    return {
      displayDateTime: null,
      dateString: null,
      key: 'asap',
      valid: timeValid,
      timeLabel: getAsapLabel(asapDuration),
      dayLabel: i18n.t('locations.card.today'),
      firstAvailableTime: DateTime.fromISO(firstAvailableTimeString, {
        zone: storeZone,
      }),
    };
  } else if (!!dateString) {
    const storeDateTime = DateTime.fromISO(dateString, {
      zone: storeZone,
    });
    const displayDateTime = storeDateTime.setZone(displayZone);

    return {
      storeDateTime,
      displayDateTime,
      dateString,
      key: displayDateTime.toISO(),
      valid: timeValid,
      storeZone,
      displayZone,
      timeLabel: displayDateTime.toFormat('h:mm a'),
      dayLabel:
        displayDateTime.toISODate() ===
        DateTime.local({ zone: displayZone }).toISODate()
          ? i18n.t('locations.card.today')
          : displayDateTime.toLocaleString({
              weekday: 'short',
            }),
    };
  }
};

export const getPointsToSpend = discount =>
  discount.appliedDiscount
    ? (discount.appliedDiscount / discount.discountAmount) * discount.pointsCost
    : discount.pointsCost;

export const universalMaxPoints = (
  availablePoints,
  discountAmount,
  pointsCost,
  preDiscountSubtotal,
) => {
  const maxPoints = availablePoints - (availablePoints % pointsCost);
  const maxPointsForOrder =
    Math.floor(preDiscountSubtotal / discountAmount) * pointsCost;
  return Math.min(maxPoints, maxPointsForOrder);
};

export const findAllItemDiscounts = items => {
  if (!items) return [];
  return items.reduce((result, item) => {
    if (item.itemDiscounts) result = result.concat(item.itemDiscounts);
    result = result.concat(findAllItemDiscounts(item.options));
    return result;
  }, []);
};

export const assignGroupIds = items =>
  items.map(item => {
    const newItem = { ...item };
    newItem.groupId = item.group.groupId;
    if (item.options) newItem.options = assignGroupIds(item.options);
    return newItem;
  });

// Maps order items to their ids and paths. If we have existing paths that match
// the new order items, we keep the existing paths. We discard any existing
// paths that no longer match new order item ids
export const buildOrderItemPaths = (
  newOrderItems = [],
  currentOrderItemPaths = {},
) => ({
  orderItemIds: newOrderItems?.map(orderItem => orderItem.orderItemId) ?? [],
  orderItemPaths:
    newOrderItems?.reduce((result, orderItem) => {
      if (currentOrderItemPaths[orderItem.orderItemId]) {
        // We don't want to overwrite these paths if we have them. They may have a
        // more specific group path, so we want to keep them.
        result[orderItem.orderItemId] =
          currentOrderItemPaths[orderItem.orderItemId];
      } else {
        result[
          orderItem.orderItemId
        ] = `${orderItem.catalogId}${menuItemKeySplitChar}${orderItem.group.groupId}${menuItemKeySplitChar}${orderItem.itemId}`;
      }
      return result;
    }, {}) ?? {},
});

export const getFormattedDeliveryAddressObject = address => {
  const {
    postalCode,
    city,
    street1,
    street2,
    state,
    country,
    lat,
    aptSuite,
    lon,
  } = address ?? {};
  return {
    postalCode,
    city,
    street1,
    street2,
    state,
    country,
    lat,
    lon,
    aptSuite,
  };
};

export const getRecommendationStatus = (
  recommendationsEnabled,
  isUserSeenRecommendationItems,
  recommendationView,
  isItemRecommended,
) => {
  if (!recommendationsEnabled) return;
  if (!isUserSeenRecommendationItems) return 'PRE';
  if (recommendationView || isItemRecommended) return 'ACCEPTED';
  return 'POST';
};

export const addGroupIdForOptions = options =>
  options?.map(option => {
    if (option?.options?.length > 0) {
      option.options = addGroupIdForOptions(option.options);
    }
    return {
      ...option,
      groupId: option.group.groupId,
    };
  });

export const arrangeOrderOptions = (data, fieldNames) => {
  if (!!data[fieldNames.orderOption]) {
    return {
      ...data,
      [fieldNames.informationField]: undefined,
      orderOption: {
        ...data[fieldNames.orderOption],
        informationFieldInput: data[fieldNames.informationField],
      },
    };
  }
  return data;
};

export const getOrderAvailabilityError = (
  orderType,
  address,
  withinDeliveryDistance,
  reduxOrderAvailabilityError,
  availableDays,
) => {
  const deliveryDistanceMessage = i18n.t('errors.deliveryDistance');
  const orderingUnavailableMessage = i18n.t('errors.orderingUnavailable');
  const addressMessage = i18n.t('errors.requiredAddress');

  if (orderType === 'DELIVERY') {
    if (!address || address === 'new' || address === 'default')
      return addressMessage;

    if (withinDeliveryDistance === false) return deliveryDistanceMessage;
    else if (!!reduxOrderAvailabilityError) {
      if (reduxOrderAvailabilityError === deliveryDistanceMessage)
        return deliveryDistanceMessage;
      else return reduxOrderAvailabilityError;
    }
  } else if (orderType === 'PICKUP') {
    if (!!reduxOrderAvailabilityError) {
      if (reduxOrderAvailabilityError === deliveryDistanceMessage) return null;
      else return reduxOrderAvailabilityError;
    }
  }

  if (availableDays?.length < 1) {
    return orderingUnavailableMessage;
  }
};
