import { ErrorMessage } from '@hookform/error-message';
import { FormControl, Grid, RadioGroup } from '@material-ui/core';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import FormError from 'components/form-error/form-error.component';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import OfferItem from './offer-item/offer-item.component';

const OffersModal = ({
  applicableOffers,
  pointsToSpend,
  setPointsToSpend,
  defaultValue,
  open,
  handleClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = methods;
  const [loading, setLoading] = useState(false);
  const handleApplyOffer = handleSubmit(async data => {
    setLoading(true);
    try {
      await onSubmit(data);
      handleClose();
    } finally {
      setLoading(false);
    }
  });

  return (
    <CustomDialog
      open={open}
      close={handleClose}
      title={t('checkout.payment.offerSelection.title')}
      testId="OfferSelection.CustomDialog"
    >
      <FormProvider {...methods}>
        <FormControl component="fieldset">
          <Controller
            control={control}
            name="offer"
            aria-label={t(
              'checkout.payment.offerSelection.offerList.ariaLabel',
            )}
            defaultValue={defaultValue}
            rules={{ required: t('checkout.offers.errors.required') }}
            render={({ field: { onChange, onBlur, value } }) => (
              <RadioGroup
                name="offer"
                onChange={onChange}
                value={value}
                onBlur={onBlur}
              >
                {applicableOffers?.map(offer => (
                  <OfferItem
                    key={offer.distributedOfferId}
                    offer={offer}
                    active={value === offer.distributedOfferId}
                    pointsToSpend={pointsToSpend}
                    setPointsToSpend={setPointsToSpend}
                  />
                ))}
              </RadioGroup>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={'offer'}
            render={({ message }) => <FormError errorMessage={message} />}
          />
        </FormControl>
        <Grid container spacing={2} style={{ marginTop: '1em' }}>
          <Grid item xs>
            <IncButtonDark
              className="darkBorder"
              onClick={handleClose}
              data-testid="OfferSelection.CustomButton.Cancel"
              fullWidth
            >
              {t('checkout.payment.offerSelection.cancelAction')}
            </IncButtonDark>
          </Grid>
          <Grid item xs>
            <IncButton
              onClick={handleApplyOffer}
              data-testid="OfferSelection.CustomButton.Apply"
              fullWidth
              disabled={loading}
            >
              {t('checkout.payment.offerSelection.applyAction')}
            </IncButton>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomDialog>
  );
};

export default OffersModal;
