import Form from 'components/form/form.component';
import IncButton from 'components/inc-button/inc-button.component';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { lookupGiftCard } from 'redux/user/user.actions';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
} from '../giftcard.styles';
import { selectGiftCardLookUp } from 'redux/config/config.selectors';
import GiftcardLookupDisabled from './giftcard-lookup-disabled/giftcard-lookup-disabled.component';
import GiftCardSecurityCodeInput from 'components/gift-card-security-code-input/gift-card-security-code-input.component';
import GiftCardNumberInput from 'components/gift-card-number-input/gift-card-number-input.component';

const GiftcardLookupPage = () => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const history = useHistory();
  const giftCardLookUpEnabled = useSelector(selectGiftCardLookUp);

  const onSubmit = async data => {
    const response = await lookupGiftCard(data);

    if (response?.data?.token) {
      history.push(`/giftcard/view/${response.data.token}`);
    }
  };

  if (!giftCardLookUpEnabled) return <GiftcardLookupDisabled />;

  return (
    <ContentContainer
      elevation={2}
      data-testid={'GiftcardLookupPage.ContentContainer'}
    >
      <ContentHeader component="h1" variant="h5">
        {t('eGiftCard.lookup.header')}
      </ContentHeader>
      <ContentBody>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <GiftCardNumberInput
              name="cardNumber"
              id="cardNumber"
              label={t('eGiftCard.lookup.form.cardNumber.label')}
            />

            <GiftCardSecurityCodeInput />

            <IncButton
              type="submit"
              disabled={!!Object.keys(errors).length}
              data-testid="GiftcardLookup.CustomButton.Submit"
              fullWidth
            >
              {t('eGiftCard.lookup.form.submitButton')}
            </IncButton>
          </Form>
        </FormProvider>
      </ContentBody>
    </ContentContainer>
  );
};

export default GiftcardLookupPage;
