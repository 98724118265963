import React, { useContext } from 'react';
import * as S from './option-group-tabs.styles';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import OptionGroup from 'pages/menu/option-group/option-group.component';
import Spinner from 'components/spinner/spinner.component';
import MenuDialogContext from 'hooks/dialog/menu-dialog.context';

const OptionGroupTabs = ({
  selectionGroups,
  isCustomizations,
  tabVal,
  handleTabChange,
  tabValues,
  filteredEditOptionsByGroupId,
  editInfo,
  customizationGroups,
}) => {
  const { t } = useTranslation();
  const { isItemModifierDetailsFetching } = useContext(MenuDialogContext);

  if (isItemModifierDetailsFetching) return <Spinner />;

  return (
    <>
      {selectionGroups.length > 0 || isCustomizations ? (
        <S.CustomTabs
          value={tabVal}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label={t('catalog.modal.tabs.ariaLabel')}
          data-testid="CatalogModal.CustomTabs"
        >
          {selectionGroups.length > 0 && (
            <Tab
              value={tabValues.selectOptions}
              label={t('catalog.modal.tabs.selectOptions')}
              data-testid="CatalogModal.CustomTabs.SelectOptions"
            />
          )}
          {isCustomizations && (
            <Tab
              value={tabValues.customizations}
              label={t('catalog.modal.tabs.customizations')}
              data-testid="CatalogModal.CustomTabs.Customizations"
            />
          )}
        </S.CustomTabs>
      ) : null}

      <div hidden={tabVal !== tabValues.selectOptions}>
        {selectionGroups.map((group, i) => (
          <OptionGroup
            key={group.groupId}
            group={group}
            editOptions={filteredEditOptionsByGroupId(group)}
            formKey={`g${i}`}
            isParentAndEditFlow={editInfo?.options}
          />
        ))}
      </div>

      <div hidden={tabVal !== tabValues.customizations}>
        {isCustomizations &&
          customizationGroups.map((group, i) => (
            <OptionGroup
              key={group.groupId}
              group={group}
              editOptions={filteredEditOptionsByGroupId(group)}
              formKey={`g${i + selectionGroups.length}`}
              isParentAndEditFlow={editInfo?.options}
            />
          ))}
      </div>
    </>
  );
};

export default OptionGroupTabs;
