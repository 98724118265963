import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import { openWindow } from '../../utils';
import { IncButtonDarkIcon } from 'components/inc-button/inc-button.styles';

const TwitterShare = ({ shareableData, shareableUrl, onShare }) => {
  const openTwitterWindow = () => {
    openWindow(
      `https://twitter.com/intent/tweet?text=${shareableData}&url=${shareableUrl}`,
      '_blank',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=640,left=200,top=100',
    );
    setTimeout(() => onShare(), 6000);
  };

  return (
    <IncButtonDarkIcon
      onClick={openTwitterWindow}
      data-testid="TwitterShare"
      fullWidth
    >
      Twitter
      <TwitterIcon />
    </IncButtonDarkIcon>
  );
};

export default TwitterShare;
