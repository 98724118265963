import { Alert } from '@material-ui/lab';
import ActionLink from 'components/action-link/action-link.component';
import React from 'react';
import { Fade as RevealFade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import * as S from '../checkout-offers-section.styles';

const CheckoutOffersSectionApplicable = ({ applicableOffers, openModal }) => {
  const { t } = useTranslation();

  return (
    <RevealFade direction="right" duration={600} triggerOnce>
      <Alert
        severity="success"
        icon={<S.CustomCertificate />}
        data-testid="OffersSection"
      >
        <ActionLink
          onClick={openModal}
          data-testid="OffersSection.ActionLink.ApplicableOffers"
        >
          {t('checkout.offers.applicable', { count: applicableOffers.length })}
        </ActionLink>
      </Alert>
    </RevealFade>
  );
};

export default CheckoutOffersSectionApplicable;
