import { Box, IconButton } from '@material-ui/core';
import IncButton from 'components/inc-button/inc-button.component';
import OrderItemOptionsList from 'components/order-item-options-collapse/order-item-options-list/order-item-options-list.component';
import { CheckoutH3, ItemTotal } from 'pages/checkout/checkout.styles';
import React from 'react';
import { useSummaryItemComponent } from './summary-item.hooks';
import * as S from './summary-item.styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from 'react-i18next';
import ItemAndModierDiscounts from 'components/item-and-modifier-discounts/item-and-modifier-discounts.component';
import { convertCentsToDollar } from 'utils';

const SummaryItem = ({ item }) => {
  const {
    title,
    smallImage,
    modifyQtyType,
    modifyingQtyItemIds,
    options,
    quantity,
    openModal,
    handleRemoveItem,
    handleDecrement,
    handleIncrement,
    hideQuantityPicker,
    basePrice,
    displaySubtotal,
    itemDiscounts,
    displayPreDiscountSubtotal,
    isOrderDiscountApplied,
  } = useSummaryItemComponent(item);
  const { t } = useTranslation();
  return (
    <Box display="flex" flex="1" minHeight="80px" data-testid="SummaryItem.Box">
      {!!smallImage?.[0] && (
        <Box width="80px" height="80px" borderRadius="4px" overflow="hidden">
          <S.Image src={smallImage[0]} alt="" data-testid="SummaryItem.Image" />
        </Box>
      )}
      <Box display="flex" pl={1} flex="1">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ width: '100%' }}
          pr={2}
        >
          <Box>
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flex="1">
                <CheckoutH3 data-testid="SummaryItem.CheckoutH3.Title">
                  {title}
                </CheckoutH3>
              </Box>
              <Box pl={1}>
                <S.Price variant="body2" data-testid="SummaryItem.Price">
                  {convertCentsToDollar(basePrice)}
                </S.Price>
              </Box>
            </Box>
            {itemDiscounts?.length > 0 && (
              <ItemAndModierDiscounts
                itemDiscounts={itemDiscounts}
                dataTestId="Item.Discount"
              />
            )}
            <Box>
              {options && (
                <Box pl="8px">
                  <OrderItemOptionsList options={options} />
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              alignItems="center"
              pl={1}
            >
              <Box display="flex" flex="1">
                <ItemTotal>{t('checkout.items.itemTotal.label')}</ItemTotal>
              </Box>
              <Box>
                <ItemTotal variant="body2" data-testid="SummaryItem.ItemTotal">
                  $
                  {isOrderDiscountApplied
                    ? displayPreDiscountSubtotal
                    : displaySubtotal}
                </ItemTotal>
              </Box>
            </Box>
          </Box>

          <Box display="flex">
            <Box>
              <S.EditButton
                size="small"
                variant="text"
                onClick={openModal}
                data-testid="SummaryItem.EditButton"
              >
                Edit
              </S.EditButton>
              <IncButton
                size="small"
                variant="text"
                onClick={handleRemoveItem}
                disabled={
                  modifyQtyType === 'REMOVE_ITEM' && modifyingQtyItemIds.length
                }
                data-testid="SummaryItem.IncButton.Remove"
              >
                Remove
              </IncButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={hideQuantityPicker ? 'center' : 'space-between'}
        alignItems="center"
        height="80px"
        pl="6px"
      >
        {!hideQuantityPicker && (
          <IconButton
            size="small"
            color="primary"
            onClick={handleIncrement}
            disabled={modifyQtyType === '+' && modifyingQtyItemIds.length > 0}
            data-testid="SummaryItem.IconButton.Increase"
            aria-label={t('checkout.items.quantity.increment', { quantity })}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        )}
        <Box data-testid="SummaryItem.Box.Quantity">{quantity}</Box>
        {!hideQuantityPicker && (
          <IconButton
            size="small"
            color="primary"
            onClick={handleDecrement}
            disabled={modifyQtyType === '-' && modifyingQtyItemIds.length > 0}
            data-testid="SummaryItem.IconButton.Decrease"
            aria-label={t('checkout.items.quantity.decrement', { quantity })}
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default SummaryItem;
